










import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { VariantType } from '~/models/app/variant'

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<VariantType>,
      default: null
    },
    wrapText: {
      type: Boolean,
      default: false
    },
    cols: { type: Number }
  }
})
