

































import {
  faCheckCircle,
  faChessKnight,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { Interval } from '~/models/timing/interval'
import { Variant, VariantType } from '~/models/app/variant'
import { ONE_SECOND_MS } from '~/constants/duration'
import CIcon from '~/components/shared/configurable/CIcon.vue'

export default defineComponent({
  props: {
    show: {
      type: [Boolean, Number],
      default: false
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dismissibleAbsolute: {
      type: Boolean,
      default: false
    },
    fade: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.INFO
    },
    icon: {
      type: [Object, Boolean],
      default: false
    },
    contentClass: {
      type: [String, Array, Object],
      default: () => []
    }
  },
  setup(props, { emit }) {
    const {
      show,
      variant,
      fade,
      dismissible,
      dismissibleAbsolute,
      icon
    } = toRefs(props)
    const isVisible = ref(Boolean(show.value))
    const countDownVal = ref(0)
    const countDownInterval = ref<Interval | null>(null)
    handleShowValue(show.value)

    function handleDismissed() {
      if (countDownInterval.value) {
        clearInterval(countDownInterval.value)
      }
      emit('dismissed')
      isVisible.value = false
    }
    function countDown() {
      countDownInterval.value = setInterval(() => {
        if (countDownVal.value < 1) {
          clearInterval(countDownInterval.value as Interval)
          isVisible.value = false
        }
        countDownVal.value--

        emit('dismiss-count-down', countDownVal.value)
      }, ONE_SECOND_MS)
    }

    const alertClasses = computed(() => {
      function fadeClasses(): string[] {
        if (fade.value) {
          return ['fade-in']
        }
        return []
      }
      function dismissibleClasses(): string[] {
        if (dismissible.value && !dismissibleAbsolute.value) {
          return ['tw-pr-12']
        }
        return ['tw-pr-4']
      }
      function variantClasses(): string[] {
        switch (variant.value) {
          case Variant.PRIMARY: {
            return [
              'tw-text-primary-900',
              'tw-bg-primary-100',
              'tw-border-primary-200'
            ]
          }
          case Variant.SUCCESS: {
            return [
              'tw-text-green-900',
              'tw-bg-green-100',
              'tw-border-green-200'
            ]
          }
          case Variant.WARNING: {
            return [
              'tw-text-orange-900',
              'tw-bg-yellow-100',
              'tw-border-orange-200'
            ]
          }
          case Variant.DANGER: {
            return ['tw-text-red-900', 'tw-bg-red-100', 'tw-border-red-200']
          }
          case Variant.SECONDARY: {
            return ['tw-text-grey-800', 'tw-bg-grey-300', 'tw-border-grey-400']
          }
          case Variant.WHITE: {
            return ['tw-text-grey-800', 'tw-bg-white', 'tw-border-grey-300']
          }
          case Variant.LIGHT: {
            return ['tw-text-grey-700', 'tw-bg-grey-100', 'tw-border-grey-200']
          }
          case Variant.INFO:
          default: {
            return ['tw-text-sky-700', 'tw-bg-sky-100 tw-border-sky-200']
          }
        }
      }

      return [...fadeClasses(), ...dismissibleClasses(), ...variantClasses()]
    })

    const closeButtonClasses = computed(() => {
      function variantClasses(): string[] {
        switch (variant.value) {
          case Variant.PRIMARY: {
            return ['tw-text-primary-900']
          }
          case Variant.SUCCESS: {
            return ['tw-text-green-900']
          }
          case Variant.WARNING: {
            return ['tw-text-amber-900']
          }
          case Variant.DANGER: {
            return ['tw-text-red-900']
          }
          case Variant.SECONDARY: {
            return ['tw-text-grey-900']
          }
          case Variant.WHITE: {
            return ['tw-text-grey-900']
          }
          case Variant.INFO:
          default: {
            return ['tw-text-sky-900']
          }
        }
      }
      return [...variantClasses(), { 'tw-absolute': dismissibleAbsolute.value }]
    })

    onMounted(() => {
      if (typeof show.value === 'number' && show.value > 0) {
        countDown()
      }
    })

    onBeforeUnmount(() => {
      if (countDownInterval.value) {
        clearInterval(countDownInterval.value)
      }
    })

    function handleShowValue(show: boolean | number) {
      if (typeof show === 'boolean') {
        isVisible.value = show
      } else {
        countDownVal.value = show
      }
    }

    watch(show, newShow => {
      handleShowValue(newShow)
    })

    const defaultIcon = computed(() => {
      switch (variant.value) {
        case Variant.WARNING: {
          return faExclamationTriangle
        }
        case Variant.SUCCESS: {
          return faCheckCircle
        }
        case Variant.DANGER: {
          return faTimesCircle
        }
        case Variant.INFO:
        case Variant.PRIMARY:
        case Variant.SECONDARY:
        case Variant.LIGHT:
        case Variant.WHITE: {
          return faInfoCircle
        }
        default: {
          return faChessKnight
        }
      }
    })

    const iconTag = computed(() => {
      if (
        icon.value === true ||
        // only fa icons have the prefix property
        ((icon.value as any).prefix &&
          (icon.value as any).prefix.startsWith('fa'))
      ) {
        return 'fa'
      }
      return CIcon
    })

    const internalIcon = computed(() => {
      if (icon.value === false) {
        return null
      } else if (icon.value === true) {
        return defaultIcon.value
      }
      return icon.value
    })

    const iconClasses = computed(() => {
      switch (variant.value) {
        case Variant.WARNING: {
          return ['tw-text-yellow-600']
        }
        case Variant.SUCCESS: {
          return ['tw-text-green-600']
        }
        case Variant.DANGER: {
          return ['tw-text-red-600']
        }
        case Variant.INFO: {
          return ['tw-text-sky-600']
        }
        case Variant.LIGHT: {
          return ['tw-text-grey-500']
        }
        case Variant.PRIMARY: {
          return ['tw-text-primary-500']
        }
        default: {
          return ['tw-text-grey-600']
        }
      }
    })

    return {
      closeButtonIcon: faTimes,
      handleDismissed,
      countDown,
      isVisible,
      alertClasses,
      closeButtonClasses,
      internalIcon,
      iconTag,
      iconClasses
    }
  }
})
