












































































import {
  defineComponent,
  toRefs,
  watch,
  PropType,
  ref,
  computed
} from '@nuxtjs/composition-api'
import { Size } from '~/models/shared/types'
import { useId } from '~/compositions/id'
import { useFormComponent } from '~/compositions/form-component'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default defineComponent({
  props: {
    options: {
      type: Array,
      required: false,
      default: () => null
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM
    },
    textField: {
      type: String,
      default: 'text'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    helperText: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    roundnessClass: {
      type: String,
      default: 'tw-rounded'
    },
    borderClasses: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup(props: any, { emit, listeners }: any) {
    const {
      value,
      options,
      multiple,
      size,
      valueField,
      id,
      required,
      floatingLabel,
      labelClass,
      hasError,
      roundnessClass,
      borderClasses
    } = toRefs(props)

    const internalValue = ref(multiple.value ? value.value || [] : value.value)
    const selectElement = ref<HTMLSelectElement | null>(null)
    const { createRandomId } = useId()
    const { labelClasses, helperTextClasses } = useFormComponent(
      required,
      floatingLabel,
      labelClass,
      hasError
    )

    const internalId = id.value || createRandomId()

    watch(value, () => (internalValue.value = value.value))

    if (options?.value?.length && !value.value) {
      const hasSelected = options.value.some(option => option.selected)
      if (hasSelected) {
        options.value.forEach(option => {
          if (option.selected) {
            internalValue.value = option[valueField.value]
          }
        })
      }
    }

    function getSelectedValue(el: HTMLSelectElement) {
      const selectedValue = Array.from(el.options || [])
        .filter(o => o.selected)
        .map(o => ('_value' in o ? (o as any)._value : o.value))
      return multiple.value ? selectedValue : selectedValue[0]
    }

    function handleChange(e: Event) {
      internalValue.value = getSelectedValue(e.target as HTMLSelectElement)
      emit('change', internalValue.value)
    }

    function handleInput(e: Event) {
      internalValue.value = getSelectedValue(e.target as HTMLSelectElement)
      emit('input', internalValue.value)
    }

    const selectClasses = computed(() => {
      const classes = []

      if (multiple.value) {
        classes.push('tw-bg-none')
      }

      if (hasError.value) {
        classes.push(
          'tw-border tw-border-solid tw-border-red-600 focus:tw-ring-red-500 focus:tw-border-red-400'
        )
      } else {
        classes.push('focus:tw-ring-primary-500 focus:tw-border-primary-400')
      }

      if (size.value === Size.SMALL) {
        classes.push('tw-text-base tw-py-1')
      } else if (size.value === Size.LARGE) {
        classes.push('tw-text-2xl tw-py-2')
      } else {
        classes.push('tw-text-lg tw-py-[0.375rem]')
      }

      return [...classes, roundnessClass.value, borderClasses.value]
    })

    const internalListeners = computed(() => {
      return {
        ...listeners,
        input: handleInput,
        change: handleChange
      }
    })

    const showPlaceholder = computed(() => {
      if (!props.placeholder) {
        return false
      }

      return multiple.value
        ? !internalValue.value?.length
        : internalValue.value === null
    })

    return {
      handleChange,
      handleInput,
      internalValue,
      internalId,
      selectElement,
      labelClasses,
      helperTextClasses,
      selectClasses,
      internalListeners,
      showPlaceholder,
      faChevronDown
    }
  }
})
