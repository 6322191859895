import '~/polyfills/reflect-metadata' // Tsyringe requirement
import { container } from 'tsyringe'
import { registerRequestDependenciesToContainer } from '~/plugins/dependency-container'
import {
  createDepGetter,
  createArgDepGetter
} from '~/plugins/dependency-container/utils'
import { NoopSnackbarService } from '~/services/snackbar/NoopSnackbarService'
import SnackbarService from '~/services/snackbar/SnackbarService'
import { NoopUrlHistoryService } from '~/services/url/history/NoopUrlHistoryService'
import UrlHistoryService from '~/services/url/history/UrlHistoryService'
import { registerRuntimeSingleton } from '~/utils/dependency-container'

export default function(
  {
    app: { $sentry: sentry, i18n, router },
    store,
    $axios: axios,
    req,
    res,
    redirect
  }: any,
  inject: any
) {
  const requestContainer = container.createChildContainer()
  registerRequestDependenciesToContainer(
    // @ts-ignore
    { sentry, i18n, router, axios, req, res, store, redirect },
    requestContainer
  )

  registerRuntimeSingleton(
    requestContainer,
    UrlHistoryService,
    NoopUrlHistoryService
  )
  registerRuntimeSingleton(
    requestContainer,
    SnackbarService,
    NoopSnackbarService
  )
  inject('requestContainer', requestContainer)
  inject('dep', createDepGetter(requestContainer))
  inject('deps', createArgDepGetter(requestContainer))
}
