import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { AuthUserResult } from '~/models/user/types'
import { invalidBodyError } from '../errors'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class AuthUserService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getUser(): Promise<AuthUserResult> {
    const response = await this.http.get('/api/auth-user/')
    const body = response.data
    if (!(body && body.data && body.data.user)) {
      throw invalidBodyError(body)
    }
    const user = body.data.user
    const cr = user.credits

    return {
      username: user.username,
      name: user.name,
      id: user.id,
      avatar: user.avatar,
      type: user.type,
      credits: cr && {
        free: cr.free_credits,
        paid: cr.paid_credits,
        freeDaily: cr.daily_free_credits
      },
      sdmu: user.sdmu,
      properties: user.properties,
      permissions: user.permissions,
      location: user.location,
      featureToggles: user.feature_toggles,
      referenceCode: user.reference_code,
      hasPublicDealers: user.has_public_dealers,
      isPayingUser: user.is_paying_user,
      externalIds: user.external_ids,
      accessToggles: toCamelCase(user.access_toggles),
      subscription: user.subscription && toCamelCase(user.subscription),
      hasCompleteProfile: user.has_complete_profile,
      dealerIds: user.dealer_ids,
      stripeSubscription:
        user.stripe_subscription && toCamelCase(user.stripe_subscription),
      inHouseConfig: user.in_house_config && toCamelCase(user.in_house_config),
      notificationRelayUrl: user.notifications?.relay_sub_url || '',
      telephone: user.telephone,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name
    }
  }
}
