import { singleton } from '~/decorators/dependency-container'
import { formatDealer } from '~/services/dealers/formatters'
import { formatPagination } from '~/services/formatters'
import {
  formatAlternativeSearch,
  formatSellersClassifieds,
  formatHumanParam,
  mapBackendSearchableToSearchable
} from '~/services/search/formatters'
import {
  ResultsResult,
  FacetsResult,
  SellersResult,
  SearchSellers,
  SearchSellerRow,
  SearchSellerRowClassified,
  Results,
  MoreResultsButton,
  Facet,
  Category,
  SearchLink,
  Stats
} from '~/models/search/types'
import { toCamelCase } from '~/utils/object'

@singleton()
export default class SearchFormatter {
  formatResultsResponse(r: any): ResultsResult {
    return {
      newWantedClassifiedUrl: r.new_wanted_classified_url,
      showInPlotUrl: r.show_in_plot_url,
      isFavorite: r.is_favorite,
      results: this.formatResults(r.results),
      sellers: r.sellers && {
        total: r.sellers.total
      }
    }
  }

  formatFacetsResponse(r: any): FacetsResult {
    let profile = null
    if (r.profile) {
      profile = { ...r.profile }
      profile.canReceiveMessages = profile.can_receive_messages
      delete profile.can_receive_messages
    }
    return {
      alternativeSearches: r.alternative_searches?.map(formatAlternativeSearch),
      adsTargetings: r.ads_targetings,
      args: r.args,
      canBeSaved: r.can_be_saved,
      category: this.formatCategory(r.category),
      categoryIds: r.category_ids,
      changeSearchUrl: r.change_search_url,
      facets: r.facets.map(this.formatFacet),
      isFavorite: r.is_favorite,
      longTitle: r.long_title,
      mySearchLinks: r.my_search_links && {
        isOpen: r.my_search_links.is_open,
        searchLinks: (r.my_search_links.links || []).map(
          this.formatMySearchLink
        )
      },
      massActions: toCamelCase(r.mass_actions),
      newClassifiedUrl: r.new_classified_url,
      previousSearch: r.previous_search,
      mapSearchUrl: r.map_search_url,
      userProfile: profile,
      searchId: r.search_id,
      shortTitle: r.short_title,
      sortFields: r.sort_fields,
      stats: r.stats && this.formatStats(r.stats),
      title: r.title,
      textSearch: toCamelCase(r.text_search),
      searchSubscription: toCamelCase(r.search_subscription),
      userOwnsSearch: r.user_owns_search,
      notMyClassifiedsUrl: r.not_my_classifieds_url || undefined
    }
  }

  formatSellersResponse(r: any): SellersResult {
    return {
      isFavorite: r.is_favorite,
      sellers: this.formatSellers(r.sellers)
    }
  }

  private formatSellers(s: any): SearchSellers {
    return {
      classifieds: formatSellersClassifieds(s.classifieds),
      pagination: formatPagination(s.pagination),
      rows: (s.rows || []).map((row: SearchSellerRow) => {
        return {
          ...formatDealer(row),
          classifieds: row.classifieds.map(this.formatSellersClassified),
          website: row.website,
          internalPage: row.internal_page
          // test pages
          // website: 'https://car.gr',
          // internalPage: 'https://car.car.gr'
        }
      })
    }
  }

  private formatSellersClassified(c: any): SearchSellerRowClassified {
    return {
      seoUrl: c.seo_url,
      price: c.price,
      id: c.id,
      thumbPattern: c.thumb_pattern,
      title: c.title,
      hasPhotos: c.has_photos
    }
  }

  private formatResults(r: any): Results {
    return {
      consent: r.consent,
      moreResultsButton:
        r.more_results_button &&
        this.formatMoreResultsButton(r.more_results_button),
      rows: this.formatRows(r.rows),
      savable: r.savable,
      shareable: r.shareable,
      pagination: formatPagination(r.pagination),
      ads: r.ads
    }
  }

  formatRows(rows: any): any {
    // @ts-ignore
    return rows.map(row => {
      return {
        ...row,
        promotion: row.promotion && toCamelCase(row.promotion),
        rental: row.rental && toCamelCase(row.rental)
      }
    })
  }

  private formatMoreResultsButton(m: any): MoreResultsButton {
    return {
      args: m.args,
      count: m.count,
      name: m.name,
      seoUrl: m.seo_url
    }
  }

  private formatFacet(f: any): Facet {
    const formatSearchables = () => {
      if (f.type === 'MakeModelSearchHandler') {
        return f.searchables?.map(mapBackendSearchableToSearchable)
      }

      return f.searchables
    }

    return {
      category: f.category,
      humanName: f.human_name,
      isImportant: f.is_important,
      isOpen: f.is_open,
      isSelected: f.is_selected,
      name: f.name,
      placeholder: f.placeholder,
      searchables: formatSearchables(),
      showHandlerName: f.show_handler_name,
      type: f.type,
      urlArgNames: f.urlarg_names,
      values: {
        humanParams:
          f.values.human_params && f.values.human_params.map(formatHumanParam),
        params: f.values.params,
        selected: f.values.selected,
        value: f.values.value,
        ...f.values
      },
      visible: f.visible,
      strictValues: f.strict_values,
      multicheck: f.multicheck,
      selectedPath: f.selected_path,
      searchPlaceholder: f.search_placeholder,
      variant: f.variant,
      defaultCategory: f.default_category,
      unitType: f.unit_type
    }
  }

  private formatCategory(c: any): Category {
    return {
      humanName: c.human_name,
      humanNamePlural: c.human_name_plural,
      humanPath: c.human_path,
      id: c.id,
      name: c.name,
      parentId: c.parent_id,
      pluralName: c.plural_name
    }
  }

  private formatMySearchLink(sl: any): SearchLink {
    return {
      categoryName: sl.category_name,
      categoryIds: sl.category_ids,
      count: sl.count,
      label: sl.label,
      legacyVType: sl.legacy_vtype,
      selected: sl.selected,
      seoUrl: sl.seo_url,
      type: sl.type
    }
  }

  private formatStats(s: any): Stats {
    return {
      averagePrice: s.average_price,
      total: s.total
    }
  }
}
