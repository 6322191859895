import { interopDefault } from '~/router/utils'

export const ClassifiedViewPage = () =>
  interopDefault(
    import(
      '~/pages/plot/classifieds/view/view.vue' /* webpackChunkName: "pages-plot-classifieds-view-view" */
    )
  )

export const classifiedSlugOrIdPattern = ':slugOrId(\\d+\\-*.*)'

export default [
  {
    name: '__classifieds_view',
    path: `/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  },
  {
    name: '__classifieds_view_plot',
    path: `/plot/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  },
  {
    name: '__classifieds_view_plot_alt',
    path: `/plot/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  },
  {
    name: '__classifieds_view_vtype',
    path: `/classifieds/:vtype/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  },
  {
    name: '__classifieds_view_alt',
    path: `/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  },
  {
    name: '__classifieds_view_alt2',
    path: `/classifieds/view/${classifiedSlugOrIdPattern}`,
    component: ClassifiedViewPage
  }
]
