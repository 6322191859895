import { isEmail } from '~/utils/string'
import { GetterTreeWithRootState } from '~/store/types'
import { UserType } from '~/models/user/types'
import { UserState } from './state'
import { AccessToggleName } from '~/models/user/access-toggles'
import { SubscriptionPlanName } from '~/models/user/subscription'

export default {
  is(state) {
    return (type: UserType) => state.type === type
  },
  isAdmin(state) {
    return state.type === UserType.ADMIN
  },
  isGuest(state) {
    return state.type === UserType.GUEST
  },
  isManager(state) {
    return state.type === UserType.MANAGER
  },
  isDealer(state) {
    return state.type === UserType.DEALER
  },
  isAgent(state) {
    return state.type === UserType.AGENT
  },
  isAnon(state) {
    return state.type === UserType.ANON
  },
  isSingle(state) {
    return state.type === UserType.SINGLE
  },
  externalIds(state) {
    return state.externalIds
  },
  isSingleOrAnon(state) {
    return state.type === UserType.SINGLE || state.type === UserType.ANON
  },
  isOfTypeDealer(_, getters) {
    return getters.isDealer || getters.isManager || getters.isAgent
  },
  isOfTypeAgent(_, getters) {
    return getters.isManager || getters.isAgent
  },
  isPayingUser(state) {
    return state.isPayingUser
  },
  userType(state) {
    return state.type
  },
  userId(state) {
    return state.id
  },
  userDealerIds(state) {
    return state.dealerIds || []
  },
  userIds(_state, { userId, userDealerIds }) {
    return [userId, ...userDealerIds]
  },
  userMayReceiveCreditAlerts(_state, { isDealer, isManager }): boolean {
    return isDealer || isManager
  },
  referenceCode(state) {
    return state.referenceCode || null
  },
  userHasFeatureToggle(state) {
    return (toggle: string) => state.featureToggles?.includes(toggle)
  },
  hasPermission(state) {
    return (permission: string) =>
      state.permissions.some(perm => perm === permission)
  },
  usernameIsEmail(state) {
    return state.username && isEmail(state.username)
  },
  hasPublicDealers(state) {
    return state.hasPublicDealers || false
  },
  totalCredits({ credits }) {
    return credits && credits.paid + credits.free
  },
  paidCredits({ credits }) {
    return credits && credits.paid
  },
  freeCredits({ credits }) {
    return credits && credits.free
  },
  myClassifiedsCount(state) {
    if (state.myClassifieds) {
      return state.myClassifieds.reduce((accumulator, object) => {
        let count = 0
        if (object.type !== 'deleted') {
          count = object.count
        }
        return accumulator + count
      }, 0)
    }
    return 0
  },
  showOutOfCreditsMessage(
    _state,
    { totalCredits, userMayReceiveCreditAlerts }
  ): boolean {
    return (
      userMayReceiveCreditAlerts &&
      typeof totalCredits !== 'undefined' &&
      totalCredits === 0
    )
  },
  showLowCreditsMessage(
    _state,
    { totalCredits, userMayReceiveCreditAlerts }
  ): boolean {
    return (
      userMayReceiveCreditAlerts &&
      typeof totalCredits !== 'undefined' &&
      totalCredits < 5 &&
      totalCredits > 0
    )
  },
  creditRenewalPageUrl: ({ type }): string | undefined => {
    if (type === UserType.MANAGER || type === UserType.DEALER) {
      return '/account/renew/'
    }

    return undefined
  },
  userHasAccessTo(state): (a: AccessToggleName) => boolean {
    return (accessToggleName: AccessToggleName) => {
      const toggle = state.accessToggles.find(t => t.value === accessToggleName)
      return Boolean(toggle && toggle.allow)
    }
  },
  isDebugUser(state): boolean {
    return state.sdmu
  },
  hasCompleteProfile(state): boolean {
    return Boolean(state.hasCompleteProfile)
  },
  isAuditsUser(_state, getters): boolean {
    return getters.isAuditProvider || getters.isAuditShop || getters.isAuditor
  },
  isAuditProvider(state): boolean {
    return state.type === UserType.AUDIT_PROVIDER
  },
  isAuditShop(state): boolean {
    return state.type === UserType.AUDIT_SHOP
  },
  isAuditor(state): boolean {
    return state.type === UserType.AUDITOR
  },
  userCanCreateClassifieds(_state, getters): boolean {
    return !(getters.isAdmin || getters.isAuditsUser)
  },
  userHasAuditRequests(state): boolean {
    return Boolean(state.auditRequestsCount?.all)
  },
  getAgentSubscription(
    state,
    getters
  ): SubscriptionPlanName | null | undefined {
    return getters.isAgent ? state.stripeSubscription?.tier : null
  },
  getHasParked(state): boolean {
    return state.hasParked
  }
} as GetterTreeWithRootState<UserState>
