import { ClassifiedCount } from '~/models/classified/types'
import { SiteName } from '~/models/domain'
import { XmlFeed } from '~/models/xml/types'
import { UserType } from '~/models/user/types'

export interface SearchableUser {
  id: number
  username: string
  name: string
  type: UserType
  userTags: string[]
  telephones: string[]
  telephone1: string | null
  paid: number
  telephone2: string | null
  // Note: Correct translation of ΑΦΜ is "Tax Identification Number" (TIN)
  afm: string | null
  invoices: number | null
  expires: string | null
  managerId: number | null
  site: SiteName
  lastLogin: string
  created: string
  classifieds: ClassifiedCountsByCategory
  xmlFeed: XmlFeed
  subscriptionTier: string
  suggestedSubscription: any
  loginasUrl: string
}

export interface ClassifiedCountsByCategory {
  vehicles: ClassifiedCountsByActiveState
  partsAndXyma: ClassifiedCountsByActiveState
  realEstate: ClassifiedCountsByActiveState
  jobs: ClassifiedCountsByActiveState
  rentals: ClassifiedCountsByActiveState
}

export interface ClassifiedCountsByActiveState {
  active: ClassifiedCount
  disabled: ClassifiedCount
  deleted: ClassifiedCount
}

export enum UserSearchBooleanFilter {
  WITH_GOOGLE_RATING = 'with_google_rating',
  WITH_SUBSCRIPTION = 'with_subscription',
  IS_RETAIL_USER = 'is_retail_user',
  WITH_SKROUTZ_XML = 'with_skroutz_xml',
  SEARCH_NOTES = 'search_notes',
  WITH_CAR_XML = 'with_car_xml',
  REQUIRES_PAYMENT = 'requires_payment',
  EXPIRING_SOON = 'expiring_soon',
  WITHOUT_A_DEALER = 'without_a_dealer',
  BANNED_FROM_DEALS = 'banned_from_deals',
  BLACKLISTED_DUE_TO_TOO_MANY_CLASSIFIEDS = 'blacklisted9',
  BLACKLISTED_BY_SUSPICION = 'blacklisted29'
}

export interface UserSearchFilters {
  'user-type'?: UserType[]
  // eslint-disable-next-line camelcase
  site?: SiteName[] // site region
  'last-activity'?: Date
  boolean: Set<UserSearchBooleanFilter>
}

export type StaticNameSearchFilters = Pick<
  UserSearchFilters,
  'user-type' | 'site' | 'last-activity'
>

export const idempotentlyNamedSearchFilterSet = new Set<
  keyof StaticNameSearchFilters
>(['user-type', 'site', 'last-activity'])

export interface UserSearchSchema {
  sortOptions: SchemaItem[]
  toggles: SchemaItem[]
  userTypes: SchemaItem[]
  lastActivityPeriods: SchemaItem[]
  siteRegions: SchemaItem[]
}

export interface SchemaItem {
  name: string
  value: string
}
