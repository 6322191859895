import { UserType } from '~/models/user/types'
import { AuditShop } from '~/models/audit/shop'
import { AuditProvider } from '~/models/audit/provider'
import { Pagination } from '~/models/search/types'
import { Telephone } from '~/models/shared/types'
import { StripePayment } from '~/models/payments/stripe'
import { ExternalCertificateDetails } from '~/models/external-certificates/types'
import { AuditPackage } from '~/models/audit/package'

export interface AuditRequestsRowsResult {
  auditRequests: {
    rows: AuditRequest[]
    pagination: Pagination
  }
}

export interface AuditRequestResult {
  auditRequest: AuditRequest
}

export interface AuditRequestFormValues {
  id: number
  auditPackage: number
  auditShop: number
}
export interface CreateAuditRequestResult {
  auditRequest: AuditRequest
  values: AuditRequestFormValues
}

export interface AuditRequestsResult {
  auditRequests: AuditRequest[]
}

export interface AuditSellerInfo {
  displayName: string
  displayPhoto: string
  email: string
  id: number
}

export interface AuditRequest {
  currentStatus: AuditRequestStatus
  id: number
  created: string
  acceptanceUrl: string
  expirationDate: string
  finalExpirationDate: string
  requestedByUser: {
    displayName: string
    displayPhoto: string
    id: number
    telephone1?: Telephone
    telephone2?: Telephone
    type: UserType
    email?: string
  }
  requestedForClassified: {
    address: string
    id: number
    make?: string
    model?: string
    seller: AuditSellerInfo
    seoUrl: string
    telephone1?: Telephone
    telephone2?: Telephone
    thumb: string
    title: string
  }
  isSelfRequestedAudit: boolean
  certificate: ExternalCertificateDetails
  statusHistory: AuditRequestStatus[]
  auditShop: AuditShop
  auditProvider: AuditProvider
  auditPackage: AuditPackage
  payment: StripePayment
  currentUserRole: AuditActingUser
  receiptUrl: string | null
  activeAppointment?: {
    appointmentDate: string
    canceledDate?: string
    created: string
    id: number
  }
  requiresAcceptance?: boolean
  showResultOnClassified?: boolean
}

export interface AuditRequestStatus {
  changedByUser?: any
  created: string
  id: number
  status: AuditRequestStatusStatus
  extras: Record<string, any>
  title: string
  description: string | null
}

export enum AuditRequestStatusStatus {
  PENDING_ACCEPTANCE = 'pending_acceptance',
  INFORMING_USER_ABOUT_PAYMENT = 'informing_user_about_payment',
  INFORMING_USER_ABOUT_APPOINTMENT = 'informing_user_about_appointment',
  INFORMING_USER_ABOUT_APPOINTMENT_FAILURE = 'informing_user_about_appointment_failure',
  PENDING_PAYMENT = 'pending_payment',
  CANCELED_BY_USER = 'canceled_by_user',
  PENDING_APPOINTMENT_SCHEDULE = 'pending_appointment_schedule',
  CANCELED_CAUSE_PAYMENT_NOT_RECEIVED = 'canceled_cause_payment_not_received',
  PENDING_AUDIT = 'pending_audit',
  AUDIT_COMPLETED = 'audit_completed',
  AUDIT_COULD_NOT_COMPLETE = 'audit_could_not_completed',
  CANCELED_BY_AUDITOR = 'canceled_by_auditor',
  EXPIRED = 'expired',
  ACCEPTANCE_EXPIRED = 'acceptance_expired',
  PAYMENT_EXPIRED = 'payment_expired'
}

export enum AuditActingUser {
  AUDIT_PROVIDER = 'audit_provider',
  SELLER = 'seller',
  PAYER = 'payer'
}
