





































import { defineComponent, Ref, ref, toRefs } from '@nuxtjs/composition-api'
import { useFormComponent } from '~/compositions/form-component'
import CInfoTooltip from '~/components/shared/configurable/info/CInfoTooltip.vue'
import CInfoModal from '~/components/shared/configurable/info/CInfoModal.vue'

export default defineComponent({
  components: { CInfoModal, CInfoTooltip },
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [Array, String, Object],
      default: () => []
    },
    helperText: {
      type: String,
      default: ''
    },
    forceBlue: {
      type: Boolean,
      required: false,
      default: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { required, labelClass, hasError } = toRefs(props)

    const formGroupTemplateRef = ref<HTMLElement | null>()
    const { labelClasses, helperTextClasses } = useFormComponent(
      required,
      ref(false),
      labelClass as Ref<string[] | object | string>,
      hasError
    )

    function handleLabelClick() {
      if (!formGroupTemplateRef) {
        return
      }
      const inputChildrenElements = formGroupTemplateRef.value?.querySelectorAll(
        ['input', 'select', 'textarea'].map(v => `${v}:not([disabled])`).join()
      )
      if (inputChildrenElements && inputChildrenElements.length === 1) {
        try {
          ;(inputChildrenElements[0] as HTMLInputElement).focus()
        } catch (err) {}
      }
    }

    return {
      handleLabelClick,
      formGroupTemplateRef,
      labelClasses,
      helperTextClasses
    }
  }
})
