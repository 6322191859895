import { FormClassifiedInfo } from '~/models/form/types'
import { EditState } from './state'
import { MutationTree } from 'vuex'
import {
  SET_CATEGORY,
  SET_INFO,
  SET_OWNER,
  SET_REGISTER_REFERENCE_CODE
} from './mutation-types'
import { Dealer } from '~/models/dealer/types'

export default {
  [SET_INFO](state, info: FormClassifiedInfo) {
    state.info = info
  },
  [SET_OWNER](state, owner: Dealer) {
    state.owner = owner
  },
  [SET_CATEGORY](state, category: any) {
    state.category = category
  },
  [SET_REGISTER_REFERENCE_CODE](state, registerReferenceCode: string) {
    state.registerReferenceCode = registerReferenceCode
  }
} as MutationTree<EditState>
