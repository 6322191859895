var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.componentType,_vm._g(_vm._b({tag:"component",staticClass:"c-button tw-text-center tw-transition-colors ",class:[
    _vm.variantClasses,
    _vm.sizeClasses,
    _vm.linkClasses,
    _vm.positionClasses,
    _vm.roundnessClasses,
    { 'tw-block tw-w-full': _vm.block }
  ],attrs:{"to":_vm.to,"href":_vm.href,"role":_vm.role,"disabled":_vm.disabled || _vm.loading,"target":_vm.isLink ? _vm.target : null,"type":_vm.isLink ? null : _vm.type,"title":_vm.title,"data-attribute":_vm.attributeValue}},'component',_vm.$attrs,false),Object.assign({}, _vm.$listeners, { click: _vm.handleClick })),[(_vm.$slots.icon || _vm.icon)?_c('span',{staticClass:"tw-relative tw-flex tw-items-center tw-gap-2 tw-min-w-0"},[_c('span',{staticClass:"tw-inline-flex tw-items-center tw-relative",class:{ 'tw-invisible': _vm.loading }},[(_vm.$slots.icon)?_vm._t("icon"):_c('CDynamicIcon',{class:{ 'tw-invisible': _vm.loading },attrs:{"icon":_vm.icon}})],2),_vm._v(" "),(_vm.loading)?_c('span',{staticClass:"tw-absolute tw-top-1/2 tw--translate-y-1/2",class:_vm.spinnerClasses},[_c('CSpinner',{staticClass:"tw-flex",attrs:{"size":_vm.spinnerSize,"variant":_vm.spinnerVariant}})],1):_vm._e(),_vm._v(" "),_vm._t("default")],2):[(_vm.loading)?_c('span',{staticClass:"tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2"},[_c('CSpinner',{staticClass:"tw-flex",attrs:{"size":_vm.spinnerSize,"variant":_vm.spinnerVariant}})],1):_vm._e(),_vm._v(" "),_c('span',{class:[_vm.containerClasses, { 'tw-invisible': _vm.loading }]},[_vm._t("default")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }