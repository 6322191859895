








































































































import {
  faCamera,
  faChevronRight,
  faChevronLeft,
  faPlay
} from '@fortawesome/free-solid-svg-icons'
import CShimmer from '~/components/shared/configurable/shimmer/CShimmer.vue'
import { imageToDataURL } from '~/utils/dom'
import { ViewType } from '~/models/shared/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { mapGetters } from 'vuex'
import { sellerIsOfTypeDealer } from '~/utils/user'
import CSwipeMixin from '~/components/shared/configurable/mixin/CSwipeMixin.vue'
import { APP_NS } from '~/store/modules/shared/app/state'
import { CategoryId } from '~/models/category/types'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Classified } from '~/models/classified/types'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

export default defineComponent({
  components: { CShimmer, CImg },
  mixins: [CSwipeMixin],
  props: {
    lazy: {
      type: Boolean,
      required: false,
      default: true
    },
    classified: {
      type: Object as PropType<Classified>,
      required: true
    },
    notDraggable: {
      type: Boolean,
      required: false,
      default: false
    },
    images: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    viewType: {
      type: String,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: false
    },
    rowsExtraQueryParams: {
      type: Object,
      required: false,
      default: null
    },
    lowerThumbCounter: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyShowFirst: {
      type: Boolean,
      required: false,
      default: false
    },
    disableImageClick: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: null
    },
    lazyLoadListeners: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      currentThumb: 0,
      imgLoaded: !this.lazy,
      showCount: false,
      showCountTimeout: null
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      userIsOfTypeDealer: 'isOfTypeDealer',
      userIsAdmin: 'isAdmin'
    }),
    ...mapGetters(APP_NS, {
      isCar: 'isCar'
    }),
    isPlotClassified() {
      return (
        this.classifiedCategoryIds && this.classifiedCategoryIds.includes(20002)
      )
    },
    icons() {
      return {
        camera: faCamera,
        chevronRight: faChevronRight,
        chevronLeft: faChevronLeft,
        ytIcon: faYoutube,
        play: faPlay
      }
    },
    classifiedCategoryIds(): number[] {
      return this.classified.category_ids || this.classified.categoryIds || []
    },
    classifiedThumbsPatterns() {
      return this.classified.thumbs_patterns || this.classified.thumbsPatterns
    },
    classifiedThumbPattern() {
      return this.classified.thumb_pattern || this.classified.thumbPattern
    },
    previewImage() {
      return this.isCar
        ? require('~/assets/img/previews/default_preview.png')
        : require('~/assets/img/previews/plot_preview.png')
    },
    hasPhotos() {
      if (this.classified.category_ids?.includes(CategoryId.RENTALS)) {
        return true
      }
      return this.classified.has_photos || this.classified.hasPhotos
    },
    thumbnailFit() {
      if (
        this.classifiedThumbsPatterns &&
        this.classifiedThumbsPatterns.stretch
      ) {
        if (this.viewType === 'gallery') {
          return this.classifiedThumbsPatterns.stretch.gallery
        } else {
          return this.classifiedThumbsPatterns.stretch.list
        }
      }
      return false
    },
    thumbnailClasses() {
      const classes = []
      if (this.userIsAdmin) {
        const { user } = this.classified
        if (user) {
          const sellerHasShop = Boolean(user.public_dealer)
          if (sellerIsOfTypeDealer(user) && this.isCar) {
            if (sellerHasShop) {
              classes.push('thumbnail-admin-dealer-with-shop')
            } else {
              classes.push('thumbnail-admin-dealer-without-shop')
            }
          }
        }
      } else if (
        this.userIsOfTypeDealer &&
        (this.classified.dealer_cls || this.classified.dealerCls)
      ) {
        classes.push('thumbnail-dealer-viewed-by-dealer')
      }
      if (!this.hasPhotos) {
        classes.push('no-photo')
      }
      if (this.thumbnailFit) {
        classes.push('fit-photo')
      }
      if (this.isPlotClassified) {
        classes.push('is-plot')
      }
      classes.push(`for-${this.viewType}`)
      return classes
    },
    thumbLink() {
      let link = ''

      let thumbsize = 'n'
      let normalSize = 'm'
      let smallSize = 'm'

      if (this.isPlotClassified) {
        thumbsize = 'v'
        normalSize = 'a'
        smallSize = 'm'
      }

      switch (this.viewType) {
        case ViewType.GALLERY: {
          if (process.client && window.innerWidth >= 1400) {
            thumbsize = normalSize
          } else {
            thumbsize = smallSize
          }
          break
        }
        case 'feed': {
          thumbsize = smallSize
          break
        }
        case 'parking': {
          thumbsize = smallSize
          break
        }
        case 'sm': {
          thumbsize = smallSize
          break
        }
      }

      if (this.thumbs[this.currentThumb]) {
        link = this.thumbs[this.currentThumb].replace('{size}', thumbsize)
      } else {
        this.$logger.captureError(new Error('no thumb found'))
      }

      return link
    },
    thumbs() {
      let t = []
      if (this.images.length) {
        // do stuff
        t = this.images.map(image => {
          return image.medium
        })
      } else if (this.classifiedThumbsPatterns) {
        t = this.classifiedThumbsPatterns.urls
      } else if (this.classifiedThumbPattern) {
        if (this.viewType === 'gallery') {
          t.push(this.classifiedThumbPattern.replace('{size}', 'v'))
        } else {
          t.push(this.classifiedThumbPattern)
        }
      }

      if (this.onlyShowFirst && t.length > 1) {
        return [t[0]]
      }

      return t
    },
    isSwipable() {
      // overwrite this if you want to conditionally add the listeners
      return this.thumbs.length
    }
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.stopInput)
    document.removeEventListener('touchend', this.stopInput)
  },
  mounted() {
    if (this.thumbs.length > 1) {
      this.addSwipeListeners()
    }
  },
  methods: {
    nextThumb() {
      this.imgLoaded = false
      if (this.currentThumb + 1 > this.thumbs.length - 1) {
        this.currentThumb = 0
      } else {
        this.currentThumb++
      }
      this.toggleShowCountTimeout()
    },
    prevThumb() {
      this.imgLoaded = false
      if (this.currentThumb - 1 < 0) {
        this.currentThumb = this.thumbs.length - 1
      } else {
        this.currentThumb--
      }
      this.toggleShowCountTimeout()
    },
    imgLoad() {
      this.imgLoaded = true
      this.$emit('image-load')
    },
    toggleShowCountTimeout() {
      this.showCount = true
      if (this.showCountTimeout) {
        clearTimeout(this.showCountTimeout)
      }
      this.showCountTimeout = setTimeout(() => {
        this.showCount = false
      }, 1500)
    },
    onSwipeRight() {
      this.prevThumb()
    },
    onSwipeLeft() {
      this.nextThumb()
    },
    onImageClick(event) {
      if (!this.disableImageClick) {
        event.preventDefault()
        event.stopPropagation()
        this.imgClicked(event)
      }
      // else imgClicked can still be triggered programatically
    },
    imgClicked(event) {
      if (this.dragging) {
        this.dragging = false
        return
      }

      if (!this.showLink) {
        this.$emit('image-clicked', {
          thumbIndex: this.currentThumb
        })
        return
      }

      if (!this.imgLoaded) {
        this.$emit('clicked', event, {
          thumb: this.thumbLink,
          thumbIndex: this.currentThumb
        })
        return
      }

      const thumbImg = new Image()
      thumbImg.onload = () => {
        this.$emit('clicked', event, {
          thumb: imageToDataURL(thumbImg),
          thumbIndex: this.currentThumb
        })
      }

      thumbImg.onerror = () => {
        // it is probably a CORS error (and we are probably in dev), so do it the old way
        this.$emit('clicked', event, {
          thumb: this.thumbLink,
          thumbIndex: this.currentThumb
        })
      }

      thumbImg.crossOrigin = 'Anonymous'
      thumbImg.src = this.thumbLink
    }
  }
})
