


















































































import Vue from 'vue'
import {
  faChevronLeft,
  faChevronRight,
  faCaretLeft,
  faCaretRight
} from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex'
import { APP_NS } from '~/store/modules/shared/app/state'
export default Vue.extend({
  props: {
    text: { type: String, default: '' },
    hiddenHeight: { type: Number, default: 340 },
    imagesInSlot: { type: Boolean, default: false, required: false },
    bgVariant: { type: String, default: '', required: false },
    btnVariant: { type: String, default: '', required: false },
    noFade: {
      type: Boolean,
      required: false,
      default: false
    },
    preventVertical: {
      type: Boolean,
      required: false,
      default: false
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false
    },
    hideArrows: {
      type: Boolean,
      required: false,
      default: false
    },
    arrowsOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    caretArrows: {
      type: Boolean,
      default: false
    },
    slidesToShow: {
      type: Number,
      required: false,
      default: 0
    },
    teaseNextSlide: {
      type: Boolean,
      required: false,
      default: false
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      icons: {
        chevronLeft: faChevronLeft,
        chevronRight: faChevronRight,
        caretLeft: faCaretLeft,
        caretRight: faCaretRight
      },
      initialX: null,
      initialY: null,
      initialScrollX: null,
      initialScrollY: null,
      // direction of the swap
      swapDirection: null,
      // flag that shows whether or not we are dragging
      dragging: false,
      scrollX: 0,
      scrollY: 0,
      transit: true,
      // swipe velocity calculation
      diffX: 0,
      diffY: 0,
      timeStart: 0,
      timeFinish: 0,
      lastMoveTime: 0,
      // flag if this is mobile-touch event
      isTouch: false,
      dsWidth: null,
      dsWidthValue: '',
      dsHeight: null,
      dsHeightValue: '',
      imagesLength: 0,
      imagesLoaded: 0,
      lessSlidesThanWidth: true,
      lessSlidesThanHeight: true,
      disableHref: false,
      listenersAdded: false,
      slidesWidth: 0,
      slidesHeight: 0,
      slidesLength: 0,
      activeChildIndex: -1
    }
  },
  computed: {
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    dragScrollContClasses() {
      const disabledRight =
        this.$refs.dragscrollCont &&
        this.scrollX <= -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth)
      return [
        this.bgVariant,
        {
          'has-scroll': !this.lessSlidesThanWidth,
          'has-fade': !this.noFade,
          'disabled-left': this.scrollX >= 0,
          'disabled-right': disabledRight,
          'tw-h-full': this.vertical
        }
      ]
    },
    btnVariantToUse() {
      if (!this.btnVariant) {
        if (this.isPlot) {
          return 'plot-blue'
        }
        // the default:
        return 'black'
      }
      return this.btnVariant
    },
    isTouchDevice() {
      return 'ontouchstart' in window
    }
  },
  watch: {
    imagesLoaded(newVal) {
      if (newVal === this.imagesLength) {
        this.calcDsWidth()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSlot()
      this.calcDsWidth()
    })
  },
  beforeDestroy() {
    this.removeScrollListeners()
  },
  destroyed() {
    this.dsWidth = 0
    this.dsHeight = 0
    this.scrollX = 0
    this.scrollY = 0
  },
  methods: {
    addScrollListeners() {
      this.$nextTick(() => {
        if (!this.$refs.dragscroll) {
          return
        }
        this.$refs.dragscroll.addEventListener(
          'selectstart',
          this.disableSelect
        )

        if (this.isTouchDevice) {
          this.$refs.dragscroll.addEventListener(
            'touchstart',
            this.startTouch,
            { passive: !this.preventVertical }
          )

          this.$refs.dragscroll.addEventListener('touchmove', this.moveInput, {
            passive: !this.preventVertical
          })
        } else {
          this.$refs.dragscroll.addEventListener('mousedown', this.startInput, {
            passive: !this.preventVertical
          })

          this.$refs.dragscroll.addEventListener('mousemove', this.moveInput, {
            passive: !this.preventVertical
          })

          this.$refs.dragscroll.addEventListener(
            'mousewheel',
            this.mousewheel,
            { passive: !this.preventVertical }
          )
        }

        this.listenersAdded = true
      })
    },
    removeScrollListeners() {
      if (!this.$refs.dragscroll) {
        return
      }
      this.$refs.dragscroll.removeEventListener('mousedown', this.startInput)
      this.$refs.dragscroll.removeEventListener(
        'selectstart',
        this.disableSelect
      )
      this.$refs.dragscroll.removeEventListener('mousemove', this.moveInput)

      this.$refs.dragscroll.removeEventListener('touchstart', this.startTouch)

      this.$refs.dragscroll.removeEventListener('touchmove', this.moveInput)

      this.$refs.dragscroll.removeEventListener('mousewheel', this.mousewheel)
      this.removeDocumentListeners()
    },
    removeDocumentListeners() {
      document.removeEventListener('mouseup', this.stopInput)
      document.removeEventListener('touchend', this.stopInput)
    },
    initSlot() {
      if (!this.$refs.dragscroll) {
        return
      }

      const theSlots = this.getSlots()

      if (theSlots.length === 1) {
        const theslot = theSlots[0]
        theslot.style.cssText = 'display:inline-flex; flex-wrap: nowrap'
        this.calcDsWidth()
      } else if (this.vertical) {
        const containerHeight = this.$refs.dragscrollCont.offsetHeight

        if (theSlots.length && containerHeight) {
          this.slidesLength = theSlots.length
          for (let i = 0; i < theSlots.length; i++) {
            const slot = theSlots[i]

            if (this.slidesToShow) {
              let sheight = containerHeight / this.slidesToShow

              if (this.teaseNextSlide) {
                sheight = sheight - sheight * 0.09 // reduce height by 9% to tease the next slide
              }
              this.slidesHeight = sheight
              slot.style.cssText = `display:inline-flex; flex-wrap: nowrap; flex-shrink: 0; height: ${this.slidesHeight}px`
            } else {
              slot.style.cssText = `display:inline-flex; flex-wrap: nowrap; flex-shrink: 0;`
              this.calcDsHeight()
            }
          }
        }
      } else {
        const containerWidth = this.$refs.dragscrollCont.offsetWidth

        if (theSlots.length && containerWidth) {
          this.slidesLength = theSlots.length
          for (let i = 0; i < theSlots.length; i++) {
            const slot = theSlots[i]

            if (this.slidesToShow) {
              let swidth = containerWidth / this.slidesToShow

              if (this.teaseNextSlide) {
                swidth = swidth - swidth * 0.09 // reduce width by 9% to tease the next slide
              }
              this.slidesWidth = swidth
              slot.style.cssText = `display:inline-flex; flex-wrap: nowrap; flex-shrink: 0; width: ${this.slidesWidth}px`
            } else {
              slot.style.cssText = `display:inline-flex; flex-wrap: nowrap; flex-shrink: 0;`
              this.calcDsWidth()
            }
          }
        }
      }
    },
    calcDsWidth() {
      if (this.vertical) {
        this.calcDsHeight()
        return
      }
      this.$nextTick(() => {
        window.requestAnimationFrame(() => {
          if (!this.$refs.dragscroll || !this.$refs.dragscrollCont) {
            return
          }

          if (this.slidesToShow) {
            this.dsWidth = this.$refs.dragscroll.scrollWidth

            if (this.dsWidth !== 0) {
              this.dsWidthValue = this.dsWidth + 'px'
            }
            const theSlots = this.getSlots() || []
            this.lessSlidesThanWidth = theSlots.length < this.slidesToShow
          } else {
            const theSlots = this.getSlots()
            let slotsWidth = 0
            for (let i = 0; i < theSlots.length; i++) {
              const slot = theSlots[i]
              slotsWidth += slot.scrollWidth
            }

            this.dsWidth = slotsWidth

            if (this.dsWidth !== 0) {
              this.dsWidthValue = this.dsWidth + 'px'
            }

            this.lessSlidesThanWidth =
              this.dsWidth <= this.$refs.dragscrollCont.offsetWidth
          }

          if (this.lessSlidesThanWidth) {
            this.dsWidthValue = this.inline ? 'auto' : '100%'
          } else if (!this.listenersAdded) {
            this.addScrollListeners()
          }

          if (this.$refs.dragscrollCont.offsetWidth > 0) {
            this.$emit('container-width', this.$refs.dragscrollCont.offsetWidth)
          }
        })
      })
    },
    calcDsHeight() {
      this.$nextTick(() => {
        window.requestAnimationFrame(() => {
          if (!this.$refs.dragscroll || !this.$refs.dragscrollCont) {
            return
          }

          if (this.slidesToShow) {
            this.dsHeight = this.$refs.dragscroll.scrollHeight

            if (this.dsHeight !== 0) {
              this.dsHeightValue = this.dsHeight + 'px'
            }
            const theSlots = this.getSlots() || []
            this.lessSlidesThanHeight = theSlots.length < this.slidesToShow
          } else {
            const theSlots = this.getSlots()
            let slotsHeight = 0
            for (let i = 0; i < theSlots.length; i++) {
              const slot = theSlots[i]
              slotsHeight += slot.scrollHeight
            }

            this.dsHeight = slotsHeight

            if (this.dsHeight !== 0) {
              this.dsHeightValue = this.dsHeight + 'px'
            }

            this.lessSlidesThanHeight =
              this.dsHeight <= this.$refs.dragscrollCont.offsetHeight
          }

          if (this.lessSlidesThanHeight) {
            this.dsHeightValue = this.inline ? 'auto' : '100%'
          } else if (!this.listenersAdded) {
            this.addScrollListeners()
          }

          if (this.$refs.dragscrollCont.offsetHeight > 0) {
            this.$emit(
              'container-height',
              this.$refs.dragscrollCont.offsetHeight
            )
          }
        })
      })
    },
    mousewheel(e) {
      if (!this.dragging) {
        if (Math.abs(e.deltaX) === 0) {
          return
        }
        if (e.deltaX > 0) {
          this.moveRight(e.deltaX)
        } else {
          this.moveLeft(Math.abs(e.deltaX))
        }
      }
    },
    disableSelect(e) {
      e.preventDefault()
    },
    startInput(e) {
      if (!this.isTouch && e.button !== 0) {
        // not left click
        return
      }
      if (this.isTouch) {
        document.addEventListener('touchend', this.stopInput, {
          passive: !this.preventVertical
        })
      } else {
        document.addEventListener('mouseup', this.stopInput, {
          passive: !this.preventVertical
        })
      }

      if (this.isTouch) {
        this.initialX = e.touches[0].clientX
        this.initialY = e.touches[0].clientY
      } else {
        this.initialX = e.clientX
        this.initialY = e.clientY
      }

      this.initialScrollX = -this.scrollX
      this.initialScrollY = -this.scrollY

      this.transit = false
      this.timeStart = new Date().getTime()
    },
    startTouch(e) {
      this.isTouch = true
      this.startInput(e)
    },
    moveInput(e) {
      if (this.initialX === null || (this.vertical && this.initialY === null)) {
        return
      }

      let currentX = null
      let currentY = null

      if (this.isTouch) {
        currentX = e.touches[0].clientX
        currentY = e.touches[0].clientY
      } else {
        currentX = e.clientX
        if (this.vertical) {
          currentY = e.clientY
        }
      }

      this.diffX = this.initialX - currentX

      if (this.vertical) {
        this.diffY = this.initialY - currentY
      }

      // 10 is a bit of offset, normally it's 0
      if (this.vertical && Math.abs(this.diffY) + 10 > Math.abs(this.diffX)) {
        this.lastMoveTime = new Date().getTime()
        // we scroll on the y-axis
        this.dragging = true

        if (this.dragging) {
          const calculatedY = -(this.initialScrollY + this.diffY)

          // limits
          if (
            this.$refs.dragscrollCont &&
            calculatedY < 50 &&
            calculatedY >
              -(this.dsHeight - this.$refs.dragscrollCont.offsetHeight + 50)
          ) {
            this.scrollY = calculatedY
          }
        }

        e.stopImmediatePropagation()
        e.stopPropagation()
        // e.preventDefault()

        if (this.diffY > 10) {
          // swiped down
          this.swapDirection = 'down'
        } else if (this.diffY < -10) {
          // swiped up
          this.swapDirection = 'up'
        } else {
          this.swapDirection = null
          this.dragging = false // so that it gets clicked instantly
        }
      } else if (Math.abs(this.diffX) + 10 > Math.abs(this.diffY)) {
        this.lastMoveTime = new Date().getTime()
        // we scroll on the x-axis
        this.dragging = true

        if (this.dragging) {
          const calculatedX = -(this.initialScrollX + this.diffX)

          // limits
          if (
            this.$refs.dragscrollCont &&
            calculatedX < 50 &&
            calculatedX >
              -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth + 50)
          ) {
            this.scrollX = calculatedX
          }
        }

        e.stopImmediatePropagation()
        e.stopPropagation()
        // e.preventDefault()

        if (this.diffX > 10) {
          // swiped left
          this.swapDirection = 'left'
        } else if (this.diffX < -10) {
          // swiped right
          this.swapDirection = 'right'
        } else {
          this.swapDirection = null
          this.dragging = false // so that it gets clicked instantly
        }
      }

      if (this.preventVertical && this.swapDirection && e.cancelable) {
        e.preventDefault()
      }
    },
    stopInput() {
      if (!this.transit) {
        this.$emit('move-done')
      }
      this.transit = true

      if (this.dragging && !this.disableHref) {
        this.hrefDisable(true)
      }

      this.timeFinish = new Date().getTime()

      const shouldSwipe = this.timeFinish - this.lastMoveTime < 20

      if (this.vertical && !this.lessSlidesThanHeight && shouldSwipe) {
        let swipeVelocity = Math.abs(
          Math.floor(this.diffY / ((this.timeFinish - this.timeStart) / 1000))
        )
        // add some smooth scrolling before ending
        if (swipeVelocity > 100) {
          if (swipeVelocity > 4000) {
            // limiter
            swipeVelocity = 4000
          }

          if (this.swapDirection === 'up' && this.scrollY < 0) {
            this.scrollY += 100 + swipeVelocity * 0.1
          } else if (
            this.$refs.dragscrollCont &&
            this.swapDirection === 'down' &&
            this.scrollY >
              -(this.dsHeight - this.$refs.dragscrollCont.offsetHeight)
          ) {
            this.scrollY -= 100 + swipeVelocity * 0.1
          }
        }
      } else if (!this.lessSlidesThanWidth && shouldSwipe) {
        let swipeVelocity = Math.abs(
          Math.floor(this.diffX / ((this.timeFinish - this.timeStart) / 1000))
        )
        // add some smooth scrolling before ending
        if (swipeVelocity > 100) {
          if (swipeVelocity > 4000) {
            // limiter
            swipeVelocity = 4000
          }

          if (this.swapDirection === 'right' && this.scrollX < 0) {
            this.scrollX += 100 + swipeVelocity * 0.1
          } else if (
            this.$refs.dragscrollCont &&
            this.swapDirection === 'left' &&
            this.scrollX >
              -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth)
          ) {
            this.scrollX -= 100 + swipeVelocity * 0.1
          }
        }
      }

      setTimeout(() => {
        this.hrefDisable(false)
      }, 100)

      this.checkScrollLimits()

      this.dragging = false

      this.swapDirection = null
      this.initialX = null
      this.initialY = null
      this.transit = true
      this.diffX = null
      this.diffY = null
      this.isTouch = false

      this.removeDocumentListeners()
    },
    checkScrollLimits() {
      if (this.$refs.dragscrollCont) {
        if (
          this.vertical &&
          this.$refs.dragscrollCont.offsetHeight <= this.dsHeight
        ) {
          if (this.scrollY > 0) {
            setTimeout(() => {
              this.scrollY = 0
            }, 100)
          } else if (
            this.scrollY <
            -(this.dsHeight - this.$refs.dragscrollCont.offsetHeight)
          ) {
            setTimeout(() => {
              if (this.$refs.dragscrollCont) {
                this.scrollY = -(
                  this.dsHeight - this.$refs.dragscrollCont.offsetHeight
                )
              }
            }, 100)
          } else if (this.slidesToShow && this.slidesHeight && this.scrollY) {
            // lock mechanism for slides
            this.scrollY = -this.getClosestScrolY()
          }
          this.calcIndexVertical(this.scrollY)
        } else if (this.$refs.dragscrollCont.offsetWidth <= this.dsWidth) {
          if (this.scrollX > 0) {
            setTimeout(() => {
              this.scrollX = 0
            }, 100)
          } else if (
            this.scrollX <
            -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth)
          ) {
            setTimeout(() => {
              if (this.$refs.dragscrollCont) {
                this.scrollX = -(
                  this.dsWidth - this.$refs.dragscrollCont.offsetWidth
                )
              }
            }, 100)
          } else if (this.slidesToShow && this.slidesWidth && this.scrollX) {
            // lock mechanism for slides
            this.scrollX = -this.getClosestScrolX()
          }
          this.calcIndex(this.scrollX)
        }
      }
    },
    getClosestScrolX() {
      if (this.scrollX) {
        const scrollLimits = [0]
        for (let i = 1; i <= this.slidesLength; i++) {
          scrollLimits.push(i * this.slidesWidth)
        }
        const closest = scrollLimits.reduce((prev, curr) => {
          return Math.abs(curr - Math.abs(this.scrollX)) <
            Math.abs(prev - Math.abs(this.scrollX))
            ? curr
            : prev
        })
        return closest
      }
      return 0
    },
    getClosestScrolY() {
      if (this.scrollY) {
        const scrollLimits = [0]
        for (let i = 1; i <= this.slidesLength; i++) {
          scrollLimits.push(i * this.slidesHeight)
        }
        const closest = scrollLimits.reduce((prev, curr) => {
          return Math.abs(curr - Math.abs(this.scrollY)) <
            Math.abs(prev - Math.abs(this.scrollY))
            ? curr
            : prev
        })
        return closest
      }
      return 0
    },
    stopHrefClick(e) {
      if (this.disableHref) {
        e.preventDefault()
        e.stopPropagation()
        this.hrefDisable(false)
        return false
      }
    },
    hrefDisable(state) {
      this.disableHref = state
      this.$emit('show-link', !this.disableHref)
    },
    moveLeftClicked() {
      if (this.$refs.dragscrollCont) {
        this.moveLeft(this.calcOffsetWidthWithTease())
        this.calcIndex(this.scrollX)
      }
    },
    moveRightClicked() {
      if (this.$refs.dragscrollCont) {
        this.moveRight(this.calcOffsetWidthWithTease())
        this.calcIndex(this.scrollX)
      }
    },
    moveUpClicked() {
      if (this.$refs.dragscrollCont) {
        this.moveUp(this.calcOffsetHeightWithTease())
        this.calcIndexVertical(this.scrollX)
      }
    },
    moveDownClicked() {
      if (this.$refs.dragscrollCont) {
        this.moveDown(this.calcOffsetHeightWithTease())
        this.calcIndexVertical(this.scrollY)
      }
    },
    calcOffsetWidthWithTease() {
      let offsetWidth = this.$refs.dragscrollCont.offsetWidth
      if (this.teaseNextSlide) {
        const swidth = offsetWidth / this.slidesToShow
        offsetWidth = swidth - swidth * 0.09
      }
      return offsetWidth
    },
    calcOffsetHeightWithTease() {
      let offsetHeight = this.$refs.dragscrollCont.offsetHeight
      if (this.teaseNextSlide) {
        const sheight = offsetHeight / this.slidesToShow
        offsetHeight = sheight - sheight * 0.09
      }
      return offsetHeight
    },
    moveLeft(amount) {
      this.scrollX += amount
      if (this.scrollX >= 0) {
        this.moveToStart()
      }
      this.$emit('move-done')
    },
    moveRight(amount) {
      this.scrollX -= amount
      if (
        this.$refs.dragscrollCont &&
        this.scrollX <= -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth)
      ) {
        this.scrollX = -(this.dsWidth - this.$refs.dragscrollCont.offsetWidth)
        this.checkScrollLimits()
        this.transit = true
      }
      this.$emit('move-done')
    },
    moveUp(amount) {
      this.scrollY += amount
      if (this.scrollY >= 0) {
        this.moveToStart()
      }
      this.$emit('move-done')
    },
    moveDown(amount) {
      this.scrollY -= amount
      if (
        this.$refs.dragscrollCont &&
        this.scrollY <=
          -(this.dsHeight - this.$refs.dragscrollCont.offsetHeight)
      ) {
        this.scrollY = -(this.dsHeight - this.$refs.dragscrollCont.offsetHeight)
        this.checkScrollLimits()
        this.transit = true
      }
      this.$emit('move-done')
    },
    moveToStart() {
      this.scrollX = 0
      this.scrollY = 0
      this.checkScrollLimits()
      this.transit = true
      this.$emit('index-change', 0)
    },
    imgLoad() {
      this.calcDsWidth()
    },
    calcIndex(scrollX: number) {
      if (this.slidesToShow) {
        const containerOffsetWidth =
          this.teaseNextSlide && this.slidesWidth
            ? this.slidesWidth
            : this.$refs.dragscrollCont.offsetWidth

        this.activeChildIndex = Math.round(
          Math.abs(scrollX) / containerOffsetWidth
        )
        this.$emit('index-change', this.activeChildIndex)
      }
    },
    calcIndexVertical(scrollY: number) {
      if (this.slidesToShow) {
        const containerOffsetHeight =
          this.teaseNextSlide && this.slidesHeight
            ? this.slidesHeight
            : this.$refs.dragscrollCont.offsetHeight
        this.activeChildIndex = Math.round(
          Math.abs(scrollY) / containerOffsetHeight
        )
        this.$emit('index-change', this.activeChildIndex)
      }
    },
    resetSlot() {
      this.dsWidthValue = 0
    },
    setActiveChildIndex(index) {
      const oldIndex = this.activeChildIndex
      this.activeChildIndex = index
      const theSlots = this.getSlots()

      if (theSlots?.length > 1) {
        // check if active element is within limits, if not fix it
        let offset = 40
        if (
          index === 0 ||
          index === theSlots.length - 1 ||
          this.lessSlidesThanWidth ||
          (this.vertical && this.lessSlidesThanHeight)
        ) {
          offset = 0
        }

        if (oldIndex < index) {
          // there is this edge case, when someone scrolls the thumbs and then clicks next/prev
          if (
            this.vertical &&
            this.$refs.dragscrollCont.offsetHeight + Math.abs(this.scrollY) <
              theSlots[index].offsetTop + theSlots[index].clientHeight
          ) {
            // thumb out of visible range, correcting...
            this.scrollY =
              this.$refs.dragscrollCont.offsetHeight -
              theSlots[index].clientHeight -
              theSlots[index].offsetTop -
              offset
          } else if (
            this.vertical &&
            Math.abs(this.scrollY) >= theSlots[index].offsetTop
          ) {
            // thumb out of visible range, correcting...
            this.scrollY = -theSlots[index].offsetTop + offset
          } else if (
            this.$refs.dragscrollCont.offsetWidth + Math.abs(this.scrollX) <
            theSlots[index].offsetLeft + theSlots[index].clientWidth
          ) {
            // thumb out of visible range, correcting...
            this.scrollX =
              this.$refs.dragscrollCont.offsetWidth -
              theSlots[index].clientWidth -
              theSlots[index].offsetLeft -
              offset
          } else if (Math.abs(this.scrollX) >= theSlots[index].offsetLeft) {
            // thumb out of visible range, correcting...
            this.scrollX = -theSlots[index].offsetLeft + offset
          }
        } else if (oldIndex > index) {
          if (
            this.vertical &&
            Math.abs(this.scrollY) >= theSlots[index].offsetTop
          ) {
            // thumb out of visible range, correcting...
            this.scrollY = -theSlots[index].offsetTop + offset
          } else if (
            this.vertical &&
            this.$refs.dragscrollCont.offsetHeight + Math.abs(this.scrollY) <
              theSlots[index].offsetTop + theSlots[index].clientHeight
          ) {
            // thumb out of visible range, correcting...
            this.scrollY =
              this.$refs.dragscrollCont.offsetHeight -
              theSlots[index].clientHeight -
              theSlots[index].offsetTop -
              offset
          } else if (Math.abs(this.scrollX) >= theSlots[index].offsetLeft) {
            // thumb out of visible range, correcting...
            this.scrollX = -theSlots[index].offsetLeft + offset
          } else if (
            this.$refs.dragscrollCont.offsetWidth + Math.abs(this.scrollX) <
            theSlots[index].offsetLeft + theSlots[index].clientWidth
          ) {
            // thumb out of visible range, correcting...
            this.scrollX =
              this.$refs.dragscrollCont.offsetWidth -
              theSlots[index].clientWidth -
              theSlots[index].offsetLeft -
              offset
          }
        }
        this.$nextTick(() => {
          this.checkScrollLimits()
        })
      }
    },
    childIsVisible(index) {
      this.calcDsWidth()

      if (this.lessSlidesThanWidth || this.activeChildIndex === index) {
        return true
      }
      const theSlots = this.getSlots()

      if (theSlots?.length > 1) {
        if (this.activeChildIndex < index) {
          if (
            this.$refs.dragscrollCont.offsetWidth + Math.abs(this.scrollX) <
            theSlots[index].offsetLeft + theSlots[index].clientWidth
          ) {
            return false
          } else if (Math.abs(this.scrollX) >= theSlots[index].offsetLeft) {
            return false
          }
        } else if (this.activeChildIndex > index) {
          if (Math.abs(this.scrollX) >= theSlots[index].offsetLeft) {
            return false
          } else if (
            this.$refs.dragscrollCont.offsetWidth + Math.abs(this.scrollX) <
            theSlots[index].offsetLeft + theSlots[index].clientWidth
          ) {
            return false
          }
        }
      }
      return true
    },
    scrollToElementIfOverflown(elem: HTMLElement) {
      if (!elem) {
        return
      }

      const container = this.$refs.dragscrollCont

      const elemIsOverflownLeft = elem.offsetLeft < Math.abs(this.scrollX)

      const elemIsOverflownRight =
        elem.offsetLeft + elem.clientWidth >
        container.offsetWidth + Math.abs(this.scrollX)

      if (elemIsOverflownLeft || elemIsOverflownRight) {
        const offset =
          elem.offsetLeft === 0 ||
          elem.offsetLeft + elem.clientWidth >= this.dsWidth
            ? 0
            : 20 // 20px is the fade width

        if (elemIsOverflownLeft) {
          this.scrollX = -(elem.offsetLeft - offset)
        } else {
          this.scrollX -=
            elem.offsetLeft + elem.clientWidth - container.offsetWidth + offset
        }
        this.checkScrollLimits()
        this.transit = true
      }
    },
    getSlots() {
      const childNodes = Array.from(this.$refs.dragscroll.childNodes)
      return childNodes.filter(n => n?.nodeType === Node.ELEMENT_NODE)
    }
  }
})
