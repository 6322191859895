import { Search } from '~/router/routes/car/classifieds/search/components'

export const rentalsRoutes = [
  {
    name: '__classifieds_search_rentals',
    path: '/rentals/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_rentals_seo',
    path: '/rentals/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
