import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { AdminExtras, UserAndAdminExtrasResult } from '~/models/user/types'
import { invalidBodyError } from '~/services/errors'
import { toCamelCase } from '~/utils/object'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  UserBasicInfoSchemaResult,
  UserMultipleTelephonesResult
} from '~/models/user/user'
import { DynamicFormFieldValues } from '~/models/dynamic-form/schema'
import { AccountMultipleTelephonesForm } from '~/models/account'
import { toSnakeCase } from '~/utils/snake-case'

@containerScoped()
export default class UserExtrasService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  async getUserExtras(): Promise<UserAndAdminExtrasResult> {
    const response = await this.http.get('/api/user/base-page/')
    const body = response.data
    if (!(body && body.data)) {
      throw invalidBodyError(body)
    }
    const userExtras = {
      unreadNotifications: body.data?.notifications?.unread || 0,
      unreadMessages: body.data?.messages?.unread || 0,
      unreadParkingNotifications: body.data?.parking_notifications?.unread || 0,
      unreadTradesNotifications: body.data?.trades_notifications || 0,
      myClassifieds: body.data.my_classifieds
        ? toCamelCase(body.data.my_classifieds.links)
        : [],
      hasTickets: !!body.data.tickets,
      xmlIssues: body.data.xml ? body.data.xml.has_issues : undefined,
      invoicesCount: body.data.invoices?.count || undefined,
      outletsCount: body.data.outlets?.count || undefined,
      rogueClassifiedsCount: body.data.rogue_classifieds?.count || undefined,
      rogueRequirements: body.data.rogue_classifieds
        ? toCamelCase(body.data.rogue_classifieds)
        : undefined,
      auditRequestsCount: body.data.audit_requests || undefined,
      hasParked: body.data.has_parked || false,
      saleRequests: body.data.sale_requests
    }

    let adminExtrasData
    if (
      body.data.tickets ||
      body.data.reports ||
      body.data.performance ||
      body.data.pages ||
      body.data.suas ||
      body.data.pending_ads ||
      body.data.agent_applications
    ) {
      adminExtrasData = {
        adminExtras: {
          ticketsCount: body.data.tickets
            ? body.data.tickets.unread
            : undefined,
          reportCount: body.data.reports ? body.data.reports.unread : undefined,
          agentApplicationsCount: body.data.agent_applications
            ? body.data.agent_applications.unread
            : undefined,
          performance: body.data.performance,
          pages: body.data.pages,
          suas: body.data.suas && toCamelCase(body.data.suas),
          pendingAds: body.data.pending_ads
        }
      }
    }

    return {
      userExtras,
      adminExtrasData
    }
  }

  async getAdminExtras(): Promise<{ adminExtras: AdminExtras }> {
    const response = await this.http.get('/api/user/base-page/')
    const body = response.data
    if (
      !(
        body &&
        body.data &&
        (body.data.tickets ||
          body.data.reports ||
          body.data.performance ||
          body.data.pages ||
          body.data.suas ||
          body.data.agent_applications)
      )
    ) {
      throw invalidBodyError(body)
    }

    return {
      adminExtras: {
        ticketsCount: body.data.tickets ? body.data.tickets.unread : undefined,
        reportCount: body.data.reports ? body.data.reports.unread : undefined,
        agentApplicationsCount: body.data.agent_applications
          ? body.data.agent_applications.unread
          : undefined,
        performance: body.data.performance,
        pages: body.data.pages,
        suas: body.data.suas && toCamelCase(body.data.suas)
      }
    }
  }

  getUserBasicInfoSchema(): Promise<UserBasicInfoSchemaResult> {
    return this.requestBuilder
      .request('get', '/api/user/basic-info/')
      .data()
      .send()
  }

  saveUSerBasicInfo(
    form: DynamicFormFieldValues
  ): Promise<UserBasicInfoSchemaResult> {
    return this.requestBuilder
      .request('post', '/api/user/basic-info/')
      .data(form)
      .send()
  }

  public getUserMultipleTelephones(): Promise<UserMultipleTelephonesResult> {
    return this.requestBuilder.request('get', '/api/user/telephones/').send()
  }

  public submitUserMultipleTelephones(
    form: AccountMultipleTelephonesForm
  ): Promise<UserMultipleTelephonesResult> {
    return this.requestBuilder
      .request('put', '/api/user/telephones/')
      .data(toSnakeCase(form))
      .validate(body => body.data.classified_telephones)
      .send()
  }
}
