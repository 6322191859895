














































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'
import { useFormComponent } from '~/compositions/form-component'
import { Size } from '~/models/app/size'

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String as PropType<HTMLInputElement['type']>,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: false,
      default: 'any'
    },
    noArrows: {
      type: Boolean,
      default: false
    },
    beforeAllowEvents: {
      type: Boolean,
      default: false
    },
    afterAllowEvents: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    inputClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MD
    },
    roundnessClass: {
      type: String,
      default: 'tw-rounded-md'
    },
    name: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['change', 'input'],
  setup(props, { emit, slots }) {
    const {
      value,
      trim,
      hasError,
      autofocus,
      floatingLabel,
      id,
      required,
      labelClass,
      size,
      beforeAllowEvents,
      afterAllowEvents,
      type
    } = toRefs(props)
    const { createRandomId } = useId()
    const {
      labelClasses,
      helperTextClasses,
      controlClasses
    } = useFormComponent(required, floatingLabel, labelClass, hasError)
    const internalValue = ref(value.value)
    const internalId = id.value || createRandomId()
    const inputTemplateRef = ref<HTMLInputElement | null>()

    watch(value, (newValue: string) => {
      internalValue.value = newValue
    })

    function handleKeydown(event: KeyboardEvent) {
      const forbiddenCharacters = ['e', 'E', '+']
      const allowsNegative =
        inputTemplateRef.value?.min && Number(inputTemplateRef.value.min) < 0

      if (!allowsNegative) {
        forbiddenCharacters.push('-')
      }
      if (type.value === 'number' && forbiddenCharacters.includes(event.key)) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        emit('keydown', event)
      }
    }

    function handleInput(event: InputEvent) {
      emit('input', extractText(event))
    }

    function handleChange(event: any) {
      emit('change', extractText(event))
    }

    function extractText(event: InputEvent) {
      const text = (event.target as HTMLInputElement).value
      return trim.value ? text.trim() : text
    }

    const inputFloatingLabelClasses = computed(() =>
      floatingLabel.value
        ? ['tw-pt-2.5 placeholder:tw-text-transparent tw-peer']
        : []
    )
    const inputSizeClasses = computed(() => {
      switch (size.value) {
        case Size.SM: {
          return ['tw-py-1', 'tw-text-base']
        }
        case Size.LG: {
          return ['tw-py-2', 'tw-text-2xl']
        }
        case Size.MD:
        default: {
          return ['tw-py-[0.375rem]']
        }
      }
    })

    onMounted(() => {
      handleAutofocus()
    })

    function handleAutofocus() {
      if (!autofocus.value) {
        return
      }

      focus()
    }

    function select() {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.select()
        })
      })
    }

    function focus() {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.focus()
        })
      })
    }

    function blur() {
      if (!inputTemplateRef.value) {
        return
      }
      nextTick(() => {
        window.requestAnimationFrame(() => {
          inputTemplateRef.value!.blur()
        })
      })
    }

    const inputBeforeAndAfterClasses = computed(() => {
      const c = []
      if (slots.before) {
        c.push('tw-pl-12')
      }
      if (slots.after) {
        c.push('tw-pr-12')
      }
      return c
    })

    const beforeAndAfterSizeClasses = computed(() => {
      switch (size.value) {
        case Size.SM: {
          return ['tw-text-sm']
        }
        case Size.LG: {
          return ['tw-text-lg']
        }
        case Size.MD:
        default: {
          return []
        }
      }
    })
    const beforeClasses = computed(() => {
      return generateSlotClasses('before')
    })

    const afterClasses = computed(() => {
      return generateSlotClasses('after')
    })

    const slotClasses = computed(() => {
      return [
        'tw-absolute',
        'tw-inset-y-0',
        'tw-flex',
        'tw-items-center',
        'tw-text-grey-500'
      ]
    })

    function generateSlotClasses(slotName: string) {
      let classes = []
      const event = slotName === 'before' ? beforeAllowEvents : afterAllowEvents
      if (!event.value) {
        classes.push('tw-pointer-events-none')
      }
      classes = classes.concat(slotClasses.value)
      return [...classes, ...beforeAndAfterSizeClasses.value]
    }

    function onWheel(event: InputEvent) {
      if (type.value === 'number' && document.activeElement === event.target) {
        event.preventDefault()
      }
    }

    return {
      internalValue,
      internalId,
      helperTextClasses,
      inputTemplateRef,
      labelClasses,
      handleInput,
      handleKeydown,
      handleChange,
      controlClasses,
      inputFloatingLabelClasses,
      inputSizeClasses,
      inputBeforeAndAfterClasses,
      beforeClasses,
      afterClasses,
      focus,
      select,
      blur,
      onWheel
    }
  }
})
