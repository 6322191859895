import { interopDefault } from '~/router/utils'

export const cpanelAnnouncementsRoutes = [
  {
    name: '__announcements_list',
    path: '/cpanel/announcements/',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/cpanel/announcements/list.vue' /* webpackChunkName: "pages-cpanel-announcements-list.vue" */
        )
      )
  },
  {
    name: '__announcements_view',
    path: '/cpanel/announcements/:id/',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/cpanel/announcements/view.vue' /* webpackChunkName: "pages-cpanel-announcements-view.vue" */
        )
      )
  }
]
