import { FormattedTree, UnformattedTree } from '~/models/shared/tree/types'
import { SchemaField } from '~/models/shared/types'
import { Pagination } from '~/models/search/types'
import { Page, Ident, Geolocation, CompactPage } from '../models/common/types'

export function formatPage(pagination: any): Page {
  return {
    number: pagination.page,
    paramName: pagination.page_param,
    perPage: pagination.per_page,
    totalCount: pagination.total
  }
}

export function formatCompactPage(p: any, legacyUrl: string): CompactPage {
  return {
    breadcrumbs: p.breadcrumbs,
    metadata: p.metadata,
    seoUrl: p.seo_url,
    targetSeoUrl: p.target_seo_url,
    title: p.title,
    externalUrl: legacyUrl
  }
}

export function formatIdent(i: any): Ident {
  if (!i) {
    throw new Error('Ident should have at least one property defined.')
  }

  return {
    id: i.id && i.id.toString(),
    telephone: i.telephone,
    name: i.name,
    username: i.username,
    email: i.email
  }
}

export function formatGeolocation(
  g: number[] | undefined
): Geolocation | undefined {
  return Array.isArray(g)
    ? {
        lat: g[0],
        lon: g[1]
      }
    : undefined
}

export function flattenTree<T = object, TR = object>(
  tree: UnformattedTree<TR>,
  format: (tree: UnformattedTree<TR>) => FormattedTree<T>,
  getChildren: (tree: UnformattedTree<TR>) => UnformattedTree<TR>[] = tree =>
    tree.children
): Map<string, FormattedTree<T>> {
  const map = new Map()

  function flattenRecursive(tree: UnformattedTree<TR>) {
    const model = format(tree)
    map.set(model.id, model)
    const children = getChildren(tree)
    children && children.forEach(flattenRecursive)
  }

  flattenRecursive(tree)
  return map
}

export function formatPagination(p: any): Pagination {
  return {
    page: p.page,
    pageParam: p.page_param,
    perPage: p.per_page,
    total: p.total
  }
}

export function formatSchemaField(s: any): SchemaField {
  return {
    max: s.max,
    notEmpty: s.not_empty,
    required: s.required,
    type: s.type,
    notMissing: s.not_missing,
    values: s.values
  }
}
