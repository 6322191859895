import Vue from 'vue'
import { ParkingState } from '~/store/modules/shared/parking/state'
import { MutationTree } from 'vuex'

import {
  SET_GROUPS,
  SET_CLASSIFIEDS_LIST,
  SET_SEARCHES,
  SET_ERROR,
  REMOVE_SEARCH,
  ADD_TO_SEARCHES,
  REMOVE_CLASSIFIED,
  TOGGLE_RECENT_PARKED,
  SET_LOADING,
  SET_RECENT,
  SET_ACTIVE_TAB,
  SET_ACTIVE_RECENT,
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  CLEAR_COMPARE,
  CLEAR_NOTIFICATION_FOR_INDEX,
  CLEAR_NOTIFICATION_FOR_CLASSIFIED,
  SET_STYLE,
  SET_CLASSIFIED_ORDER,
  SET_CLASSIFIEDS_WITH_NOTIFICATIONS,
  SHOW_PARKING,
  HIDE_PARKING,
  EDIT_CLASSIFIED_LIST,
  FETCH_ALL_CLASSIFIED_LISTS,
  FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID,
  REMOVE_CLASSIFIED_LIST,
  CREATE_CLASSIFIED_LIST,
  SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING,
  COPY_CLASSIFIED_LIST,
  SET_CLASSIFIED_LIST_LOADING,
  DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST,
  REMOVE_LIST_FROM_FAVORITES,
  SET_PARKING_NOTIFICATIONS,
  CLEAR_PARKING_NOTIFICATIONS,
  SET_DISPATCHED,
  SET_SWIPE_DIRECTION,
  SET_NOTES_FOR_CLASSIFIED
} from './mutation-types'
import { Pagination } from '~/models/search/types'
import { ClassifiedNote } from '~/models/classified/note'

export default {
  [SET_GROUPS](state, groups) {
    if (!groups) {
      state.groups = []
      return
    }

    // check if group is empty
    for (let i = 0; i < groups.length; i++) {
      if (!groups[i].classifieds) {
        continue
      }

      if (groups[i].classifieds.length === 0) {
        groups.splice(i, 1)
        // Exit due to removed group
        continue
      }

      let notifCount = 0

      for (let j = 0; j < groups[i].classifieds.length; j++) {
        if (groups[i].classifieds[j].notifications?.length) {
          notifCount++
        }
      }

      groups[i].group.notificationsCount = notifCount
    }
    state.groups = groups
  },
  [SET_CLASSIFIEDS_LIST](state, rows) {
    state.classifiedsList = rows
  },
  [SET_SEARCHES](state, searches) {
    state.searches = searches
  },
  [SET_ERROR](state, errorStatus) {
    state.error = errorStatus
  },
  [REMOVE_SEARCH](state, searchId) {
    if (state.searches) {
      for (let i = 0; i < state.searches.length; i++) {
        if (state.searches[i].query_id === searchId) {
          // eslint-disable-next-line no-console
          state.searches.splice(i, 1)
          return
        }
      }
    }

    return null
  },
  [ADD_TO_SEARCHES](state, searchId) {
    if (state.recent && state.recent.searches) {
      for (let i = 0; i < state.recent.searches.length; i++) {
        if (state.recent.searches[i].query_id === searchId) {
          state.searches && state.searches.push(state.recent.searches[i])
          return
        }
      }
    }

    return null
  },
  [REMOVE_CLASSIFIED](state, clsfdId) {
    if (state.groups) {
      for (let i = 0; i < state.groups.length; i++) {
        for (let j = 0; j < state.groups[i].classifieds.length; j++) {
          if (state.groups[i].classifieds[j].id === clsfdId) {
            state.groups[i].classifieds.splice(j, 1)
          }
        }
      }

      // check if group is empty
      for (let i = 0; i < state.groups.length; i++) {
        if (state.groups[i].classifieds.length === 0) {
          state.groups.splice(i, 1)
        }
      }
    }

    // remove from list
    if (state.classifiedsList) {
      for (let i = 0; i < state.classifiedsList.length; i++) {
        if (state.classifiedsList[i].id === clsfdId) {
          state.classifiedsList.splice(i, 1)
        }
      }
    }

    // remove from order
    for (let i = 0; i < state.classifiedOrder.length; i++) {
      if (state.classifiedOrder[i] === clsfdId) {
        state.classifiedOrder.splice(i, 1)
      }
    }

    return null
  },
  [TOGGLE_RECENT_PARKED](state, payload) {
    if (state.recent && state.recent.classifieds.length) {
      for (let i = 0; i < state.recent.classifieds.length; i++) {
        if (state.recent.classifieds[i].id === payload.classifiedId) {
          state.recent.classifieds[i].is_parked = payload.parked
        }
      }
    }
  },
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_RECENT](state, recent) {
    state.recent = recent
  },
  [SET_ACTIVE_TAB](state, tab) {
    state.activeTab = tab
  },
  [SET_ACTIVE_RECENT](state, recent) {
    state.activeRecent = recent
  },
  [ADD_TO_COMPARE](state, id) {
    state.compareList && state.compareList.push(id)
  },
  [REMOVE_FROM_COMPARE](state, id) {
    if (state.compareList) {
      for (let i = 0; i < state.compareList.length; i++) {
        if (state.compareList[i] === id) {
          state.compareList.splice(i, 1)
        }
      }
    }
  },
  [CLEAR_COMPARE](state) {
    state.compareList = []
  },
  [CLEAR_NOTIFICATION_FOR_INDEX](state, index) {
    if (state.groups) {
      state.groups[index].group.notificationsCount = 0
    }

    return null
  },
  [CLEAR_NOTIFICATION_FOR_CLASSIFIED](state, id) {
    // remove from classified object
    if (state.classifiedsList) {
      for (let i = 0; i < state.classifiedsList.length; i++) {
        if (state.classifiedsList[i].id === id) {
          state.classifiedsList[i].notifications = []
        }
      }
    }

    // remove from notifications array
    for (let i = 0; i < state.notificationClassifieds.length; i++) {
      if (state.notificationClassifieds[i] === id) {
        state.notificationClassifieds.splice(i, 1)
      }
    }
  },
  [SET_STYLE](state, style) {
    state.style = style
  },
  [SET_CLASSIFIED_ORDER](state, order) {
    state.classifiedOrder = order
  },
  [SET_CLASSIFIEDS_WITH_NOTIFICATIONS](state, withNotifications) {
    state.notificationClassifieds = withNotifications
  },
  [FETCH_ALL_CLASSIFIED_LISTS](state, lists) {
    const firstListItem = 0
    state.classifiedLists = lists
    if (lists?.length) {
      state.selectedList = lists[firstListItem]
      state.classifiedsPagination = lists[firstListItem].classifieds.pagination
    }
  },
  [FETCH_CLASSIFIED_LISTS_CLASSIFIEDS_BY_ID](state, { list, classifieds }) {
    const listItemIndex = state.classifiedLists?.findIndex(
      l => l.publicId === list.publicId
    )
    if (state.classifiedLists && listItemIndex > -1) {
      const lists = state.classifiedLists.slice()
      lists[listItemIndex].classifieds = classifieds
      state.classifiedLists = lists
      state.selectedList = lists[listItemIndex]
      lists[listItemIndex].classifiedsCount = classifieds.pagination.total
    } else {
      state.selectedList = list
    }
    state.classifiedsPagination = classifieds.pagination
  },
  [EDIT_CLASSIFIED_LIST](state, list) {
    const listIndex = state.classifiedLists.findIndex(
      l => l.publicId === list.publicId
    )
    list.classifieds = state.classifiedLists[listIndex].classifieds
    Vue.set(state.classifiedLists, listIndex, list)
    state.selectedList = list
  },
  [CREATE_CLASSIFIED_LIST](state, list) {
    state.classifiedLists.unshift({
      ...list,
      classifieds: { rows: [], pagination: {} }
    })
    if (!state.selectedList) {
      state.selectedList = list
    }
  },
  [REMOVE_CLASSIFIED_LIST](
    state,
    { listIdToRemove, classifieds, firstListItem }
  ) {
    const listIndex = state.classifiedLists.findIndex(
      l => l.publicId === listIdToRemove
    )
    state.classifiedLists.splice(listIndex, 1)

    if (firstListItem) {
      firstListItem.classifieds = classifieds
      state.selectedList = firstListItem
      state.classifiedsPagination = classifieds.pagination
    } else {
      state.selectedList = null
      state.classifiedsPagination = {} as Pagination
    }
  },
  [SET_CLASSIFIED_LIST_LOADING](state, loading) {
    state.classifiedListLoading = loading
  },
  [SET_CLASSIFIED_LIST_ACTION_BUTTONS_LOADING](state, loading) {
    state.classifiedListActionButtonsLoading = loading
  },
  [COPY_CLASSIFIED_LIST](state, list) {
    state.classifiedLists.unshift(list)
  },
  [DELETE_CLASSIFIED_FROM_CLASSIFIED_LIST](state, listId) {
    const listIndex = state.classifiedLists.findIndex(
      l => l.publicId === listId
    )
    state.classifiedLists[listIndex].classifiedsCount--
  },
  [REMOVE_LIST_FROM_FAVORITES](state, listId) {
    state.classifiedLists = state.classifiedLists.filter(
      c => c.publicId !== listId
    )
  },
  [SHOW_PARKING](state) {
    state.showParking = true
    state.parkUrlBefore = window.location.href
  },
  [HIDE_PARKING](state) {
    state.showParking = false
    state.parkUrlBefore = ''
    state.style = 'full'
  },
  [SET_PARKING_NOTIFICATIONS](state, count) {
    state.parkingNotifications = count
  },
  [CLEAR_PARKING_NOTIFICATIONS](state) {
    state.parkingNotifications = 0
  },
  [SET_DISPATCHED](state, dispatched) {
    state.dispatched = dispatched
  },
  [SET_SWIPE_DIRECTION](state, swipeDirection) {
    state.swipeDirection = swipeDirection
  },
  [SET_NOTES_FOR_CLASSIFIED](
    state,
    { note, clsfdId }: { note: ClassifiedNote | null; clsfdId: number }
  ) {
    // set for groups
    if (state.groups) {
      for (let i = 0; i < state.groups.length; i++) {
        for (let j = 0; j < state.groups[i].classifieds.length; j++) {
          if (state.groups[i].classifieds[j].id === clsfdId) {
            state.groups[i].classifieds[j].notes = note
          }
        }
      }
    }

    // set for list
    if (state.classifiedsList) {
      for (let i = 0; i < state.classifiedsList.length; i++) {
        if (state.classifiedsList[i].id === clsfdId) {
          state.classifiedsList[i].notes = note
        }
      }
    }
  }
} as MutationTree<ParkingState>
