import { Context } from '@nuxt/types'
import { ServerResponse } from 'http'
import VueRouter, { Route } from 'vue-router'
import { classifiedDealerSiteRouteNames } from '~/constants/dealer-site-route-names'
import { responseToken } from '~/constants/dependency-injection/tokens'
import LoggerService from '~/services/LoggerService'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import ImagePreloadRegistrar from '~/services/preload/ImagePreloadRegistrar'
import SearchService from '~/services/search/SearchService'
import { DealerSiteState } from '~/store/modules/shared/dealers/site/state'
import { ActionTreeWithRootState } from '~/store/types'
import { Breadcrumb } from '~/models/common/types'
import { getDealerSiteRouteName } from '~/utils/router'
import { trimEnd } from '~/utils/string'
import {
  classifiedPageSnippet,
  pageSnippet
} from '~/models/dealer/site/page-snippet'
import {
  PageSnippet,
  NonClassifiedPageSnippet
} from '~/models/dealer/site/page-snippet/types'
import { PageType, Conf } from '~/models/dealer/site/types'
import { getNamespacedStore } from '~/utils/store'
import { SET_BREADCRUMBS } from '../../page/mutation-types'
import { PAGE_NS } from '../../page/state'
import { SET_ANALYTICS } from './analytics/mutation-types'
import { DEALER_SITE_ANALYTICS_NS } from './analytics/state'
import { CLEAR_ERROR, SET_ERROR } from './error/mutation-types'
import { DEALER_SITE_ERROR_NS } from './error/state'
import {
  SET_SITE_CONTENT,
  SET_LOADING_STATE,
  SET_OUTLETS,
  SET_ACTIVE_OUTLET_ID,
  ADD_CUSTOM_PAGE_BODY,
  SET_WEBSITE_META,
  SET_SITE_LOADED,
  REGISTER_NAVIGATION_TO_OTHER_PAGE,
  SET_TEMPLATE_CONF
} from './mutation-types'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { ClassifiedViewVariant } from '~/models/classified/view/variant'

export default {
  async loadSite(
    { commit, dispatch, state: { siteLoaded } },
    serverSideContext?: Context
  ) {
    if (siteLoaded) {
      return
    }

    commit(SET_LOADING_STATE, true)
    await dispatch('loadSiteMeta', serverSideContext)
    await Promise.all([
      dispatch('loadLayout'),
      dispatch('loadOutlets', { mutateLoadingState: false })
    ])
    commit(SET_SITE_LOADED)
    commit(SET_LOADING_STATE, false)
  },
  async loadLayout({ dispatch, state: { websiteId } }) {
    const [dealerSiteLayout, imagePreloadRegistrar] = this.$deps(
      DealerSiteLayoutService,
      ImagePreloadRegistrar
    )
    try {
      const layout = await dealerSiteLayout.getLayout(websiteId)
      imagePreloadRegistrar.registerDealerSiteLayoutImages(layout)
      dispatch('setLayout', layout)
    } catch (error) {
      this.$logger.captureError(error)
    }
  },
  setLayout({ commit }, layout) {
    commit(SET_SITE_CONTENT, layout)
    commit(SET_TEMPLATE_CONF, layout.template.conf)
    commit(`${DEALER_SITE_ANALYTICS_NS}/${SET_ANALYTICS}`, layout.analytics, {
      root: true
    })
  },
  async loadSiteMeta({ commit }, serverSideContext?: Context) {
    const meta = await this.$dep(DealerSiteService).getWebsiteMetaFromDomain(
      this.$router.currentRoute,
      serverSideContext
    )
    commit(SET_WEBSITE_META, meta)
  },
  async loadCustomPage(
    { commit, state: { pageSnippets, websiteId } },
    id: string
  ) {
    const pageSnippet: PageSnippet | undefined = pageSnippets.get(id)

    if (!pageSnippet) {
      this.$logger.captureError(new Error(`Id "${id}" doesn't match any page`))
      return
    }
    if (pageSnippet.type !== 'custom_page') {
      this.$logger.captureError(
        new Error(
          `Page "${id}" isn't a custom page: ${pageSnippet.type} ≠ ` +
            `${'custom_page' as PageType}`
        )
      )
      return
    }

    commit(SET_LOADING_STATE, true)
    try {
      commit(ADD_CUSTOM_PAGE_BODY, {
        pageId: id,
        body: (await this.$dep(DealerSiteService).getPage(id, websiteId)).body
      })
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },

  async loadOutlets(
    { commit, state: { websiteId } },
    { mutateLoadingState = true }: { mutateLoadingState: boolean }
  ) {
    mutateLoadingState && commit(SET_LOADING_STATE, true)
    try {
      const outlets = await this.$dep(DealerSiteService).getOutlets(websiteId)
      commit(SET_OUTLETS, outlets)
      commit(SET_ACTIVE_OUTLET_ID, [...outlets.keys()][0])
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      mutateLoadingState && commit(SET_LOADING_STATE, false)
    }
  },

  async navigateToPage(
    { state: { pageSnippets, navigatedToOtherPage }, commit },
    id: string
  ) {
    const ps = pageSnippets.get(id)
    if (!ps) {
      const { $logger } = this
      $logger.captureError(new Error(`no page found with id: ${id}`))
      return
    }
    if (!navigatedToOtherPage) {
      commit(REGISTER_NAVIGATION_TO_OTHER_PAGE)
    }

    await this.$router.push(
      // @ts-ignore TODO: Remove ts-ignore comment once type mismatch between RawLocation and Route of vue-router is resolved.
      this.$dep(DealerSiteService).resolvePageRoute(ps).route
    )
  },
  navigateToPageFromOffCanvasNav(
    { getters: { pageIsActive }, dispatch },
    { event, id }: { event: Event; id: string }
  ) {
    event.preventDefault()

    if (pageIsActive(id)) {
      return
    }

    dispatch('navigateToPage', id)
    dispatch('layout/closeNav')
  },
  async loadPageByPath(
    { getters: { getPageSnippetByPath } },
    { to, from, redirect }: { to: Route; from?: Route; redirect?: Function }
  ) {
    if (from && to.name !== from.name) {
      return
    }

    const { slugOrId, pagePath } = to.params
    let ps: PageSnippet | undefined
    if (
      classifiedDealerSiteRouteNames.some(
        classifiedRouteName => classifiedRouteName === to.name
      ) &&
      slugOrId
    ) {
      ps = classifiedPageSnippet(slugOrId)
    } else if (to.name === getDealerSiteRouteName('page')) {
      const pageSnippetByPath: NonClassifiedPageSnippet = getPageSnippetByPath(
        pagePath
      )
      if (pageSnippetByPath) {
        ps = pageSnippet(pageSnippetByPath)
      }
    }

    const errorStore = getNamespacedStore(this, DEALER_SITE_ERROR_NS)
    if (!ps) {
      errorStore.commit(SET_ERROR, { type: '404' })
      if (process.server) {
        const res = this.$dep<ServerResponse>(responseToken)
        res.statusCode = 404
      }
      return
    } else if (errorStore.getters('errorExists')('404')) {
      errorStore.commit(CLEAR_ERROR)
    }

    const dsiteUrl = `/dsite/${this.$router.currentRoute.params.domain}`
    const pathIsDsiteIndex =
      this.$router.currentRoute.path === '/' ||
      this.$router.currentRoute.path === dsiteUrl ||
      this.$router.currentRoute.path === dsiteUrl + '/'

    if (redirect && process.server && pathIsDsiteIndex && ps.index) {
      let routeToGo = this.$dep(DealerSiteService).resolvePageRoute(ps)
      if (this.$router.currentRoute.query) {
        routeToGo = this.$router.resolve({
          path: routeToGo.route.path,
          query: this.$router.currentRoute.query
        })
      }
      return redirect(routeToGo.href)
    } else {
      await ps.loadPage!(this)
    }
  },
  async loadSearch({ getters: { activePage }, commit }) {
    const [logger, searchService, router] = this.$deps(
      LoggerService,
      SearchService,
      VueRouter
    )
    if (!activePage) {
      logger.captureError(
        new Error('Search component should be loaded with an active page')
      )
      return
    }

    commit(SET_LOADING_STATE, true)
    // TODO: Update vue router types to match implementation.
    const currentRoute: Route = { ...router.currentRoute }

    if (process.server && currentRoute.query) {
      delete currentRoute.query.lang
    }

    await searchService.createSearch({
      route: currentRoute,
      mapSearch: false
    })
    commit(SET_LOADING_STATE, false)
  },
  getRootBreadcrumbOfClassifiedPage({
    state: { pageSnippets },
    getters: { activePage },
    rootState: {
      page: { breadcrumbs: pageBreadcrumbs }
    }
  }): Breadcrumb | undefined {
    if (!activePage || activePage.type !== 'classified' || !pageBreadcrumbs) {
      return undefined
    }

    let root: Breadcrumb | undefined
    const pages = [...pageSnippets.values()]
    for (const breadcrumb of pageBreadcrumbs) {
      const match: PageSnippet | undefined = pages.find(
        p =>
          trimEnd(breadcrumb.text, '/') === trimEnd(p.pagePath, '/') ||
          breadcrumb.text === p.name
      )
      if (match) {
        root = {
          ...breadcrumb,
          url: this.$dep(DealerSiteService).resolvePageRoute(match).href
        }
      }
    }
    return root
  },
  async setClassifiedPageBreadcrumbs({
    dispatch,
    commit,
    getters: { activePage },
    rootState: {
      classifieds: {
        view: { classified }
      }
    }
  }) {
    if (activePage.type !== 'classified') {
      this.$logger.captureError(
        new Error(
          `Can't set breadcrumbs for non-classified page. (id: ${activePage.id}, type: ${activePage.type})`
        )
      )
      return
    }

    const breadcrumbs: Breadcrumb[] = []
    const rootBreadcrumb = await dispatch('getRootBreadcrumbOfClassifiedPage')
    rootBreadcrumb && breadcrumbs.push(rootBreadcrumb)
    classified && breadcrumbs.push({ text: classified.title })
    commit(`${PAGE_NS}/${SET_BREADCRUMBS}`, breadcrumbs, { root: true })
  },
  async loadClassified({ dispatch }, route: Route) {
    const { params } = route
    await dispatch(
      `${CLASSIFIED_VIEW_NS}/createClassifiedView`,
      {
        slugOrId: params.slugOrId,
        variant: ClassifiedViewVariant.DEALER_SITE
      },
      { root: true }
    )
    await dispatch('setClassifiedPageBreadcrumbs')
  },
  createTemplateConfSettingFunction({ commit }) {
    if (process.server) {
      this.$logger.captureError(
        new Error('Window is not available on the server-side.')
      )
      return
    }

    if (window.setDealerSiteTemplateConf) {
      this.$logger.captureError(
        new Error(
          'Identifier already assigned: window.setDealerSiteTemplateConf'
        )
      )
      return
    }

    window.setDealerSiteTemplateConf = (conf: Conf) => {
      commit(SET_TEMPLATE_CONF, conf)
    }
  }
} as ActionTreeWithRootState<DealerSiteState>
