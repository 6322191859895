import { DependencyContainer } from 'tsyringe'
import InjectionToken from 'tsyringe/dist/typings/providers/injection-token'
import { constructor } from 'tsyringe/dist/typings/types'
import { MultiArgDependencyResolverFunction } from '~/models/dependency-container/resolver'

const resolvedDepMapSymbol = Symbol('resolvedDepMap')

interface DepMapperContext {
  $requestContainer?: DependencyContainer
  [resolvedDepMapSymbol]: Map<InjectionToken, any>
}

type GetGetterOfInstanceOrAny<V> = V extends constructor<infer C>
  ? () => C
  : () => any

export const mapDeps = <
  TK extends Record<string | symbol, InjectionToken> = Record<
    string | symbol,
    InjectionToken
  >
>(
  tokens: TK
): {
  [P in keyof TK]: GetGetterOfInstanceOrAny<TK[P]>
} => {
  // @ts-ignore This gets eventually populated with token entries.
  const mapped: {
    [P in keyof TK]: GetGetterOfInstanceOrAny<TK[P]>
  } = {}

  Object.entries(tokens).map(([k, token]) => {
    // @ts-ignore TODO: Type key as key of T. Key type of Object.entries is always string.
    mapped[k] = function(this: DepMapperContext) {
      const resolvedDeps = ((): Map<InjectionToken, any> => {
        if (!this[resolvedDepMapSymbol]) {
          this[resolvedDepMapSymbol] = new Map<InjectionToken, any>()
        }

        return this[resolvedDepMapSymbol]
      })()

      if (!this.$requestContainer) {
        throw new TypeError('Undefined request-container instance')
      }

      if (resolvedDeps.has(token)) {
        return resolvedDeps.get(token)
      }

      const dep = this.$requestContainer.resolve(token)
      resolvedDeps.set(token, dep)

      return dep
    }
  })
  return mapped
}

/**
 * Single Dependency Resolver Function
 * @param c
 */
export const createDepGetter = (c: DependencyContainer) => <T>(
  token: InjectionToken<T>
): T => c.resolve<T>(token)

/**
 * Arbitrary Argument Dependency Resolver Function
 * @param c
 */
export const createArgDepGetter = (
  c: DependencyContainer
  // @ts-ignore
): MultiArgDependencyResolverFunction => (...tokens: InjectionToken[]) => {
  const deps = []
  for (const t of tokens) {
    deps.push(c.resolve(t))
  }
  return deps
}
