import { SchemaField } from '~/models/shared/types'
import {
  AdCampaignErrors,
  AdCampaignForm,
  AdForm,
  AdFormErrors,
  CampaignAd
} from '~/models/advertising/types'

export const ADVERTISE_FORM_NS = 'advertise/form'

export interface AdvertiseFormState {
  loading: boolean
  campaignValues: AdCampaignForm
  campaignErrors: AdCampaignErrors
  campaignSchema: SchemaField[]
  campaignAds: CampaignAd[]
  newAdValues: AdForm
  newAdErrors: AdFormErrors
  newAdSchema: SchemaField[]
  name: string
}
function getDefaultState(): AdvertiseFormState {
  return {
    loading: false,
    campaignValues: {},
    campaignErrors: {},
    campaignSchema: [],
    campaignAds: [],
    newAdValues: {},
    newAdErrors: {},
    newAdSchema: [],
    name: ''
  }
}

export default getDefaultState
