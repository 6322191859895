import {
  format as dFormat,
  formatDistance,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceStrict,
  subDays,
  isSameYear,
  sub,
  Duration,
  parse,
  getUnixTime,
  fromUnixTime,
  isSameDay,
  addYears as dAddYears,
  addDays as dAddDays,
  isAfter as dIsAfter,
  isBefore as dIsBefore,
  setHours as dSetHours,
  setMinutes as dSetMinutes,
  parseISO,
  isValid,
  differenceInMonths,
  differenceInYears,
  Locale
} from 'date-fns'
import { el, enUS, de, bg } from 'date-fns/locale'
import { I18nLocale } from '~/models/shared/types'
import { LocaleCode } from '~/models/locale/types'
import { SIMPLE_DATETIME_FORMAT } from '~/constants/date'

type TimeUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'

const locales: Record<LocaleCode, any> = { el, en: enUS, de, bg }

const lessThanPrefixes: Record<LocaleCode, string> = {
  el: 'λιγότερο από ',
  en: 'less than ',
  de: 'weniger als ',
  bg: 'по-малко от '
}

const moreThanSuffixes: Record<LocaleCode, string> = {
  el: 'πάνω από ',
  en: 'more than ',
  de: 'mehr als ',
  bg: 'повече от '
}

const aroundPrefixes: Record<LocaleCode, string> = {
  el: 'περίπου ',
  en: 'about ',
  de: 'ungefähr ',
  bg: 'около '
}

export function timeDistanceFrom(
  dateFrom: string,
  dateTo: string | Date,
  config: {
    locale: LocaleCode
    addSuffix: boolean
    strict?: boolean
    dateRelative?: boolean
  } = {
    locale: 'el',
    addSuffix: true,
    strict: undefined,
    dateRelative: undefined
  }
) {
  const dateF = toDate(dateFrom)
  const dateT = toDate(dateTo)
  try {
    let unit
    if (config.dateRelative) {
      unit = getRelativeDateUnit(dateF, dateT)
    }
    const options = {
      locale: locales[config.locale],
      addSuffix:
        typeof config.addSuffix === 'undefined' ? true : config.addSuffix,
      unit
    }

    if (config.strict) {
      return formatDistanceStrict(dateF, dateT, options)
    }
    return formatDistance(dateF, dateT, options)
  } catch (error) {
    return dateFrom
  }
}

export function diffInYears(dateFrom: any, dateTo: any) {
  return differenceInYears(toDate(dateFrom), toDate(dateTo))
}

export function diffInMonths(dateFrom: any, dateTo: any) {
  return differenceInMonths(toDate(dateFrom), toDate(dateTo))
}

export function diffInDays(dateFrom: any, dateTo: any) {
  return differenceInDays(toDate(dateFrom), toDate(dateTo))
}

export function diffInHours(dateFrom: any, dateTo: any) {
  return differenceInHours(toDate(dateFrom), toDate(dateTo))
}

export function diffInMinutes(dateFrom: any, dateTo: any) {
  return differenceInMinutes(toDate(dateFrom), toDate(dateTo))
}

export function diffInSeconds(dateFrom: any, dateTo: any) {
  return differenceInSeconds(toDate(dateFrom), toDate(dateTo))
}

export function datesInSameYear(dateFrom: any, dateTo: any) {
  return isSameYear(toDate(dateFrom), toDate(dateTo))
}

export function datesInSameDay(dateFrom: any, dateTo: any) {
  return isSameDay(toDate(dateFrom), toDate(dateTo))
}

export function getRelativeDateUnit(dateFrom: string, dateTo: string | Date) {
  let unit: TimeUnit

  if (Math.abs(diffInYears(dateFrom, dateTo)) >= 1) {
    unit = 'year'
  } else if (Math.abs(diffInMonths(dateFrom, dateTo)) >= 1) {
    unit = 'month'
  } else if (Math.abs(diffInDays(dateFrom, dateTo)) >= 1) {
    unit = 'day'
  } else if (Math.abs(diffInHours(dateFrom, dateTo)) >= 1) {
    unit = 'hour'
  } else if (Math.abs(diffInMinutes(dateFrom, dateTo)) >= 1) {
    unit = 'minute'
  } else unit = 'second'
  return unit
}

export function getRelativeDate(date: string, locale: LocaleCode = 'el') {
  if (datesInSameDay(date, Date.now())) {
    return format(date, 'HH:mm')
  } else if (datesInSameYear(date, Date.now())) {
    return format(date, 'd LLL', { locale })
  } else {
    return format(date, 'dd/MM/yy')
  }
}

export function timeDistanceFromNow(
  dateFrom: any,
  {
    locale = 'el',
    addSuffix = true,
    addPrefix = true,
    strict = false,
    unit = 'day',
    includeSeconds = false
  }: {
    locale?: LocaleCode
    unit?: TimeUnit
    addSuffix?: boolean
    addPrefix?: boolean
    strict?: boolean
    includeSeconds?: boolean
  } = {
    locale: 'el',
    addSuffix: true,
    addPrefix: true,
    unit: 'day',
    strict: false,
    includeSeconds: false
  }
) {
  const date = toDate(dateFrom)
  try {
    let formattedDistance
    if (strict) {
      formattedDistance = formatDistanceStrict(date, Date.now(), {
        locale: locales[locale],
        addSuffix,
        unit: unit || 'day'
      })
    } else {
      formattedDistance = formatDistance(date, Date.now(), {
        locale: locales[locale],
        addSuffix,
        includeSeconds
      })
    }

    if (!addPrefix) {
      formattedDistance = removePrefix(formattedDistance, { locale })
    } else if (
      locale === I18nLocale.EL &&
      formattedDistance.startsWith('σε περίπου') &&
      formattedDistance.endsWith('μήνας')
    ) {
      formattedDistance = formattedDistance.slice(
        0,
        formattedDistance.length - 1
      )
    }

    return formattedDistance
  } catch (error) {
    return dateFrom
  }
}

export function cappedTimeDistanceFromNow(
  dateFrom: any,
  capInDays = 60,
  {
    locale = 'el',
    addSuffix = true,
    includeSeconds = true,
    addPrefix = true
  }: {
    locale: LocaleCode
    addSuffix: boolean
    includeSeconds: boolean
    addPrefix: boolean
  } = {
    locale: 'el',
    addSuffix: true,
    addPrefix: true,
    includeSeconds: false
  }
) {
  let dateF = toDate(dateFrom)
  let suffix = ''
  if (Math.abs(differenceInDays(dateF, Date.now())) > capInDays) {
    dateF = subDays(Date.now(), capInDays)
    suffix = moreThanSuffixes[locale]
  }
  return `${suffix}${timeDistanceFromNow(dateF, {
    locale,
    addSuffix,
    addPrefix,
    includeSeconds
  })}`
}

export function format(
  dateTime: any,
  format = SIMPLE_DATETIME_FORMAT,
  config: { locale: LocaleCode } = { locale: 'el' }
) {
  const date = toDate(dateTime)
  try {
    if (config.locale) {
      config.locale = locales[config.locale]
    }
    // @ts-ignore
    return dFormat(date, format, config)
  } catch (error) {
    return dateTime
  }
}

export function setHours(date: Date, hours: number): Date {
  return dSetHours(date, hours)
}

export function setMinutes(date: Date, minutes: number): Date {
  return dSetMinutes(date, minutes)
}

export function toDate(dateTime: any) {
  if (typeof dateTime === 'string') {
    let date = new Date(dateTime)
    if (isValidDate(date)) {
      return date
    }
    date = new Date(slashToDash(dateTime))
    if (isValidDate(date)) {
      return date
    }
    date = new Date(dashToSlash(dateTime))
    if (isValidDate(date)) {
      return date
    }
    date = new Date(dashToSlashNoMiliseconds(dateTime))
    if (isValidDate(date)) {
      return date
    }
    return dateTime
  } else if (typeof dateTime === 'number') {
    // Temporary Milliseconds Handling
    return dateTime > 999999999999 ? new Date(dateTime) : fromUnixTime(dateTime)
  } else {
    return dateTime
  }
}

export function adminTimeDistanceFromNow(date: any) {
  let unit: TimeUnit
  if (Math.abs(diffInDays(date, Date.now())) > 1) {
    unit = 'day'
  } else if (differenceInMinutes(Date.now(), new Date(date)) < 60) {
    unit = 'minute'
  } else unit = 'hour'
  return timeDistanceFromNow(date, {
    strict: true,
    unit
  })
}

/**
 *
 * @deprecated - Prefer using dateIsValid instead
 */
export function isValidDate(date: any) {
  // @ts-ignore
  return date instanceof Date && !isNaN(date)
}

function dashToSlash(dateTime: any) {
  return dateTime.replace(/-/g, '/')
}

function dashToSlashNoMiliseconds(dateTime: any) {
  return dashToSlash(dateTime).split('.')[0]
}

function slashToDash(dateTime: any) {
  return dateTime.replace(/\//g, '-')
}

function removePrefix(
  dateStr: any,
  { locale = 'el' }: { locale: LocaleCode } = { locale: 'el' }
) {
  const prefixes = []
  prefixes.push(aroundPrefixes[locale])
  prefixes.push(lessThanPrefixes[locale])
  prefixes.push(moreThanSuffixes[locale])
  prefixes.forEach(prefix => {
    dateStr = dateStr.replace(prefix, '')
  })
  return dateStr
}

export function subtractDate(date: string | Date, duration: Duration) {
  return sub(new Date(date), duration)
}

export function parseDate(date: string, pattern: string, locale?: Locale) {
  if (locale) {
    parse(date, pattern, new Date(), { locale })
  }
  return parse(date, pattern, new Date())
}

export function parseISODate(date: string, options?: any) {
  return parseISO(date, options)
}

export function getTimestamp(date: Date): number {
  return getUnixTime(date)
}

export function fromTimestamp(timestamp: string | number): Date {
  return fromUnixTime(Number(timestamp))
}

export function addYears(date: Date | number, amount: number) {
  return dAddYears(date, amount)
}

export function addDays(date: Date | number, amount: number) {
  return dAddDays(date, amount)
}

export function isAfter(date: Date, dateToCompareTo: Date) {
  return dIsAfter(date, dateToCompareTo)
}

export function isBefore(date: Date, dateToCompareTo: Date) {
  return dIsBefore(date, dateToCompareTo)
}

export function dateIsValid(date: Date): boolean {
  return isValid(date)
}

export function getDateLocale(locale: LocaleCode) {
  return locales[locale]
}

export const testables = {
  dashToSlash,
  slashToDash,
  removePrefix
}

export function covertToGrDate(d: Date): Date {
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000

  const offset = 2 // GMT +2 = greece
  const nd = new Date(utc + 3600000 * offset)

  return nd
}

//  @TODO write tests
// date -> string | Date
export function adjustForTimezone(date: any) {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const timeOffsetInMS = date.getTimezoneOffset() * 60000
  date.setTime(date.getTime() - timeOffsetInMS)
  return date
}
