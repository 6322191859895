import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import Vue from 'vue'
import { Store } from 'vuex'
import { httpToken } from '~/constants/dependency-injection/tokens'

import { containerScoped } from '~/decorators/dependency-container'
import { SchemaResult } from '~/models/report/message/schema'
import { ActionResult } from '~/models/shared/result'
import { formatSchemaField } from '~/services/formatters'
import { Pagination } from '~/models/search/types'
import { RootState } from '~/store/types'
import {
  ClassifiedList,
  ClassifiedListComment,
  Reactions,
  ReactionsObject
} from '~/models/classified-list/types'
import { Classified } from '~/models/classified/types'
import { User } from '~/models/user/types'
import { createArrayPairsFromObject } from '~/utils/array'
import { invalidBodyError } from './errors'
import { toCamelCase } from '~/utils/object'
import SearchFormatter from '~/services/search/SearchFormatter'

@containerScoped()
export default class ClassifiedListsService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(Store) private store: Store<RootState>,
    @inject(SearchFormatter) private searchFormatter: SearchFormatter
  ) {}

  async addClassifiedToList(
    listId: number | string,
    classifiedId: string
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.put(
      `/api/classifieds/lists/${listId}/add/${classifiedId}/`
    )
    const body = response.data
    if (!body || !body.status || !body.message) {
      throw invalidBodyError(body)
    }
    return body
  }

  async fetchListClassifiedsById(
    listId: string | number,
    page?: number | string
  ): Promise<{
    classifieds: { pagination: Pagination; rows: Classified[] }
    list: ClassifiedList
  }> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/${listId}/classifieds/`,
      { params: { pg: page } }
    )
    const body = response.data
    if (!body?.data?.classifieds || !body?.data?.list) {
      throw invalidBodyError(body)
    }
    body.data.list = toCamelCase(body.data.list)
    body.data.classifieds.pagination = toCamelCase(
      body.data.classifieds.pagination
    )
    let rows = body.data.classifieds.rows
    const { id } = this.store.state.user

    rows = body.data.classifieds.rows.map((r: Classified) => {
      if (
        (r.states.is_hidden || r.states.is_non_public || r.states.is_removed) &&
        r.user_id !== id
      ) {
        return {
          ...r,
          states: {
            is_non_public: false,
            is_hidden: false,
            is_removed: false,
            is_deleted: true
          }
        }
      }
      return r
    })
    body.data.classifieds.rows = this.searchFormatter.formatRows(rows)
    return body.data
  }

  async createList(
    listName: string,
    classifiedIds: Array<number> = []
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/lists/',
      {
        name: listName,
        classifieds: classifiedIds
      }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }
    return body.data.values
  }

  async fetchAllLists(
    classifiedId: number | string = ''
  ): Promise<ClassifiedList[]> {
    const params = classifiedId ? { classified: classifiedId } : {}

    const response: AxiosResponse = await this.http.get(
      '/api/classifieds/lists/',
      { params }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.lists) {
      throw invalidBodyError(body)
    }
    return body.data.lists as ClassifiedList[]
  }

  async removeClassifiedFromList(
    listId: number | string,
    classifiedId: string
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/${listId}/remove/${classifiedId}/`
    )
    const body = toCamelCase(response.data)
    if (!body?.message || !body?.status) {
      throw invalidBodyError(body)
    }
    return body
  }

  async editList(
    listId: number | string,
    name: string
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.put(
      `/api/classifieds/lists/${listId}/`,
      { name }
    )
    const body = toCamelCase(response.data)

    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }
    return body.data.values
  }

  async deleteList(
    listId: number | string
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/${listId}/`
    )
    const body = toCamelCase(response.data)
    if (!body?.status) {
      throw invalidBodyError(body)
    }
    return body
  }

  async copyList(
    listId: number | string,
    listName: string
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/${listId}/copy/`,
      {
        name: listName
      }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }

    return body.data.values
  }

  async addToLists(
    listId: string | number
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/favorites/${listId}/`
    )
    const data = toCamelCase(response.data)
    if (!data || !data.message || !data.status) {
      throw invalidBodyError(data)
    }

    return data
  }

  async removeListFromFavorites(
    listId: string | number
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/favorites/${listId}/`
    )
    const data = toCamelCase(response.data)
    if (!data || !data.message || !data?.status) {
      throw invalidBodyError(data)
    }

    return data
  }

  async fetchReactions(
    listId: number | string,
    classifiedId: string
  ): Promise<Reactions> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/${listId}/reactions/${classifiedId}/`
    )
    let { data } = toCamelCase(response.data)
    if (!data || !data.myReactions || !data?.recentReactions) {
      throw invalidBodyError(data)
    }
    const reactionsCounts = Object.values(data.reactionsCounts)
    if (reactionsCounts.length) {
      data = this.normalizeReactionData({
        ...data,
        reactions: data.reactionsCounts
      })
    } else {
      data.totalReactionsCount = 0
      data.reactionsPairs = []
    }
    return data
  }

  async createReaction(
    listId: number | string,
    classifiedId: string,
    reaction: string
  ): Promise<
    { created: string; reaction: string; user: User } | { message: string }
  > {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/${listId}/reactions/${classifiedId}/`,
      { reaction }
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.values && !data?.message) {
      throw invalidBodyError(data)
    }

    return data
  }

  async fetchClassifiedListComments(
    listId: number | string,
    classifiedId: string
  ): Promise<Array<ClassifiedListComment>> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/${listId}/comments/${classifiedId}/`
    )
    const data = toCamelCase(response.data)

    if (!data?.data?.comments) {
      throw invalidBodyError(data)
    }
    const { comments } = data.data
    this.setReactionsInComments(comments)

    return comments
  }

  setReactionsInComments(comments: ClassifiedListComment[]) {
    let i = comments.length
    while (i--) {
      const comment = comments[i]
      comments[i] = this.normalizeReactionData(
        comment as ReactionsObject
      ) as ClassifiedListComment

      if (comment.replies && comment.replies.length) {
        this.setReactionsInComments(comment.replies)
      }
    }
  }

  async createClassifiedListComments(
    listId: number | string,
    classifiedId: string,
    comment: string
  ): Promise<ClassifiedListComment> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/${listId}/comments/${classifiedId}/`,
      { comment }
    )
    const data = toCamelCase(response.data)

    if (!data?.data?.values) {
      throw invalidBodyError(data)
    }

    return data.data.values
  }

  async createClassifiedListCommentsReply(
    commentId: number,
    comment: string
  ): Promise<ClassifiedListComment> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/comments/${commentId}/reply/`,
      {
        comment
      }
    )
    const data = toCamelCase(response.data)

    if (!data?.data?.values) {
      throw invalidBodyError(data)
    }

    return data.data.values
  }

  async deleteClassifiedListComments(commentId: number) {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/comments/${commentId}/`
    )

    const data = response.data
    if (!data?.message) {
      throw invalidBodyError(data)
    }
  }

  async fetchClassifiedListCommentsReplies(
    commentId: number
  ): Promise<ClassifiedListComment> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/comments/${commentId}/`
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.comment) {
      throw invalidBodyError(data)
    }
    return data.data.comment
  }

  async hideClassifiedListComment(
    commentId: number
  ): Promise<ClassifiedListComment> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/comments/${commentId}/visibility/`
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.comment) {
      throw invalidBodyError(data)
    }
    return data.data.comment
  }

  async showClassifiedListComment(
    commentId: number
  ): Promise<ClassifiedListComment> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/comments/${commentId}/visibility/`
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.comment) {
      throw invalidBodyError(data)
    }
    return data.data.comment
  }

  async fetchClassifiedListCommentReportData(
    commentId: number
  ): Promise<SchemaResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/comments/${commentId}/report/`
    )
    const { data: body } = response

    if (!body) {
      throw invalidBodyError(body)
    }
    return this.formatSchemaResponse(body.data)
  }

  async classifiedListCommentReport(
    commentId: number,
    { reason, details }: { reason: number | null; details: string | null } = {
      reason: null,
      details: null
    }
  ): Promise<ActionResult> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/comments/${commentId}/report/`,
      {
        reason,
        details: details || undefined
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body
  }

  async fetchReportedClassifiedListComment(commentId: number) {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/comments/${commentId}/report/`
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.comment) {
      throw invalidBodyError(data)
    }
    return data.data.comment
  }

  async createClassifiedListCommentReaction(
    commentId: number,
    reaction: string
  ): Promise<{
    commentId?: number
    reactionType?: string
    user?: User
    message?: string
  }> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/comments/${commentId}/react/`,
      {
        reaction
      }
    )
    const data = toCamelCase(response.data)
    if (!data?.data?.values && !data?.message) {
      throw invalidBodyError(data)
    }

    return data
  }

  addCommentInComments(
    comments: Array<ClassifiedListComment>,
    comment: ClassifiedListComment
  ): void {
    let i = comments.length
    while (i--) {
      if (comment.parentId === comments[i].id) {
        Vue.set(comments[i].replies, comments[i].replies.length, comment)
        break
      }
      comments[i].replies?.length &&
        this.addCommentInComments(comments[i].replies, comment)
    }
  }

  manageCommentReaction(
    comments: ClassifiedListComment[],
    commentId: number,
    reaction: string
  ) {
    let i = comments.length
    while (i--) {
      const comment = comments[i]
      if (commentId === comment.id) {
        if (comment.myReactions.includes(reaction)) {
          this.removeReaction(comment, reaction)
        } else if (
          !comment.myReactions.includes(reaction) &&
          comment.myReactions.length
        ) {
          this.replaceReaction(comment, reaction)
        } else {
          this.addReaction(comment, reaction)
        }

        break
      }
      comments[i].replies?.length &&
        this.manageCommentReaction(comments[i].replies, commentId, reaction)
    }
  }

  replaceComment(
    comments: Array<ClassifiedListComment>,
    comment: ClassifiedListComment
  ) {
    let i = comments.length
    while (i--) {
      if (comment.id === comments[i].id) {
        Vue.set(comments, i, comment)
        break
      }
      comments[i].replies?.length &&
        this.replaceComment(comments[i].replies, comment)
    }
  }

  removeComment(comments: Array<ClassifiedListComment>, commentId: number) {
    let i = comments.length
    while (i--) {
      if (commentId === comments[i].id) {
        comments.splice(i, 1)
        break
      }
      comments[i].replies?.length &&
        this.removeComment(comments[i].replies, commentId)
    }
  }

  normalizeReactionData(reactionObj: ReactionsObject) {
    let reactionsCounts: number[] = []

    if (reactionObj?.reactions) {
      reactionsCounts = Object.values(reactionObj.reactions)
    } else {
      reactionObj.totalReactionsCount = 0
      reactionObj.reactionsPairs = []
    }

    if (reactionsCounts.length) {
      reactionObj.totalReactionsCount = reactionsCounts.reduce(
        (a: any, c: any) => a + c
      )
      const tmpReactionsPairs = createArrayPairsFromObject(
        reactionObj.reactions
      )
      reactionObj.reactionsPairs = tmpReactionsPairs.sort(
        (a: any, b: any) => b[1] - a[1]
      )
    }

    return reactionObj
  }

  addReaction(comment: ClassifiedListComment, reaction: string) {
    const reactions = comment.reactions
    comment.myReactions = [reaction]
    if (!comment.totalReactionsCount && comment.totalReactionsCount !== 0) {
      comment.totalReactionsCount = 0
    }
    if (reactions[reaction] > 0) {
      reactions[reaction] += 1
    } else if (!reactions[reaction]) {
      reactions[reaction] = 1
    }
    comment.totalReactionsCount++
    comment.reactions = reactions

    comment.reactionsPairs = createArrayPairsFromObject(reactions)
  }

  replaceReaction(comment: ClassifiedListComment, reaction: string) {
    const myReaction = comment.myReactions[0]

    this.removeReaction(comment, myReaction)
    this.addReaction(comment, reaction)
  }

  removeReaction(comment: ClassifiedListComment, reaction: string) {
    const reactions = comment.reactions
    comment.myReactions = []
    if (reactions[reaction] > 1) {
      reactions[reaction]--
    } else if (reactions[reaction] === 1) {
      delete reactions[reaction]
    }
    comment.totalReactionsCount--
    comment.reactions = reactions

    comment.reactionsPairs = createArrayPairsFromObject(reactions)
  }

  formatTotalReactions(totalReactionsCount: number): string | number {
    // Subtract count because the three emoji are appeared
    if (totalReactionsCount > 999) {
      return `${(totalReactionsCount / 1000).toFixed(1)}k`
    } else if (totalReactionsCount) {
      return totalReactionsCount
    }

    return 0
  }

  private formatSchemaResponse(r: any): SchemaResult {
    const { details, reason } = r.schema

    return {
      schema: {
        details: details && formatSchemaField(details),
        reason: reason && formatSchemaField(reason)
      }
    }
  }
}
