export enum Position {
  AUTO = 'auto',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  LEFT = 'left',
  RIGHT = 'right',
  LEFT_START = 'left-start',
  RIGHT_START = 'right-start',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_END = 'bottom-end'
}
