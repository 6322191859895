import { AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'

import { containerScoped } from '~/decorators/dependency-container'
import {
  ErrorResult,
  SubmitResult,
  FieldValuesResult,
  SchemaResult,
  ChangeCategoryResult
} from '~/models/form/types'
import { CompactResult, Result } from '~/models/shared/result'
import { formatCompactPage } from '../formatters'
import {
  formatFieldset,
  formatFormCategory,
  formatFormClassified
} from './formatters'
import ScrollService from '~/services/scroll/ScrollService'
import { toCamelCase } from '~/utils/object'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export default class FormService {
  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(ScrollService) private scrollService: ScrollService,
    @inject(VueI18n) private i18n: VueI18n
  ) {}

  async getSchema(
    categoryId: number,
    classifiedId: number,
    form: object
  ): Promise<Result<SchemaResult>> {
    const url = classifiedId
      ? `/api/classifieds/schema/${categoryId}/${classifiedId}/`
      : `/api/classifieds/schema/${categoryId}/`

    return await this.requestBuilder
      .request('post', url)
      .data({
        form
      })
      .validate(body => body?.data?.schema)
      .map(body => {
        return { data: this.formatSchemaResponse(body.data) }
      })
      .send()
  }

  async getFieldValues(
    categoryId: number
  ): Promise<CompactResult<FieldValuesResult>> {
    return await this.requestBuilder
      .request('get', `/api/classifieds/new/${categoryId}/`)
      .validate(body => body?.data?.classified?.field_values)
      .map(body => {
        return {
          page: formatCompactPage(body._page, body?.data?.change_search_url),
          data: this.formatFieldValuesResponse(body.data)
        }
      })
      .send()
  }

  async createNewClassified(
    categoryId: number,
    form: object
  ): Promise<SubmitResult> {
    return await this.requestBuilder
      .request('post', `/api/classifieds/new/${categoryId}/`)
      .data({
        ...form
      })
      .validate(body => body?.data)
      .map(body => this.formatSubmitResponse(body))
      .send()
  }

  async editClassified(
    classifiedId: number,
    form: object
  ): Promise<SubmitResult> {
    return await this.requestBuilder
      .request('put', `/api/classifieds/${classifiedId}/edit/`)
      .data({
        ...form
      })
      .validate(body => body?.data)
      .map(body => this.formatSubmitResponse(body))
      .send()
  }

  async submitCategoryChange(
    classifiedId: number,
    categories: number | number[]
  ): Promise<ChangeCategoryResult> {
    return await this.requestBuilder
      .request('put', `/api/classifieds/${classifiedId}/categories/`)
      .data({
        categories
      })
      .validate(body => body)
      .map(body => this.formatChangeCategory(body))
      .send()
  }

  makeCategoryIdPrimary(
    classifiedId: number,
    category: number
  ): Promise<ChangeCategoryResult> {
    return this.requestBuilder
      .request(
        'post',
        `/api/classifieds/${classifiedId}/make-base/${category}/`
      )
      .validate(body => body)
      .action()
      .send()
  }

  public getSuccessfulEditMessage(): string {
    return this.i18n.t('your changes were saved').toString()
  }

  public onErrorScroll({
    errorClass = '.c-form-error',
    container = null
  }: {
    errorClass?: string
    container?: any
  }): void {
    if (process.client) {
      setTimeout(() => {
        let firsterror = document.querySelector(errorClass) // fix that in the future
        // @ts-ignore
        if (firsterror?.length) {
          // @ts-ignore
          firsterror = firsterror[0]
        }

        const options: any = {
          easing: 'ease-in',
          offset: -200,
          duration: 500
        }

        if (container) {
          options.container = container
        }
        if (firsterror) {
          this.scrollService.scrollTo(firsterror, options)
        }
      }, 100)
    }
  }

  private formatFieldValuesResponse(r: any): FieldValuesResult {
    return {
      allCategories: toCamelCase(r.all_categories),
      classified: formatFormClassified(r.classified),
      registerReferenceCode: r.register_reference_code
    }
  }

  private formatSchemaResponse(r: any): SchemaResult {
    return {
      category: formatFormCategory(r.category),
      schema: {
        fieldsets: r.schema.fieldsets.map(formatFieldset),
        genericFormFields: r.schema.generic_form_fields
      },
      extras: r.extras ? toCamelCase(r.extras) : undefined
    }
  }

  private formatSubmitResponse(r: any): SubmitResult {
    const { classified, dispatcher } = r.data
    return {
      classified,
      dispatcher,
      message: r.message
    }
  }

  private formatChangeCategory(r: any): ChangeCategoryResult {
    const { categories } = r.data
    return {
      categories: toCamelCase(categories),
      message: r.message
    }
  }

  public formatFormError(r: AxiosResponse<any>): ErrorResult {
    const { data } = r
    return {
      fieldErrors: data?.data?.errors,
      message: data.error,
      status: data.status,
      reason: data?.data?.reason
    }
  }
}
