import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  AuditProviderNewShopResult,
  AuditProviderShopsResult,
  AuditShopResult
} from '~/models/audit/shop'
import { DynamicFormFieldValues } from '~/models/dynamic-form/schema'
import { ActionResult } from '~/models/shared/result'
import { DynamicFormResult } from '~/models/dynamic-form/types'
import { toCamelCase } from '~/utils/object'
import { CategoryId } from '~/models/category/types'

@containerScoped()
export default class AuditShopService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  deleteAuditShop(id: number): Promise<ActionResult> {
    return this.requestBuilder
      .request('delete', `/api/audits/audit-shop/${id}/`)
      .action()
      .send()
  }

  getAuditShop(
    id: number,
    category?: number | string
  ): Promise<AuditShopResult> {
    return this.requestBuilder
      .request('get', `/api/audits/audit-shop/${id}/`)
      .params({ category })
      .map(body => ({
        // Not camel-casing field values here to preserve schema mapping
        shopFieldValues: body.data.audit_shop,
        schema: toCamelCase(body.data.schema),
        auditShop: toCamelCase(body.data.audit_shop)
      }))
      .send()
  }

  updateShop(
    id: number,
    form: DynamicFormFieldValues,
    category: CategoryId | string = CategoryId.CARS
  ): Promise<DynamicFormResult> {
    return this.requestBuilder
      .request('put', `/api/audits/audit-shop/${id}/`)
      .params({ category })
      .data(form)
      .send()
  }

  createShop(
    form: DynamicFormFieldValues,
    category: CategoryId | string = CategoryId.CARS
  ): Promise<DynamicFormResult> {
    return this.requestBuilder
      .request('post', '/api/audits/audit-provider/shop/')
      .params({ category })
      .data(form)
      .send()
  }

  getNewShopSchema(
    category: CategoryId | string = CategoryId.CARS
  ): Promise<AuditProviderNewShopResult> {
    return this.requestBuilder
      .request('get', '/api/audits/audit-provider/shop/')
      .params({ category })
      .send()
  }

  getShops(): Promise<AuditProviderShopsResult> {
    return this.requestBuilder
      .request('get', '/api/audits/audit-provider/shops/')
      .send()
  }
}
