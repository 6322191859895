var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative focus-within:tw-z-10"},[(!_vm.floatingLabel && (_vm.label || _vm.$slots.label))?_c('label',{staticClass:"tw-z-10",class:_vm.labelClasses,attrs:{"for":_vm.internalId}},[(_vm.$slots.label)?_vm._t("label"):[_vm._v(" "+_vm._s(_vm.label))]],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-relative"},[(_vm.$slots.before)?_c('div',{staticClass:"tw-left-0 tw-pl-4",class:_vm.beforeClasses},[_vm._t("before")],2):_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({ref:"inputTemplateRef",staticClass:"c-input tw-form-input tw-block tw-w-full tw-transition-colors disabled:tw-bg-grey-100 disabled:tw-text-grey-500 disabled:tw-border-grey-300 disabled:tw-cursor-not-allowed read-only:tw-bg-grey-100 read-only:tw-text-grey-500 read-only:tw-border-grey-300 read-only:tw-cursor-not-allowed tw-border-solid",class:[
        _vm.controlClasses,
        _vm.inputFloatingLabelClasses,
        _vm.inputSizeClasses,
        _vm.roundnessClass,
        _vm.inputBeforeAndAfterClasses,
        _vm.inputClass,
        { 'no-arrows': _vm.noArrows }
      ],attrs:{"id":_vm.internalId,"placeholder":_vm.floatingLabel && !_vm.placeholder && _vm.label ? _vm.label : _vm.placeholder,"disabled":_vm.disabled,"type":_vm.type,"step":_vm.type === 'number' ? _vm.step : undefined,"required":_vm.required,"name":_vm.name},domProps:{"value":_vm.internalValue},on:{"wheel":_vm.onWheel}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
        {input: _vm.handleInput,
        keydown: _vm.handleKeydown,
        change: _vm.handleChange}))),_vm._v(" "),(_vm.floatingLabel && (_vm.label || _vm.placeholder || _vm.$slots.label))?_c('label',{staticClass:"tw-z-10",class:_vm.labelClasses,attrs:{"for":_vm.internalId}},[(_vm.$slots.label)?_vm._t("label"):_c('span',{staticClass:"tw-overflow-hidden tw-text-ellipsis"},[_vm._v("\n        "+_vm._s(_vm.label || _vm.placeholder))])],2):_vm._e(),_vm._v(" "),(_vm.$slots.after)?_c('div',{staticClass:"tw-right-0 tw-pr-4",class:_vm.afterClasses},[_vm._t("after")],2):_vm._e()]),_vm._v(" "),(_vm.$slots.helperText || _vm.helperText)?_c('div',{staticClass:"tw-mt-2",class:_vm.helperTextClasses},[(_vm.$slots.helperText)?_vm._t("helperText"):[_vm._v("\n      "+_vm._s(_vm.helperText)+"\n    ")]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }