import { getSubDomainRouteName } from '~/utils/router'
import { interopDefault } from '~/router/utils'
import { getAuditsExternalCertificatesRoutes } from '~/router/routes/shared/account/audits/external-certificates'

const routeNamePrefix = 'subdomain_iframe'
const meta = { isIframe: true, isSubdomain: true, isCrossOrigin: true }
/**
 *
 * @param basePath
 */
export default [
  {
    name: getSubDomainRouteName('iframe'),
    path: '/iframe',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/iframes/audits-iframe.vue' /* webpackChunkName: "pages-car-iframes-audits-iframe" */
        )
      ),
    children: getAuditsExternalCertificatesRoutes(routeNamePrefix, meta),
    meta,
    redirect: {
      name: `__${routeNamePrefix}_account_audits_external_certificates`
    }
  }
]
