import { MutationTree } from 'vuex'
import { BackgroundJobsState } from './state'
import {
  BackgroundJob,
  BackgroundJobStatusTypes
} from '~/models/background-jobs/types'
import {
  ADD_BACKGROUND_JOB,
  REMOVE_JOB,
  SET_BACKGROUND_JOBS,
  TERMINATE_JOB,
  UPDATE_JOB
} from '~/store/modules/shared/backgroundJobs/mutation-types'
import Vue from 'vue'

// const { setter } = createMutationCreator<BackgroundJobsState>()

export default {
  [SET_BACKGROUND_JOBS](state, jobs: BackgroundJob[]) {
    state.jobs = jobs
  },
  [ADD_BACKGROUND_JOB](state, job: BackgroundJob) {
    if (!state.jobs.some(j => j.id === job.id)) {
      state.jobs.unshift(job)
    }
  },
  [UPDATE_JOB](
    state,
    { job, excludedKeys }: { job: BackgroundJob; excludedKeys?: string[] }
  ) {
    const oldJob = state.jobs.find(j => j.id === job.id)
    if (oldJob) {
      for (const key in job) {
        if (excludedKeys?.length && excludedKeys.includes(key)) {
          continue
        }
        // @ts-ignore
        Vue.set(oldJob, key, job[key])
      }
    }
  },
  [REMOVE_JOB](state, jobId: number) {
    const jobIndex = state.jobs.findIndex(j => j.id === jobId)
    state.jobs.splice(jobIndex, 1)
  },
  [TERMINATE_JOB](state, jobId: number) {
    const job = state.jobs.find(j => j.id === jobId)
    if (job) {
      Vue.set(job, 'status', BackgroundJobStatusTypes.TERMINATED)
      Vue.set(job, 'terminated', true)
    }
  }
} as MutationTree<BackgroundJobsState>
