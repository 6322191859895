import { Search } from '~/router/routes/car/classifieds/search/components'
export const partsRoutes = [
  {
    name: '__classifieds_search_parts',
    path: '/parts/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_parts_seo',
    path: '/parts/*.html',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
