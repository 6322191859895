import Vue from 'vue'
import { ActionResult } from '~/models/shared/result'
import { ActionTreeWithRootState } from '~/store/types'
import { SET_NO_CREDITS_FOR_CLASSIFIED, SET_PENDING } from './mutation-types'
import { SearchActionsState } from './state'
import { HttpStatus } from '~/constants/http'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

export default {
  async performAction(
    { commit, rootGetters },
    { action, classifiedId }: { action: Function; classifiedId?: number }
  ) {
    try {
      const data: ActionResult = await action()
      if (data && data.message) {
        const { message } = data
        this.$snackbar && this.$snackbar.success(message)
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
      if (errorMessage) {
        if (error.response?.status === HttpStatus.NO_CREDITS) {
          const isMobile = rootGetters[`${USER_AGENT_NS}/isMobile`]
          if (isMobile || !classifiedId) {
            // @ts-ignore
            const msg = this.app.i18n?.t('buy credits::promo') as string
            this.$snackbar &&
              this.$snackbar.error(errorMessage, {
                time: 5000,
                action: {
                  text: msg,
                  href: this.$dep(LegacyUrlService).getBuyCreditsUrl()
                }
              })
          } else {
            commit(SET_NO_CREDITS_FOR_CLASSIFIED, null)
            await Vue.nextTick()
            commit(SET_NO_CREDITS_FOR_CLASSIFIED, classifiedId)
          }
        } else {
          this.$snackbar && this.$snackbar.error(errorMessage)
          this.$logger.captureError(error)
          throw error
        }
      } else {
        this.$logger.captureError(error)
        throw error
      }
    } finally {
      commit(SET_PENDING, false)
    }
  }
} as ActionTreeWithRootState<SearchActionsState>
