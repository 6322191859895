import { ONE_YEAR } from '~/constants/duration'
import { containerScoped } from '~/decorators/dependency-container'
import { clientOnly, serverOnly } from '~/decorators'

import { extractCookie } from '~/utils/cookies'
import { inject } from 'tsyringe'
import {
  requestToken,
  responseToken
} from '~/constants/dependency-injection/tokens'
import { RequestOptions, ServerResponse } from 'http'
import { appendToResponseHeader } from '~/utils/http'

const domain = process.env.DOMAIN

interface CookieSetOptions {
  maxAge?: number
}
const cookieSetdefaultOptions = {
  maxAge: ONE_YEAR
}

@containerScoped()
export default class CookiesService {
  static deletionExpirationAttribute = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT'
  constructor(
    @inject(requestToken) private ssrRequest?: RequestOptions,
    @inject(responseToken) private ssrResponse?: ServerResponse
  ) {}

  public set(
    key: string,
    value: any,
    { maxAge = ONE_YEAR }: CookieSetOptions = cookieSetdefaultOptions,
    forceReplace: boolean = false
  ) {
    if (!key) {
      throw new Error('Attempting to set cookie without a key')
    }
    if (process.server) {
      return this.setCookieOnTheServer(key, value, maxAge, forceReplace)
    } else if (process.client) {
      return this.setCookieOnTheClient(key, value, maxAge)
    }
  }

  public get(key: string): string | null {
    if (!key) {
      throw new Error('Attempting to get cookie without a key')
    }
    if (process.server) {
      return this.getCookieOnTheServer(key)
    } else if (process.client) {
      return this.getCookieOnTheClient(key)
    }
    return null
  }

  public delete(key: string) {
    if (!key) {
      throw new Error('Attempting to delete cookie without a key')
    }
    if (process.server) {
      this.deleteCookieOnTheServer(key)
    } else if (process.client) {
      this.deleteCookieOnTheClient(key)
    }
  }

  @serverOnly
  private deleteCookieOnTheServer(key: string) {
    if (this.ssrResponse) {
      appendToResponseHeader(this.ssrResponse, 'set-cookie', [
        `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${domain}`
      ])
    }
  }

  @serverOnly
  private getCookieOnTheServer(key: string): string | null {
    return (
      (this.ssrRequest && extractCookie(key, this.ssrRequest.headers)) || null
    )
  }

  @serverOnly
  private setCookieOnTheServer(
    key: string,
    value: any,
    maxAge: number,
    forceReplace: boolean = false
  ) {
    if (!this.ssrRequest || !this.ssrResponse) {
      return
    }
    // TODO: maybe re-enable this later
    // this.modifyRequestCookieHeader(key, value)
    appendToResponseHeader(
      this.ssrResponse,
      'set-cookie',
      [
        `${key}=${encodeURIComponent(
          value
        )}; Path=/; Max-Age=${maxAge}; Domain=${domain}`
      ],
      forceReplace
    )
  }

  @serverOnly
  private modifyRequestCookieHeader(key: string, value: string): void {
    if (
      !this.ssrRequest?.headers?.cookie ||
      typeof this.ssrRequest.headers.cookie !== 'string'
    ) {
      return
    }
    // Split cookies using the default HttpIncomingMessage header cookie separator '; '
    const cookieHeaderParts = this.ssrRequest.headers.cookie.split('; ')
    for (let i = 0; i < cookieHeaderParts.length; i++) {
      const name = cookieHeaderParts[i].split('=')[0]
      if (name === key) {
        // if a cookie by that name already exists, replace its value, re-join and return
        cookieHeaderParts[i] = `${name}=${value}`
        this.ssrRequest.headers.cookie = cookieHeaderParts.join('; ')
        return
      }
    }
    // if a non-existent cookie is set, append it at the end
    this.ssrRequest.headers.cookie += `; ${key}=${value}`
  }

  @clientOnly
  private deleteCookieOnTheClient(key: string) {
    if (document) {
      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${domain}`
      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute};`
      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${window.location.hostname}`
    }
  }

  @clientOnly
  private getCookieOnTheClient(key: string): string | null {
    return document && extractCookie(key, document)
  }

  @clientOnly
  private setCookieOnTheClient(key: string, value: any, maxAge: number) {
    if (document) {
      document.cookie = `${key}=${value}; Path=/; Max-Age=${maxAge}; Domain=${domain}`
    }
  }

  safelyGetJsonParsedCookie(name: string, fallback = {}): null | any {
    const cookie = this.get(name)
    if (!cookie) {
      return null
    }
    try {
      return JSON.parse(cookie)
    } catch (error) {
      try {
        return JSON.parse(decodeURIComponent(cookie))
      } catch (error) {
        this.delete(name)
        return fallback
      }
    }
  }
}
