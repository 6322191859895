import { ClassifiedViewPage } from '~/router/routes/plot/classifieds/view'
import { interopDefault } from '~/router/utils'

export const MapSearch = () =>
  interopDefault(
    import(
      '~/pages/shared/classifieds/search/map-search.vue' /* webpackChunkName: "pages-classifieds-search-map-search" */
    )
  )

export const mapRoutes = [
  {
    name: '__classifieds_search_plot_map',
    path: '/search',
    component: MapSearch,
    meta: {
      noFooter: true,
      disableNotificationButton: true,
      isSearchRoute: true,
      wideContainer: true
    },
    children: [
      {
        name: '__classifieds_search_plot_map_classified',
        path: '/search/:slugOrId',
        component: ClassifiedViewPage,
        meta: {
          noFooter: true,
          noBreadcrumbs: true,
          disableNotificationButton: true,
          plotSearch: true
        }
      }
    ]
  }
]
