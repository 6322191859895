import Vue from 'vue'
import { UrlQueryParamSetter, QueryParamSetterOptions } from '~/models/url'
import { IUrlHistoryService } from '~/services/url/history/IUrlHistoryService'
import { noop } from '~/utils/function'

export class NoopUrlHistoryService implements IUrlHistoryService {
  onPopState(
    _componentInstance: Vue,
    _listener: (e: PopStateEvent) => void
  ): () => void {
    return noop
  }

  pushHash(_hash?: string): void {}

  queryParamSetter(_options: QueryParamSetterOptions): UrlQueryParamSetter {
    return noop
  }

  replaceQueryParams(
    _queryParams: Record<
      string,
      string | string[] | number[] | null | undefined
    >
  ): void {}
}
