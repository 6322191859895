import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  AdminStripeRequestResult,
  AfmInfoResult,
  AvailableCreditsResult,
  InvoicingInfoForm,
  InvoicingSchemaResult,
  OfficialRecordsResult,
  StripePaymentsArgs,
  StripePaymentsResult,
  StripePayoutResult,
  StripeSeller,
  StripeSessionInfoResult,
  StripeSessionProps,
  StripeSessionResult,
  StripeSubscriptionPlansResult
} from '~/models/payments/stripe'
import { toSnakeCase } from '~/utils/snake-case'
import { clientOnly } from '~/decorators'
import VueRouter from 'vue-router'
import CookiesService from '~/services/CookiesService'
import VueI18n from 'vue-i18n'
import { ONE_DAY } from '~/constants/duration'
import { HttpStatus } from '~/constants/http'
import { toCamelCase } from '~/utils/object'
import { setUrlParam } from '~/utils/http'
import qs from 'qs'

export const STRIPE_SESSION_COOKIE_NAME = 'strpss'

export enum SubscriptionPlanName {
  BASIC = 'basic',
  PLUS = 'plus',
  PREMIUM = 'premium'
}

@containerScoped()
export default class StripeService {
  constructor(
    @inject(CookiesService) private cookies: CookiesService,
    @inject(VueRouter) private router: VueRouter,
    @inject(VueI18n) private i18n: VueI18n,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  public getAvailableCreditPackages(): Promise<AvailableCreditsResult> {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/credit-packages/')
      .validate(body => body && body.data && body.data.packages)
      .send()
  }

  public getAvailableCreditPackagesForCarGuest(): Promise<
    AvailableCreditsResult
  > {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/credit-packages/')
      .validate(body => body && body.data && body.data.packages)
      .send()
  }

  public deleteCard(): Promise<any> {
    return this.requestBuilder
      .request('delete', '/api/payments/stripe/user-pm/')
      .validate(body => body && body.message === 'deleted')
      .send()
  }

  public getAvailableSubscriptionPlans(): Promise<
    StripeSubscriptionPlansResult
  > {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/subscriptions/')
      .validate(body => body && body.data && body.data.plans)
      .send()
  }

  public getPaymentsHistory(type?: string): Promise<StripePaymentsResult> {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/payments/')
      .params({ type })
      .validate(body => body && body.data && body.data.payments)
      .send()
  }

  public getSessionInfo(sessionId: string): Promise<StripeSessionInfoResult> {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/session/')
      .params({ session: sessionId })
      .validate(body => body && body.data && body.data.session)
      .send()
  }

  @clientOnly
  public startSessionForPriceId({
    priceId,
    issueInvoice,
    cancelUrl = undefined,
    successUrl = undefined,
    forSubscription = false
  }: StripeSessionProps): Promise<StripeSessionResult> {
    // fallback urls
    const fallbackCancelUrl = window.location.href
    const fallbackSuccessUrl =
      window.location.origin +
      this.router.resolve({ name: '__account_settings_payments_success' }).href

    const url = forSubscription
      ? '/api/payments/stripe/subscriptions/'
      : '/api/payments/stripe/credit-packages/'

    return this.requestBuilder
      .request('post', url)
      .data({
        price_id: priceId,
        issue_invoice: issueInvoice,
        cancel_url: cancelUrl || fallbackCancelUrl,
        success_url: successUrl || fallbackSuccessUrl
      })
      .send()
  }

  @clientOnly
  public changeSubscription({
    priceId
  }: StripeSessionProps): Promise<StripeSessionResult> {
    return this.requestBuilder
      .request('patch', '/api/payments/stripe/subscriptions/change/')
      .data({
        price_id: priceId
      })
      .validate(body => body && body.status && body.status === HttpStatus.OK)
      .send()
  }

  @clientOnly
  public cancelSubscriptionAtPeriodEnd(shouldCancel: boolean): Promise<any> {
    return this.requestBuilder
      .request('patch', '/api/payments/stripe/subscriptions/')
      .data({
        cancel_at_period_end: shouldCancel
      })
      .validate(body => body && body.status && body.status === HttpStatus.OK)
      .send()
  }

  @clientOnly
  public cancelSubscriptionPermanently(): Promise<any> {
    return this.requestBuilder
      .request('delete', '/api/payments/stripe/subscriptions/')
      .validate(body => body && body.status && body.status === HttpStatus.OK)
      .send()
  }

  public getInvoicingInfoSchemaAndValues(): Promise<InvoicingSchemaResult> {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/invoicing-info/')
      .validate(
        body => body && body.data && body.data.schema && body.data.values
      )
      .send()
  }

  public getAdminInvoicingInfoSchemaAndValues(
    userId: string
  ): Promise<InvoicingSchemaResult> {
    return this.requestBuilder
      .request('get', `/api/admin/payments/stripe/${userId}/invoicing-info/`)
      .validate(
        body => body && body.data && body.data.schema && body.data.values
      )
      .send()
  }

  public getStripeSellerData(): Promise<StripeSeller> {
    const returnUrl = this.router.resolve({
      name: '__account_overview',
      query: { onboarding_return: '1' }
    }).href
    return this.requestBuilder
      .request('get', '/api/payments/stripe/seller/')
      .params({ return: returnUrl })
      .send()
  }

  public getAfmInfo(afm: number | string): Promise<AfmInfoResult> {
    return this.requestBuilder
      .request('get', '/api/payments/stripe/get-afm-info/')
      .params({ afm })
      .validate(body => body && body.data && body.data.info)
      .send()
  }

  public getOfficialRecords(
    afm: number | string
  ): Promise<OfficialRecordsResult> {
    return this.requestBuilder
      .request('get', '/api/admin/payments/stripe/get-afm-info/')
      .params({ afm })
      .validate(body => body && body.data && body.data.info)
      .map(body => {
        body.data.info.vat_info.registDate = new Date(
          body.data.info.vat_info.registDate
        ).toLocaleDateString('el-GR')
        body.data.info.vat_info.stopDate = new Date(
          body.data.info.vat_info.stopDate
        ).toLocaleDateString('el-GR')
        return toCamelCase(body.data)
      })
      .send()
  }

  public getAdminStripePayments(
    params?: StripePaymentsArgs
  ): Promise<AdminStripeRequestResult> {
    let url = 'api/payments/stripe/payments/admin/'

    if (params?.pg) {
      url = setUrlParam(url, 'pg', params?.pg)
    } else {
      url = setUrlParam(url, 'pg', 1)
    }
    if (params?.queryString) url = setUrlParam(url, 'q', params?.queryString)
    if (params?.userType) url = setUrlParam(url, 'user-type', params?.userType)
    if (params?.site) url = setUrlParam(url, 'site', params?.site)
    if (params?.fromDate) url = setUrlParam(url, 'from-date', params?.fromDate)
    if (params?.toDate) url = setUrlParam(url, 'to-date', params?.toDate)
    if (params?.tag?.length) {
      const tagQ = qs.stringify({ tag: params.tag }, { indices: false })
      url.includes('?') ? (url += `&${tagQ}`) : (url += `?${tagQ}`)
    }

    return this.requestBuilder
      .request('get', url)
      .validate(body => body && body.data)
      .map(body => toCamelCase(body.data))
      .send()
  }

  public setSessionCookie(sessionId: string) {
    this.cookies.set(STRIPE_SESSION_COOKIE_NAME, sessionId, {
      maxAge: ONE_DAY
    })
  }

  public clearSessionCookie() {
    this.cookies.delete(STRIPE_SESSION_COOKIE_NAME)
  }

  public submitInvoicingInfoForm(
    form: InvoicingInfoForm
  ): Promise<InvoicingSchemaResult> {
    return this.requestBuilder
      .request('post', '/api/payments/stripe/invoicing-info/')
      .data(toSnakeCase(form))
      .send()
  }

  public submitAdminInvoicingInfoForm(
    userId: string,
    form: InvoicingInfoForm
  ): Promise<InvoicingSchemaResult> {
    return this.requestBuilder
      .request('post', `/api/admin/payments/stripe/${userId}/invoicing-info/`)
      .data(toSnakeCase(form))
      .send()
  }

  public requestSellerPayout(): Promise<StripePayoutResult> {
    return this.requestBuilder
      .request('post', '/api/payments/stripe/seller/payout/')
      .send()
  }

  public getSubscriptionPlanTitle(tierName: string): string {
    switch (tierName) {
      case SubscriptionPlanName.PLUS:
        return 'Plus'
      case SubscriptionPlanName.PREMIUM:
        return 'Premium'
      default:
        return 'Basic'
    }
  }

  public getSubscriptionPlanDescription(tierName: string): string {
    switch (tierName) {
      case SubscriptionPlanName.PLUS:
        return this.i18n.t(
          'everything you need to properly promote your classifieds'
        ) as string
      case SubscriptionPlanName.PREMIUM:
        return this.i18n.t(
          'the optimal plan for your rapidly growing business'
        ) as string
      default:
        return this.i18n.t(
          'the absolute essentials for showcasing your classifieds'
        ) as string
    }
  }

  public getSubscriptionPlanPerks(tierName: string): string[] {
    switch (tierName) {
      case SubscriptionPlanName.PREMIUM:
        return [
          this.i18n.t('unlimited classifieds') as string,
          this.i18n.t('classified statistics for the last year') as string,
          this.i18n.t('{credits} free credits per day', {
            credits: 200
          }) as string,
          this.i18n.t('{credits} paid credits per day', {
            credits: 40
          }) as string,
          this.i18n.t('simultaneous promotion of up to {count} classifieds', {
            count: 10
          }) as string,
          this.i18n.t('your logo displayed at search results') as string,
          this.i18n.t(
            "feed of your classifieds at plot.gr's frontpage"
          ) as string
        ]
      case SubscriptionPlanName.PLUS:
        return [
          this.i18n.t('unlimited classifieds') as string,
          this.i18n.t('classified statistics for the last 6 months') as string,
          this.i18n.t('{credits} free credits per day', {
            credits: 100
          }) as string,
          this.i18n.t('{credits} paid credits per day', {
            credits: 20
          }) as string,
          this.i18n.t('simultaneous promotion of up to {count} classifieds', {
            count: 2
          }) as string
        ]
      default:
        return [
          this.i18n.t('unlimited classifieds') as string,
          this.i18n.t('classified statistics for the last month') as string,
          this.i18n.t('{credits} free credits per day', {
            credits: 50
          }) as string
        ]
    }
  }
}
