import { ValueOf } from 'type-fest'
import {
  UserSearchFilters,
  UserSearchBooleanFilter
} from '~/models/user/search'
import UserSearchService from '~/services/user/search/UserSearchService'
import {
  SET_LOADING_STATE,
  SET_USERS,
  SET_PAGE,
  SET_SEARCH_TEXT,
  SET_FILTERS,
  SET_SCHEMA,
  TOGGLE_FILTER,
  CLEAR_FILTERS,
  RESET_PAGE_NUMBER,
  SET_SORTING
} from '~/store/modules/shared/admin/users/search/mutation-types'
import { UserSearchState } from '~/store/modules/shared/admin/users/search/state'

import { ActionTreeWithRootState } from '~/store/types'

export default {
  async searchUsers(
    { commit, state },
    {
      searchText,
      filters,
      pageNumber,
      sorting,
      updateQueryParamsOfPage = true
    }: {
      searchText?: string
      filters?: UserSearchFilters
      pageNumber?: number
      sorting?: string
      updateQueryParamsOfPage: boolean
    } = {
      updateQueryParamsOfPage: true
    }
  ) {
    try {
      commit(SET_LOADING_STATE, true)
      typeof searchText !== 'undefined' && commit(SET_SEARCH_TEXT, searchText)
      typeof filters !== 'undefined' && commit(SET_FILTERS, filters)
      typeof sorting !== 'undefined' && commit(SET_SORTING, sorting)

      const userSearchService = this.$dep(UserSearchService)
      const {
        data: { users, schema },
        page
      } = await userSearchService.search(
        state.searchText,
        { ...state.filters, boolean: state.booleanFilters },
        sorting || state.sorting,
        pageNumber || state.page?.number,
        updateQueryParamsOfPage
      )

      commit(SET_USERS, users)
      commit(SET_SCHEMA, schema)
      commit(SET_PAGE, page)
    } catch (error) {
      this.$snackbar.error(error, { time: 5000, log: true })
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async searchUsersByQueryParams({ dispatch }) {
    const userSearchService = this.$dep(UserSearchService)
    const [
      searchText,
      filters,
      pageNumber,
      sorting
    ] = userSearchService.createFiltersFromSearchRequestQueryParams()
    await dispatch('searchUsers', {
      searchText,
      filters,
      pageNumber,
      sorting,
      updateQueryParamsOfPage: false
    })
  },
  async toggleFilterAndSearch(
    { commit, dispatch },
    toggleFilterValue: UserSearchBooleanFilter
  ) {
    commit(TOGGLE_FILTER, toggleFilterValue)
    commit(RESET_PAGE_NUMBER)
    await dispatch('searchUsers')
  },
  async setSortingAndSearch({ commit, dispatch }, sorting: string) {
    commit(SET_SORTING, sorting)
    commit(RESET_PAGE_NUMBER)
    await dispatch('searchUsers')
  },
  async commitAndSearch(
    { commit, dispatch },
    {
      mutation,
      value
    }: {
      mutation: string
      value: ValueOf<UserSearchFilters>
    }
  ) {
    commit(mutation, value)
    commit(RESET_PAGE_NUMBER)
    await dispatch('searchUsers')
  },
  async clearFiltersAndSearch({ commit, dispatch }) {
    commit(CLEAR_FILTERS)
    commit(RESET_PAGE_NUMBER)
    await dispatch('searchUsers')
  }
} as ActionTreeWithRootState<UserSearchState>
