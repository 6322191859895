import { interopDefault } from '~/router/utils'

export const cpanelOffersRoutes = [
  {
    path: '/cpanel/offers/',
    name: '__account_offers_cpanel',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/offers/index.vue' /* webpackChunkName: "pages-shared-account-offers-index.vue" */
        )
      ),
    meta: {
      isIframe: true,
      scrollTopSmooth: true
    },
    redirect: { name: '__account_offers_other_classifieds_cpanel' },
    children: [
      {
        path: 'my-classifieds/',
        name: '__account_offers_my_classifieds_cpanel',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/offers/my-classifieds.vue' /* webpackChunkName: "pages-shared-account-offers-my-classifieds.vue" */
            )
          ),
        meta: {
          isIframe: true,
          scrollTopSmooth: true
        }
      },
      {
        path: 'other-classifieds/',
        name: '__account_offers_other_classifieds_cpanel',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/offers/other-classifieds.vue' /* webpackChunkName: "pages-shared-account-offers-other-classifieds.vue" */
            )
          ),
        meta: {
          isIframe: true,
          scrollTopSmooth: true
        }
      }
    ]
  }
]
