import { interopDefault } from '~/router/utils'

const Notifications = () =>
  interopDefault(
    import(
      '~/pages/car/notifications/index.vue' /* webpackChunkName: "pages-notifications.vue" */
    )
  )

export default [
  {
    name: '__notifications_view',
    path: '/notifications/',
    component: Notifications
  }
]
