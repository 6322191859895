module.exports = {
  htmlAttrs: {
    lang: 'el',
    itemtype: 'https://schema.org/WebPage',
    itemscope: ''
  },
  titleTemplate: 'Car.gr - %s',
  title: 'Μεταχειρισμένα αυτοκίνητα',
  meta: [
    { charset: 'utf-8' },
    { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    {
      hid: 'description',
      name: 'description',
      content:
        'Η μεγαλύτερη αγορά για μεταχειρισμένα αυτοκίνητα και ανταλλακτικά στην Ελλάδα.'
    },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'msapplication-TileColor', content: '#004e95' },
    {
      name: 'msapplication-TileImage',
      content: '//static.car.gr/static/favicon/ms-icon-144x144.png?v=2'
    },
    { name: 'theme-color', content: '#004e95' },
    {
      name: 'msapplication-square70x70logo',
      content: '//static.car.gr/static/favicon/mstile-70x70.png?v=2'
    },
    {
      name: 'msapplication-square144x144logo',
      content: '//static.car.gr/static/favicon/mstile-144x144.png?v=2'
    },
    {
      name: 'msapplication-square150x150logo',
      content: '//static.car.gr/static/favicon/mstile-150x150.png?v=2'
    },
    {
      name: 'msapplication-wide310x150logo',
      content: '//static.car.gr/static/favicon/mstile-310x150.png?v=2'
    },
    {
      name: 'msapplication-square310x310logo',
      content: '//static.car.gr/static/favicon/mstile-310x310.png?v=2'
    },
    {
      name: 'msapplication-config',
      content: '//static.car.gr/static/favicon/browserconfig.xml?v=2'
    },
    {
      name: 'application-name',
      content: 'Car.gr'
    },
    {
      name: 'format-detection',
      content: 'telephone=no'
    },
    {
      property: 'og:image:width',
      content: '1200'
    },
    {
      property: 'og:image:height',
      content: '630'
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: 'https://static-cz.car.gr/nuxt-static/img/og/car.jpg'
    }
  ],
  link: [
    {
      rel: 'preconnect',
      href: 'https://static-cz.car.gr',
      crossorigin: ''
    },
    {
      rel: 'preconnect',
      href: 'https://static.car.gr',
      crossorigin: ''
    },
    {
      rel: 'preconnect',
      href: 'https://p.typekit.net',
      crossorigin: ''
    },
    {
      rel: 'preconnect',
      href: 'https://use.typekit.net',
      crossorigin: ''
    },
    {
      rel: 'preload',
      href: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      as: 'script'
    },
    {
      rel: 'preload',
      href: `https://use.typekit.net/${process.env.ADOBE_FONTS_WEB_PROJECT_ID}.css`,
      as: 'style'
    },
    {
      rel: 'stylesheet',
      href: `https://use.typekit.net/${process.env.ADOBE_FONTS_WEB_PROJECT_ID}.css`
    },
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      id: 'favicon',
      href: '//static.car.gr/static/favicon/favicon.ico?v=2'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '//static.car.gr/static/favicon/favicon-16x16.png?v=2'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '//static.car.gr/static/favicon/favicon-32x32.png?v=2'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: '//static.car.gr/static/favicon/favicon-96x96.png?v=2'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '//static.car.gr/static/favicon/android-icon-192x192.png?v=2'
    },
    {
      rel: 'manifest',
      href: '//static.car.gr/static/favicon/manifest.json?v=3'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '//static.car.gr/static/favicon/apple-icon-180x180.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '//static.car.gr/static/favicon/apple-icon-152x152.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: '//static.car.gr/static/favicon/apple-icon-144x144.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: '//static.car.gr/static/favicon/apple-icon-120x120.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: '//static.car.gr/static/favicon/apple-icon-114x114.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: '//static.car.gr/static/favicon/apple-icon-76x76.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: '//static.car.gr/static/favicon/apple-icon-72x72.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '//static.car.gr/static/favicon/apple-icon-60x60.png?v=2'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      href: '//static.car.gr/static/favicon/apple-icon-57x57.png?v=2'
    }
  ],
  script: [
    {
      src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      async: true
    },
    {
      src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_V4_MEASUREMENT_ID}`,
      async: true
    },
    {
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://car.gr',
        logo: 'https://www.car.gr/static/logo-square.png'
      }
    },
    {
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://car.gr',
        logo: 'https://www.car.gr/static/logo-square.png',
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://www.car.gr/cars/?q={search_term_string}'
          },
          'query-input': 'required name=search_term_string'
        }
      }
    }
  ]
}
