var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-rounded-full tw-inline-block",class:[
    _vm.variantColor,
    _vm.typeClass,
    _vm.bgColor,
    { 'tw-border-r-transparent': _vm.type === 'border' },
    _vm.sizeClass,
    _vm.borderClass
  ]})}
var staticRenderFns = []

export { render, staticRenderFns }