import { Merge } from 'type-fest'
import { Searchable } from '~/models/search/types'
import { UserType } from '../user/types'

export interface Option {
  text: string
  headerText?: string
  value: string | number | null
  count?: number
  disabled?: boolean
  isTop?: boolean
  label?: string
  selected?: boolean
  options?: Option[]
}

export interface BackendOption {
  name: string
  value: string | number
}

export interface Indexed {
  key?: string
}

export type IndexedSearchable = Merge<Indexed, Searchable>
export type IndexedOption = Merge<Indexed, Option>

export enum Size {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl'
}

export enum ViewType {
  LIST = 'list',
  GALLERY = 'gallery'
}

export enum I18nLocale {
  EL = 'el',
  EN = 'en',
  DE = 'de',
  BG = 'bg'
}

export enum ConsentName {
  OVER18 = 'over18'
}

export interface SchemaFieldValue {
  name: string
  value: string | number
}
export interface SchemaField {
  max?: number
  notEmpty: boolean | null
  notMissing: boolean | null
  required: boolean
  type: string
  values?: SchemaFieldValue[]
  disabled?: boolean
  name?: string
}

export type StringMap<T> = Map<string, T>

export interface Postcode {
  country: {
    id: number
  }
  postcode: {
    name: string
  }
}

export interface Telephone {
  code: number
  dontShowToPublic?: boolean
  telephone: string
  social: Social
}

export interface Social {
  viber: boolean
  whatsapp: boolean
}

export interface Identifiable {
  id: string | number
  type: UserType
}

export interface AvailableLocale {
  locale: I18nLocale
  title: string
  icon: string
  link: string
}

export interface StaticFilesResult {
  staticUrl: string
  files: [StaticFile]
}

export interface StaticFile {
  path: string
  filename: string
}

export interface Step {
  disabled: boolean
  label: string
  selected: boolean
  vertical: boolean
  round: boolean
  completed: boolean
}
