






import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    circular: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    rounded: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },
    width: {
      type: String,
      required: false,
      default() {
        return '100%'
      }
    },
    height: {
      type: String,
      required: false,
      default: null
    }
  }
})
