import { MutationTree } from 'vuex'
import { AdvertiseDashboardState } from './state'
import {
  DELETE_AD,
  DELETE_AD_ITEM,
  DELETE_CAMPAIGN,
  SET_AD_ITEM_PAGINATION,
  SET_AD_ITEM_ROWS,
  SET_AD_PAGINATION,
  SET_AD_ROWS,
  SET_BALANCE,
  SET_CAMPAIGN_PAGINATION,
  SET_CAMPAIGN_ROWS,
  SET_LOADING,
  SET_SELECTED_AD_ITEMS,
  SET_SELECTED_ADS,
  SET_SELECTED_CAMPAIGNS,
  UPDATE_AD,
  UPDATE_CAMPAIGN
} from './mutation-types'

import { createMutationCreator } from '~/factories/store/mutation'
import { Ad, AdCampaign } from '~/models/advertising/types'
import Vue from 'vue'

const { setter } = createMutationCreator<AdvertiseDashboardState>()

export default {
  ...setter(SET_LOADING, 'loading'),
  ...setter(SET_CAMPAIGN_ROWS, 'campaignRows'),
  ...setter(SET_CAMPAIGN_PAGINATION, 'campaignPagination'),
  ...setter(SET_AD_ROWS, 'adRows'),
  ...setter(SET_AD_PAGINATION, 'adPagination'),
  ...setter(SET_AD_ITEM_ROWS, 'adItemRows'),
  ...setter(SET_AD_ITEM_PAGINATION, 'adItemPagination'),
  ...setter(SET_SELECTED_CAMPAIGNS, 'selectedCampaigns'),
  ...setter(SET_SELECTED_ADS, 'selectedAds'),
  ...setter(SET_SELECTED_AD_ITEMS, 'selectedAdItems'),
  ...setter(SET_BALANCE, 'balance'),
  [UPDATE_AD](state, updatedAd: Ad) {
    const index = state.adRows.findIndex(a => a.id === updatedAd.id)
    if (index > -1) {
      Vue.set(state.adRows, index, updatedAd)
    }
  },
  [UPDATE_CAMPAIGN](state, updatedCampaign: AdCampaign) {
    const index = state.campaignRows.findIndex(c => c.id === updatedCampaign.id)
    if (index > -1) {
      Vue.set(state.campaignRows, index, updatedCampaign)
    }
  },
  [DELETE_AD](state, id: number | string) {
    const index = state.adRows.findIndex(a => a.id.toString() === id.toString())
    if (index > -1) {
      state.adRows.splice(index, 1)
    }
  },
  [DELETE_AD_ITEM](state, id: number | string) {
    const index = state.adItemRows.findIndex(
      a => a.id.toString() === id.toString()
    )
    if (index > -1) {
      state.adItemRows.splice(index, 1)
    }
  },
  [DELETE_CAMPAIGN](state, id: number | string) {
    const index = state.campaignRows.findIndex(
      C => C.id.toString() === id.toString()
    )
    if (index > -1) {
      state.campaignRows.splice(index, 1)
    }
  }
} as MutationTree<AdvertiseDashboardState>
