import { computed } from '@nuxtjs/composition-api'
import { Ref } from '@vue/composition-api'

export function useFormComponent(
  required: Ref<boolean>,
  floating: Ref<boolean>,
  labelClass: Ref<string | string[]>,
  hasError: Ref<boolean>,
  type: string = 'input'
) {
  const controlClasses = computed(() => [
    ...(hasError.value
      ? [
          'focus:tw-ring-red-400',
          'focus:tw-border-red-400',
          'tw-border-red-300',
          'tw-text-red-900'
        ]
      : [
          'focus:tw-ring-primary-400',
          'focus:tw-border-primary-400',
          'tw-border-grey-300',
          'tw-text-[#495057]'
        ])
  ])

  const labelClasses = computed(() => [
    'tw-text-grey-800',
    'tw-text-base',
    ...(required.value
      ? ['tw-font-bold', 'after:tw-content-["*"]', 'after:tw-ml-0.5']
      : ['tw-font-medium']),
    ...(floating.value
      ? [
          'tw-absolute',
          'tw-duration-300',
          'tw-transition-all',
          'tw-scale-75',
          'tw-bg-white',
          'tw-top-0',
          'tw-px-1',
          'tw-max-w-[110%]',
          'tw--translate-y-1/2',
          type === 'input' ? 'tw-items-center' : '',
          hasError.value ? 'tw-text-red-900' : 'tw-text-[#6b7280]',
          hasError.value
            ? 'peer-focus:tw-text-red-900'
            : 'peer-focus:tw-text-[#6b7280]',
          type === 'textarea' ? 'tw-pt-0 peer-focus:tw-pt-0' : '',
          'peer-focus:tw-scale-75',
          'peer-focus:tw-max-w-[110%]',
          'peer-focus:tw--translate-y-1/2',
          'peer-focus:tw-bg-white',
          'peer-focus:tw-right-auto',
          'peer-focus:tw-px-1',
          'peer-placeholder-shown:tw-scale-100',
          'peer-placeholder-shown:tw-bg-transparent',
          'peer-placeholder-shown:tw--translate-y-0',
          type === 'textarea' ? 'peer-placeholder-shown:tw-pt-3' : '',
          type === 'input' ? 'peer-placeholder-shown:tw-bottom-0' : '',
          'peer-placeholder-shown:tw-right-0',
          'peer-placeholder-shown:tw-px-0',
          'peer-placeholder-shown:tw-text-[#6b7280]',
          'peer-placeholder-shown:tw-max-w-full',
          'tw-origin-[0]',
          'tw-inline-flex',
          'tw-mb-0',
          'tw-text-[1rem]',
          'tw-font-normal',
          'tw-left-3',
          'tw-pointer-events-none',
          'tw-z-10'
        ]
      : []),
    labelClass.value
  ])

  const helperTextClasses = computed(() => [
    'tw-mt-2',
    ...(hasError.value ? ['text-red-600'] : ['tw-text-grey-800'])
  ])

  return { controlClasses, labelClasses, helperTextClasses }
}
