import { Store } from 'vuex'
import { ADD_PAGE_SNIPPET } from '~/store/modules/shared/dealers/site/mutation-types'
import {
  DealerSiteState,
  DEALER_SITE_NS
} from '~/store/modules/shared/dealers/site/state'
import { getNamespacedStore } from '../../../../../utils/store'
import BasePageSnippet from '../BasePageSnippet'

export default class ClassifiedPageSnippet extends BasePageSnippet {
  async onPageLoad(store: Store<any>): Promise<void> {
    const dsStore = getNamespacedStore<DealerSiteState>(store, DEALER_SITE_NS)
    dsStore.commit(ADD_PAGE_SNIPPET, { ...this })
    // @ts-ignore
    // TODO: Update vue router types to match implementation.
    await dsStore.dispatch('loadClassified', store.$router.history.current)
  }
}
