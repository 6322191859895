
// HOW TO USE
// Just add ref="swipable" at the element that you want to be swipable
// and then overwrite the onSwipeLeft, onSwipeRight methods
import Vue from 'vue'
export default Vue.extend({
  props: {
    lazyLoadListeners: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      initialX: null,
      initialY: null,
      swapDirection: null,
      dragging: false,
      diffX: 0,
      diffY: 0,
      isTouch: false,
      listenersAdded: false
    }
  },
  computed: {
    isSwipable() {
      // overwrite this if you want to conditionally add the listeners
      return true
    }
  },
  beforeDestroy() {
    this.removeSwipeListeners()
  },
  mounted() {
    if (!this.lazyLoadListeners) {
      this.addSwipeListeners()
    }
  },
  methods: {
    async addSwipeListeners() {
      await this.$nextTick()
      if (this.isSwipable && !this.listenersAdded) {
        if (this.$refs.swipable) {
          this.$refs.swipable.addEventListener('touchstart', this.startTouch, {
            passive: true
          })
          this.$refs.swipable.addEventListener('touchmove', this.moveInput, {
            passive: true
          })
          this.$refs.swipable.addEventListener('mousedown', this.startInput, {
            passive: true
          })
          this.$refs.swipable.addEventListener('mousemove', this.moveInput, {
            passive: true
          })

          this.$refs.swipable.addEventListener(
            'selectstart',
            this.disableSelect
          )

          this.listenersAdded = true
        } else {
          this.$logger.captureError(
            new Error('No ref with name "swipable" found')
          )
        }
      }
    },
    removeSwipeListeners() {
      this.removeDocumentListeners()
      this.listenersAdded = false
    },
    removeDocumentListeners() {
      document.removeEventListener('mouseup', this.stopInput)
      document.removeEventListener('touchend', this.stopInput)
    },
    startTouch(e) {
      this.isTouch = true

      this.startInput(e)
    },
    startInput(e) {
      if (!e) {
        return
      }
      if (this.isTouch) {
        document.addEventListener('touchend', this.stopInput, {
          passive: true
        })
      } else {
        document.addEventListener('mouseup', this.stopInput, {
          passive: true
        })
      }

      if (this.isTouch && e.touches) {
        this.initialX = e.touches[0].clientX
        this.initialY = e.touches[0].clientY
      } else {
        this.initialX = e.clientX
      }
    },
    moveInput(e) {
      if (this.initialX === null || !e) {
        return
      }

      let currentX = null
      let currentY = null

      if (this.isTouch && e.touches) {
        currentX = e.touches[0].clientX
        currentY = e.touches[0].clientY
      } else {
        currentX = e.clientX
      }

      this.diffX = this.initialX - currentX
      this.diffY = this.initialY - currentY

      // 10 is a bit of offset, normally it's 0
      if (Math.abs(this.diffX) > Math.abs(this.diffY)) {
        // we scroll on the x-axis
        this.dragging = true

        e.stopImmediatePropagation()
        e.stopPropagation()
        // e.preventDefault()

        if (this.diffX > 10) {
          // swiped left
          this.swapDirection = 'left'
        } else if (this.diffX < -10) {
          // swiped right
          this.swapDirection = 'right'
        } else {
          this.swapDirection = null
          this.dragging = false // so that it gets clicked instantly
        }
      }
    },
    stopInput(e) {
      if (this.swapDirection === 'right') {
        this.onSwipeRight()
      } else if (this.swapDirection === 'left') {
        this.onSwipeLeft()
      }

      if (!this.hasSomeParentWithClass(e.target, 'swipable')) {
        this.dragging = false
      }

      if (this.isTouch) {
        this.dragging = false
      }

      this.swapDirection = null
      this.initialX = null
      this.initialY = null
      this.diffX = null
      this.diffY = null
      this.isTouch = false

      this.removeDocumentListeners()
    },
    disableSelect(e) {
      e.stopPropagation()
      e.preventDefault()
    },
    onSwipeRight() {
      // overwrite this
    },
    onSwipeLeft() {
      // overwrite this
    },
    hasSomeParentWithClass(element, classname) {
      const classString = '' + element.className

      if (classString && classString.split(' ').includes(classname)) {
        return true
      }
      return (
        element.parentNode &&
        this.hasSomeParentWithClass(element.parentNode, classname)
      )
    }
  }
})
