import { ActionTree } from 'vuex'
import { ActionResult } from '~/models/shared/result'
import { ClassifiedActionsState } from './state'
import { SET_PENDING } from './mutation-types'
import { HttpStatus } from '~/constants/http'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'

export default {
  async performAction({ commit }, action) {
    commit(SET_PENDING, true)
    try {
      const data: ActionResult = await action()
      if (data && data.message) {
        const { message } = data
        this.$snackbar && this.$snackbar.success(message)
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
      if (errorMessage) {
        if (error.response?.status === HttpStatus.NO_CREDITS) {
          // @ts-ignore
          const msg = this.app.i18n.t('buy credits::promo') as string
          this.$snackbar &&
            this.$snackbar.error(errorMessage, {
              time: 5000,
              action: {
                text: msg,
                href: this.$dep(LegacyUrlService).getBuyCreditsUrl()
              }
            })
        } else {
          this.$snackbar && this.$snackbar.error(errorMessage)
          this.$logger.captureError(error)
          throw error
        }
      } else {
        this.$logger.captureError(error)
        throw error
      }
    } finally {
      commit(SET_PENDING, false)
    }
  }
} as ActionTree<ClassifiedActionsState, any>
