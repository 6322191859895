export const SET_DATA = 'SET_DATA'
export const SET_REFS = 'SET_REFS'
export const SET_CURRENT_USER_IS_PARKED = 'SET_CURRENT_USER_IS_PARKED'
export const SET_CLEAN_DESCRIPTION = 'SET_CLEAN_DESCRIPTION'
export const SET_DEBUG_SHIMMER = 'SET_DEBUG_SHIMMER'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_CLASSIFIED_ID = 'SET_CLASSIFIED_ID'
export const SET_CLASSIFIED = 'SET_CLASSIFIED'
export const SET_BIG_PHOTOS_VISIBLE = 'SET_BIG_PHOTOS_VISIBLE'
export const SET_YOUTUBE_VISIBLE = 'SET_YOUTUBE_VISIBLE'
export const SET_SHOW_CONTACT = 'SET_SHOW_CONTACT'
export const SET_CONTACT_SCHEMA = 'SET_CONTACT_SCHEMA'
export const SET_MESSAGE_STATS = 'SET_MESSAGE_STATS'
export const SET_SHOW_CONTACT_PROMPT = 'SET_SHOW_CONTACT_PROMPT'
export const RESET_STATE = 'RESET_STATE'
export const SET_HIDE_PROMOTION = 'SET_HIDE_PROMOTION'
export const SET_LEASING_OPTION = 'SET_LEASING_OPTION'
export const SET_LEASING_WITH_VAT = 'SET_LEASING_WITH_VAT'
export const SET_LEASING_AVAILABLE_DURATIONS = 'SET_LEASING_AVAILABLE_DURATIONS'
export const SET_LEASING_DURATION = 'SET_LEASING_DURATION'
export const SET_INSTALLMENTS_DOWNPAYMENT = 'SET_INSTALLMENTS_DOWNPAYMENT'
export const SET_INSTALLMENTS_INSTALLMENT_AMOUNT =
  'SET_INSTALLMENTS_INSTALLMENT_AMOUNT'
export const SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS =
  'SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS'
export const SET_EXTERNAL_CERTIFICATE = 'SET_EXTERNAL_CERTIFICATE'
export const SET_PROCESSED_PHOTOS = 'SET_PROCESSED_PHOTOS'
export const REMOVE_PROCESSED_PHOTO = 'REMOVE_PROCESSED_PHOTO'
export const SET_AUTO_TOUCH_DATA_IN_VIEW = 'SET_AUTO_TOUCH_DATA_IN_VIEW'
export const SET_HAS_UNREAD_MESSAGES = 'SET_HAS_UNREAD_MESSAGES'
export const SET_FLOATING_BUTTONS_MESSAGE_PHONE_VISIBILITY =
  'SET_FLOATING_BUTTONS_MESSAGE_PHONE_VISIBILITY'
export const SET_CLASSIFIED_NOTES = 'SET_CLASSIFIED_NOTES'
export const SET_VARIANT = 'SET_VARIANT'
export const SET_OFFER_COUNT = 'SET_OFFER_COUNT'
export const SET_OFFER_THREAD_READ = 'SET_OFFER_THREAD_READ'
export const SET_CLASSIFIED_OFFERS = 'SET_CLASSIFIED_OFFERS'
export const SET_HAS_OFFERS_FOR_CLASSIFIED = 'SET_HAS_OFFERS_FOR_CLASSIFIED'
export const REMOVE_CLASSIFIED_OFFERS = 'REMOVE_CLASSIFIED_OFFERS'
export const UPDATE_CLASSIFIED_OFFER = 'UPDATE_CLASSIFIED_OFFER'
export const SET_HAS_AUDIT_FOR_CLASSIFIED = 'SET_HAS_AUDIT_FOR_CLASSIFIED'
export const SET_AUDIT_REQUESTS = 'SET_AUDIT_REQUESTS'
export const SET_CONTACT_FORM_DATA = 'SET_CONTACT_FORM_DATA'
export const UPDATE_AUDIT_REQUEST = 'UPDATE_AUDIT_REQUEST'
export const SET_AUDITS = 'SET_AUDITS'
