import BasePageSnippet from './BasePageSnippet'
import ClassifiedPageSnippet from './classes/ClassifiedPageSnippet'
import ContactPageSnippet from './classes/ContactPageSnippet'
import CustomPageSnippet from './classes/CustomPageSnippet'
import SearchPageSnippet from './classes/SearchPageSnippet'
import { NonClassifiedPageSnippet } from './types'

export const CLASSIFIED_ID_PREFIX = 'classified_'

export function classifiedPageSnippet(id: string): ClassifiedPageSnippet {
  return new ClassifiedPageSnippet({
    id: CLASSIFIED_ID_PREFIX + id,
    type: 'classified',
    pagePath: id,
    index: false,
    visible: true
  })
}

export function pageSnippet(ps: NonClassifiedPageSnippet): BasePageSnippet {
  switch (ps.type) {
    case 'custom_page':
      return new CustomPageSnippet(ps)
    case 'search':
    case 'search_custom_page':
      return new SearchPageSnippet(ps)
    case 'contact':
      return new ContactPageSnippet(ps)
    default:
      return new CustomPageSnippet(ps)
  }
}
