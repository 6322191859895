import { MutationTree } from 'vuex'
import { AccountState } from './state'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  SET_ACCOUNT_LOADING,
  SET_SUBSCRIPTION_PLANS,
  SET_ACTIVE_SUBSCRIPTION,
  SET_STRIPE_PORTAL_URL,
  SET_ACCOUNT_OVERVIEW,
  SET_AGENT_QUICK_STATS_PANELS,
  SET_ACCOUNT_OVERVIEW_STRIPE_SELLER
} from '~/store/modules/shared/account/mutation-types'
import { StripeSeller } from '~/models/payments/stripe'
import Vue from 'vue'

const { setter } = createMutationCreator<AccountState>()

export default {
  ...setter(SET_ACCOUNT_LOADING, 'loading'),
  ...setter(SET_SUBSCRIPTION_PLANS, 'subscriptionPlans'),
  ...setter(SET_ACTIVE_SUBSCRIPTION, 'activeSubscription'),
  ...setter(SET_STRIPE_PORTAL_URL, 'stripePortalUrl'),
  ...setter(SET_ACCOUNT_OVERVIEW, 'accountOverview'),
  ...setter(SET_AGENT_QUICK_STATS_PANELS, 'quickStatsPanels'),
  [SET_ACCOUNT_OVERVIEW_STRIPE_SELLER](state, stripeSeller: StripeSeller) {
    if (state.accountOverview) {
      Vue.set(state.accountOverview, 'stripeSeller', stripeSeller)
    }
  }
} as MutationTree<AccountState>
