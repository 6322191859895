import { interopDefault } from '~/router/utils'

export const adminUsersRoutes = [
  {
    name: '__admin_users_search',
    path: '/admin/users/search',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/users/search.vue' /* webpackChunkName: "pages-shared-admin-users-search" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__admin_users_edit',
    path: '/admin/users/:userId/edit',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/users/edit.vue' /* webpackChunkName: "pages-shared-admin-users-edit" */
        )
      ),
    meta: { noFooter: true },
    redirect: { name: '__admin_users_edit_details' },
    children: [
      {
        path: 'details/',
        name: '__admin_users_edit_details',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/users/details.vue' /* webpackChunkName: "pages-shared-admin-users-details.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'tools/',
        name: '__admin_users_edit_tools',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/users/tools.vue' /* webpackChunkName: "pages-shared-admin-users-tools.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'accounting/',
        name: '__admin_users_edit_accounting',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/users/accounting.vue' /* webpackChunkName: "pages-shared-admin-users-accounting.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'dealers/',
        name: '__admin_users_edit_dealers_index',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/dealers/index.vue' /* webpackChunkName: "pages-shared-admin-dealers-index.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'dealers/create',
        name: '__admin_users_edit_dealers_create',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/dealers/create.vue' /* webpackChunkName: "pages-shared-admin-dealers-create.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'dealers/:dealerId/edit/',
        name: '__admin_users_edit_dealers_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/dealers/edit.vue' /* webpackChunkName: "pages-shared-admin-dealers-edit.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'logs/',
        name: '__admin_users_edit_logs',
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/admin/users/logs.vue' /* webpackChunkName: "pages-shared-admin-users-logs.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      }
    ]
  }
]
