import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { inject, registry } from 'tsyringe'
import { ValueOf } from 'type-fest'
import VueRouter from 'vue-router'
import { UrlQueryParamSetterBuilder } from '~/builders/url/query/UrlQueryParamSetterBuilder'
import { containerScoped } from '~/decorators/dependency-container'
import { PAGE_PARAM_NAME } from '~/constants/pagination'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { Result } from '~/models/shared/result'
import { StringMap } from '~/models/shared/types'
import { UrlQueryParamSetter } from '~/models/url'
import {
  SearchableUser,
  UserSearchFilters,
  UserSearchSchema,
  UserSearchBooleanFilter,
  StaticNameSearchFilters,
  idempotentlyNamedSearchFilterSet
} from '~/models/user/search'
import LoggerService from '~/services/LoggerService'
import { invalidBodyError } from '~/services/errors'
import { formatPage } from '~/services/formatters'
import { toCamelCase } from '~/utils/object'
import { UserType } from '~/models/user/types'

export const userSearchUrlToken = Symbol('userSearchUrlInjectionToken')

/**
 * Should be redacted from public.
 */
@containerScoped()
@registry([
  {
    token: userSearchUrlToken,
    useValue: '/api/admin/users/search/'
  }
])
export default class UserSearchService {
  private setQueryParams: UrlQueryParamSetter

  constructor(
    @inject(httpToken) private http: NuxtAxiosInstance,
    @inject(userSearchUrlToken) private userSearchUrl: string,
    @inject(LoggerService) private logger: LoggerService,
    @inject(VueRouter) private router: VueRouter,
    @inject(UrlQueryParamSetterBuilder)
    urlQueryParamSetterBuilder: UrlQueryParamSetterBuilder
  ) {
    this.setQueryParams = urlQueryParamSetterBuilder
      .keyScope([
        ...Object.values(UserSearchBooleanFilter),
        ...idempotentlyNamedSearchFilterSet
      ])
      .build()
  }

  async search(
    text: string,
    filters: UserSearchFilters = { boolean: new Set() },
    sorting: string,
    page: number,
    updateQueryParamsOfPage: boolean = false
  ): Promise<
    Result<{ users: StringMap<SearchableUser>; schema: UserSearchSchema }>
  > {
    const queryParams = this.createSearchRequestQueryParams(
      text,
      filters,
      page,
      sorting
    )
    const { data: body } = await this.http.get(this.userSearchUrl, {
      params: queryParams
    })

    if (!body?.data?.users?.rows || !body?.data?.users?.pagination) {
      throw invalidBodyError(body)
    }

    const {
      users: { rows, pagination }
    } = body.data
    const userMap = new Map<string, SearchableUser>(
      rows.map((user: any) => {
        if (typeof user.id === 'undefined') {
          this.logger.captureError(
            new TypeError(`User id is undefined: ${JSON.stringify(user)}`)
          )
        }

        return [user.id?.toString(), toCamelCase(user)]
      })
    )
    delete body.data.users

    if (updateQueryParamsOfPage) {
      // TODO: Discuss potential removal of mutation from plugins/axios/on-request.ts interceptor since it causes
      //  side-effects to code that uses http.
      delete queryParams._tag
      this.setQueryParams(queryParams)
    }

    return {
      data: { users: userMap, schema: toCamelCase(body.data) },
      page: formatPage(pagination)
    }
  }

  private createSearchRequestQueryParams(
    searchText: string,
    filters: UserSearchFilters,
    pageNumber: number = 1,
    sorting: string
  ): Record<string, string> {
    const getFilterIfExistent = this.createExistentFilterGetter(filters)
    return {
      ...(searchText ? { q: searchText } : {}),
      ...getFilterIfExistent('user-type'),
      ...getFilterIfExistent('site'),
      ...getFilterIfExistent('last-activity'),
      ...[...filters.boolean.values()].reduce(
        (obj, b) => ({ ...obj, [b]: 1 }),
        {}
      ),
      ...(sorting ? { sort: sorting } : {}),
      [PAGE_PARAM_NAME]: pageNumber.toString()
    }
  }

  createFiltersFromSearchRequestQueryParams(): [
    string | null,
    UserSearchFilters,
    number | null,
    string | null
  ] {
    const queryParams = this.router.currentRoute.query

    const getFilterIfExistent = this.createExistentFilterGetter(queryParams)

    if (!queryParams['user-type']) {
      delete queryParams['user-type']
    }

    const searchText = queryParams.q as string | null
    const sorting = queryParams.sort as string | null

    const userType = getFilterIfExistent(
      'user-type',
      (type: UserType | UserType[]) =>
        typeof type === 'string' ? [type] : type
    )
    const site = getFilterIfExistent('site')
    const lastActivity = getFilterIfExistent('last-activity')
    const pageNumber = queryParams.pg

    delete queryParams['user-type']
    delete queryParams.site
    delete queryParams['last-activity']
    delete queryParams.q
    delete queryParams.pg

    const filters: UserSearchFilters = {
      ...userType,
      ...site,
      ...lastActivity,
      boolean: new Set(Object.keys(queryParams)) as Set<UserSearchBooleanFilter>
    }

    return [searchText, filters, Number(pageNumber), sorting]
  }

  private createExistentFilterGetter(filters: StaticNameSearchFilters) {
    return <K extends keyof StaticNameSearchFilters>(
      key: K,
      mapValue: (value: any) => ValueOf<StaticNameSearchFilters, K> = value =>
        value
    ) => {
      return key in filters ? { [key]: mapValue(filters[key]) } : {}
    }
  }

  userHasIssues(user: SearchableUser): boolean {
    return user.xmlFeed?.hasIssues
  }

  userIsDisabled(user: SearchableUser): boolean {
    return (user.userTags && user.userTags.includes('disabled')) || false
  }
}
