import { AuditRequest } from '~/models/audit/request'
import { CategoryId } from '~/models/category/types'

export interface ValueLabel {
  label: string
  value: string | Array<string>
}

export interface ExternalCertificatedDataDoc {
  example: object
  label: string
  type: string
}

export interface ExternalCertificatesAuditCategory {
  label?: string
  name?: string
  state: CertificateState
  comment: string
  position?: string
  value?: CommentState | CommentState[]
}
export interface CommentState {
  comment: string
  state: string
  name?: string
  label?: string
}

export enum CheckPositions {
  FRONT = 'front',
  BACK = 'back',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  FRONT_LEFT = 'front-left',
  FRONT_RIGHT = 'front-right',
  BACK_LEFT = 'back-left',
  BACK_RIGHT = 'back-right',
  NONE = 'none'
}
export interface ExternalCertificatesFieldPositions {
  front?: CommentState
  back?: CommentState
  left?: CommentState
  right?: CommentState
  'front-left'?: CommentState
  'front-right'?: CommentState
  'back-left'?: CommentState
  'back-right'?: CommentState
  center?: CommentState
}

export interface ExternalCertifcatesAvailablePositions {
  frontBack: Array<string>
  surround: Array<string>
  leftRight: Array<string>
}
export interface ExternalCertificatesCategoryField
  extends ExternalCertificatesFieldPositions {
  isRequired: boolean
  label: string
  type: string
  max?: number
  min?: number
  name: string
  comment?: string
  state?: string
  positions?: ExternalCertificatesFieldPositions
  availablePositions: ExternalCertifcatesAvailablePositions
  value?: string | Date | []
  isActive?: boolean
  position?: 'frontBack' | 'surround' | 'leftRight'
  categoryLabel?: string
}

export interface ExternalCertificatesGeneralInfoForm {
  auditor: ExternalCertificatesCategoryField
  date: ExternalCertificatesCategoryField | null
  kteoLocation: ExternalCertificatesCategoryField
  licencePlate: ExternalCertificatesCategoryField
  make: ExternalCertificatesCategoryField
  firstRegistrationDate: ExternalCertificatesCategoryField & {
    europe: string | Date
    greece: string | Date
    value?: {
      europe: string | Date
      greece: string | Date
    }
  }
  engineSize: ExternalCertificatesCategoryField
  doors: ExternalCertificatesCategoryField
  exteriorColor: ExternalCertificatesCategoryField
  transmission: ExternalCertificatesCategoryField
  vinNumber: ExternalCertificatesCategoryField
  fuelType: ExternalCertificatesCategoryField
  euroclass: ExternalCertificatesCategoryField
  seats: ExternalCertificatesCategoryField
  mileage: ExternalCertificatesCategoryField & {
    audit: number
    certifiedKm: string
    lastKteoKm: number
    lastServiceKm: number
    value?: {
      audit: string
      certifiedKm: string
      lastKteoKm: string
      lastServiceKm: string
    }
  }
}
export interface ExternalCertificatesGeneralInfo {
  auditor: ValueLabel
  date: ValueLabel
  kteoLocation: ValueLabel
  licencePlate: ValueLabel
  make: ValueLabel
  model: ValueLabel
  firstRegistrationDate: ValueLabel
  engineSize: ValueLabel
  doors: ValueLabel
  exteriorColor: ValueLabel
  transmission: ValueLabel
  vinNumber: ValueLabel
  fuelType: ValueLabel
  euroclass: ValueLabel
  seats: ValueLabel
  mileage: {
    label: string
    value: { lastServiceKm: number; audit: number; lastKteoKm: number }
  }
}

export interface ExternalCertificatesDocuments {
  carRegistration: ValueLabel | ExternalCertificatesCategoryField
  circulationTaxPaid: ValueLabel | ExternalCertificatesCategoryField
  kteo: ValueLabel | ExternalCertificatesCategoryField
  exhaustCard: ValueLabel | ExternalCertificatesCategoryField
  sunscreenMembranes: ValueLabel | ExternalCertificatesCategoryField
  otherDocuments: ValueLabel | ExternalCertificatesCategoryField
  secondKey: ValueLabel | ExternalCertificatesCategoryField
  retentionOfTitle: ValueLabel | ExternalCertificatesCategoryField
  instructionManual: ValueLabel | ExternalCertificatesCategoryField
  serviceBook: ValueLabel | ExternalCertificatesCategoryField
  lastService: ValueLabel | ExternalCertificatesCategoryField
  nextService: ValueLabel | ExternalCertificatesCategoryField
  secondAlarmRemote: ValueLabel | ExternalCertificatesCategoryField
}

export interface ExternalCertificateDetails {
  attachments: any[]
  auditRequest?: AuditRequest
  details: any
  finalized: boolean
  id: number
  modified: string
  providerName: string
  providerPhoto: string
  publicUrl: string
  reportId: number
  editHistory: []
  synopsis?: string
}
export interface ExternalCertificates {
  reportId: number
  auditCategories: Array<ExternalCertificatesAuditCategory>
  generalInfo: ExternalCertificatesGeneralInfo
  attachments: []
  documents: ExternalCertificatesDocuments
  synopsis?: {
    label: string
    value: string
  }
  generalComments?: string
  generatedComments?: string
  certificate: ExternalCertificateDetails
}

export interface ExternalCertificatesCategorySpecOption {
  value: CategoryId
  label: string
}

export interface ExternalCertificatesCategorySpec {
  selected: CategoryId
  options: ExternalCertificatesCategorySpecOption[]
}

export interface ExternalCertificatesSchema {
  generalComments: {
    generalComments: ExternalCertificatesCategoryField
  }
  synopsis: {
    synopsis: ExternalCertificatesCategoryField
  }
  auditRequest: {
    auditRequest: {
      options: { value: number; label: string }
    }
  }
  finalize: {
    finalize: {
      isRequired: boolean
      type: string
    }
  }
  reportId: {
    reportId: ExternalCertificatesCategoryField
  }
  attachments: {
    attachments: {
      isRequired: boolean
      type?: string
      files: []
    }
  }
  auditCategories: ExternalCertificatesAuditCategories
  generalInfo: ExternalCertificatesGeneralInfoForm
  documents: ExternalCertificatesDocuments
}

export interface DocData {
  example: object
  label: string
  name: string
  type: string
}

export interface ExternalCertificatesDoc {
  topLevelData: Array<ValueLabel>
  reportId: { required: boolean; type: number }
  auditCategories: Array<{
    label: string
    name: string
    value: Array<DocData>
  }>
  generalInfo: Array<DocData>
  documents: Array<DocData>
  generalComments: { required: boolean; type: number }
}

export interface ExternalCertificatesAuditCategories {
  axlesWheelsTires: { [key: string]: ExternalCertificatesCategoryField }
  bodyChassis: { [key: string]: ExternalCertificatesCategoryField }
  brakingSystem: { [key: string]: ExternalCertificatesCategoryField }
  coolingSystem: { [key: string]: ExternalCertificatesCategoryField }
  engine: { [key: string]: ExternalCertificatesCategoryField }
  interior: { [key: string]: ExternalCertificatesCategoryField }
  lightsElectrical: { [key: string]: ExternalCertificatesCategoryField }
  motionTransmission: { [key: string]: ExternalCertificatesCategoryField }
  otherEquipment: { [key: string]: ExternalCertificatesCategoryField }
  refinishing: { [key: string]: ExternalCertificatesCategoryField }
  steeringSystem: { [key: string]: ExternalCertificatesCategoryField }
  visibility: { [key: string]: ExternalCertificatesCategoryField }
}
export interface CertificateAttachment {
  contentType: string
  domain: string
  file: string
  name: string
  url: string
  id?: string
}

export interface CertificateReceiptResult {
  paymentReceipt: string
}

export enum CertificateState {
  BAD = 'bad',
  GOOD = 'good',
  MODERATE = 'moderate',
  INFO = 'info',
  NOT_CHECKED = 'notChecked'
}
