import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { ActionResult } from '~/models/shared/result'
import { UserCredits } from '~/models/user/types'
import UrlFormatter from '~/services/url/UrlFormatter'
import { invalidBodyError } from '../errors'
import { formatLeasingOption } from '~/services/classified/formatters'
import { toCamelCase } from '~/utils/object'
import { ViewResult } from '~/models/classified/view'
import { DeletedInfoResult } from '~/models/classified/view/deleted'
import {
  PromoClassifiedStatus,
  PromoteInfoResult,
  PromotePostResult,
  PromoUserStatus
} from '~/models/classified/promoted'
import {
  SimilarClassifiedsEntity,
  SimilarClassifiedsResult
} from '~/models/classified/view/similar'
import { MoveOldJobResult } from '~/models/classified/view/old-job'
import { ClassifiedViewLeasing } from '~/models/classified/view/leasing'
import { ClassifiedViewFinance } from '~/models/classified/view/finance'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  NoteDeleteResult,
  NotePostResult,
  NoteResponse
} from '~/models/classified/note'
import { ClassifiedDeletionMetaData } from './../../models/classified/types'
import { toSnakeCase } from '~/utils/snake-case'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { USER_NS } from '~/store/modules/shared/user/state'

@containerScoped()
export default class ClassifiedService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(UrlFormatter) private urlFormatter: UrlFormatter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(Store) private store: Store<RootState>
  ) {}

  async view(classifiedId: number, params: object): Promise<ViewResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/${classifiedId}/`,
      {
        params
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return this.formatViewResponse(body)
  }

  async getDeletedInfo(classifiedId: number): Promise<DeletedInfoResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/${classifiedId}/deleted/`
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return this.formatDeletedInfoResponse(body)
  }

  async getPromoteInfo(classifiedId: number): Promise<PromoteInfoResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/promoted/${classifiedId}/`
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    const bodyCamelCase = toCamelCase(body)
    return this.formatPromoteInfoResponse(bodyCamelCase)
  }

  async getSimilarClassifieds(
    classifiedId: number,
    getAll?: boolean
  ): Promise<SimilarClassifiedsResult> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/${classifiedId}/similar/`,
      {
        params: { all: getAll }
      }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return this.formatSimilarClassifiedsResponse(body)
  }

  async promoteClassified(
    classifiedId: number,
    tier: string
  ): Promise<PromotePostResult> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/promoted/${classifiedId}/`,
      { tier }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    const bodyCamelCase = toCamelCase(body)
    return this.formatPromotePostResponse(bodyCamelCase)
  }

  async cancelPromotion(classifiedId: number): Promise<AxiosResponse> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/promoted/${classifiedId}/`
    )
    return response
  }

  async getBanPromoUserState(classifiedId: number): Promise<PromoUserStatus> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/banned/`

    const response: AxiosResponse = await this.http.get(url)

    const { data: body } = response
    if (!body && !body.data) {
      throw invalidBodyError(body)
    }

    return { userEnabled: !body.data.banned }
  }

  async banPromoUser(classifiedId: number): Promise<AxiosResponse> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/banned/`

    const response: AxiosResponse = await this.http.delete(url)
    return response
  }

  async enablePromoUser(classifiedId: number): Promise<AxiosResponse> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/banned/`

    const response: AxiosResponse = await this.http.post(url)
    return response
  }

  async getBanPromoClassifiedState(
    classifiedId: number
  ): Promise<PromoClassifiedStatus> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/enabled/`

    const response: AxiosResponse = await this.http.get(url)

    const { data: body } = response
    if (!body && !body.data) {
      throw invalidBodyError(body)
    }

    return { classifiedEnabled: body.data.enabled }
  }

  banPromoClassified(classifiedId: number): Promise<AxiosResponse> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/enabled/`

    return this.http.delete(url)
  }

  enablePromoClassified(classifiedId: number): Promise<AxiosResponse> {
    const url = `/api/admin/classifieds/promotions/${classifiedId}/enabled/`

    return this.http.post(url)
  }

  touch(classifiedId: number): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/touch/`)
      .action()
      .send()
  }

  async delete(classifiedId: number, reason?: string): Promise<ActionResult> {
    const response = await this.requestBuilder
      .request('delete', `/api/classifieds/${classifiedId}/delete/`)
      .params({ reason })
      .action()
      .send()

    this.store.dispatch(`${USER_NS}/reloadUserExtras`)

    return response
  }

  async remove(classifiedId: number): Promise<ActionResult> {
    const response = await this.requestBuilder
      .request('delete', `/api/classifieds/${classifiedId}/remove/`)
      .action()
      .send()

    this.store.dispatch(`${USER_NS}/reloadUserExtras`)

    return response
  }

  async restore(classifiedId: number): Promise<ActionResult> {
    const response = await this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/restore/`)
      .action()
      .send()

    this.store.dispatch(`${USER_NS}/reloadUserExtras`)

    return response
  }

  showToPublic(classifiedId: number): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/show-to-public/`)
      .action()
      .send()
  }

  hideFromPublic(
    classifiedId: number,
    reason: string,
    notes?: string
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/non-public/`)
      .data({
        reason,
        notes
      })
      .action()
      .send()
  }

  show(classifiedId: number): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/show/`)
      .action()
      .send()
  }

  hide(classifiedId: number): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/hide/`)
      .action()
      .send()
  }

  hideAllFromPublic(
    classifiedIds: number[],
    reason: string,
    notes: string
  ): Promise<AxiosResponse[]> {
    return Promise.all(
      classifiedIds.map(classifiedId => {
        return this.hideFromPublic(classifiedId, reason, notes).catch(
          e => e.response
        )
      })
    )
  }

  showAllToPublic(classifiedIds: number[]): Promise<AxiosResponse[][]> {
    return Promise.all(
      classifiedIds.map(classifiedId =>
        Promise.all([
          this.showToPublic(classifiedId),
          this.show(classifiedId)
        ]).catch(e => e.response)
      )
    )
  }

  touchAll(classifiedIds: number[]): Promise<AxiosResponse[]> {
    return Promise.all(
      classifiedIds.map(classifiedId => {
        return this.touch(classifiedId).catch(e => e.response)
      })
    )
  }

  removeAll(classifiedIds: number[]): Promise<AxiosResponse[]> {
    return Promise.all(
      classifiedIds.map(classifiedId => {
        return this.remove(classifiedId).catch(e => e.response)
      })
    )
  }

  restoreAll(classifiedIds: number[]): Promise<AxiosResponse[]> {
    return Promise.all(
      classifiedIds.map(classifiedId => {
        return this.restore(classifiedId).catch(e => e.response)
      })
    )
  }

  public getNotes(classifiedId: number): Promise<NoteResponse> {
    return this.requestBuilder
      .request('get', `/api/classifieds/${classifiedId}/notes/`)
      .validate(body => body && body.data && body.data.note)
      .send()
  }

  public postNotes(
    classifiedId: number,
    notes: string
  ): Promise<NotePostResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/notes/`)
      .data({
        notes
      })
      .validate(body => body && body.data && body.data.values)
      .send()
  }

  public deleteNotes(classifiedId: number): Promise<NoteDeleteResult> {
    return this.requestBuilder
      .request('delete', `/api/classifieds/${classifiedId}/notes/`)
      .validate(body => body && body.data && body.data.deleted)
      .send()
  }

  async moveOldJob(classifiedId: number): Promise<MoveOldJobResult> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/${classifiedId}/move-old-job/`
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return this.formatMoveOldJobResponse(body)
  }

  public restoreWithCode(
    classifiedId: number,
    code: string
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/restore-with-code/`)
      .data({
        code
      })
      .validate(body => body)
      .map(body => {
        return body
      })
      .send()
  }

  public touchWithCode(
    classifiedId: number,
    code: string
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/touch-with-code/`)
      .data({
        code
      })
      .validate(body => body)
      .map(body => {
        return body
      })
      .send()
  }

  public sendClassifiedDeletionMetadata(
    classifiedId: number,
    answers: ClassifiedDeletionMetaData
  ): Promise<any> {
    const params = toSnakeCase(answers)
    return this.requestBuilder
      .request('post', `/api/classifieds/${classifiedId}/deletion-metadata/`)
      .data(params)
      .validate(body => body)
      .send()
  }

  fetchDeletionMetadata(
    classifiedId: string
  ): Promise<{
    title: string
    deletionReason: string
  }> {
    return this.requestBuilder
      .request('get', `/api/classifieds/${classifiedId}/deletion-metadata/`)
      .validate(body => body.classified?.field_values?.title)
      .map(body => ({
        title: body.classified.field_values.title,
        deletionReason: body.deletion_reason
      }))
      .send()
  }

  private formatViewResponse(r: any): ViewResult {
    const { data, _page } = r
    const seller = { ...data.seller }
    if (seller.website) {
      seller.website = this.urlFormatter.getValidHttpUrl(seller.website)
    }
    if (seller.social_media) {
      seller.social_media = this.urlFormatter.formatSocialMediaLinks(
        seller.social_media
      )
    }
    if (seller.verification) {
      seller.verification = toCamelCase(seller.verification)
    }
    seller.canReceiveMessages = seller.can_receive_messages
    delete seller.can_receive_messages
    return {
      consent: data.consent,
      audits: toCamelCase(data.audits),
      classified: {
        ...data.classified,
        ...{
          leasing:
            data.classified.leasing &&
            this.formatViewLeasing(data.classified.leasing),
          finance:
            data.classified.finance &&
            this.formatViewFinance(data.classified.finance)
        },
        auto_touch: toCamelCase(data.auto_touch),
        extraRentalServices:
          data.classified.extra_rental_services &&
          toCamelCase(data.classified.extra_rental_services),
        rentalInsurance:
          data.classified.rental_insurance &&
          toCamelCase(data.classified.rental_insurance),
        rentalPickupLocation:
          data.classified.rental_pickup_location &&
          toCamelCase(data.classified.rental_pickup_location),
        pricePerMonth:
          data.classified.price_per_month &&
          toCamelCase(data.classified.price_per_month),
        compactPhotos:
          data.classified.photos_compact &&
          toCamelCase(data.classified.photos_compact),
        showLoansInfo: toCamelCase(data.classified.show_loans_info),
        paidFeatures: toCamelCase(data.paid_features),
        saleRequests: toCamelCase(data.sale_requests)
      },
      currentUser: data.current_user,
      adsTargetings: data.ads_targetings,
      ads: data.ads,
      extra: data.extra,
      page: _page,
      promotion: toCamelCase(data.promotion),
      paidClassified: data.paid_classified
        ? toCamelCase(data.paid_classified)
        : undefined,
      seller,
      rental: data.rental && toCamelCase(data.rental),
      seoUrl: data.seo_url,
      insurance: toCamelCase(data.insurance)
    }
  }

  private formatDeletedInfoResponse(r: any): DeletedInfoResult {
    const { data, _page } = r
    return {
      classified: data.classified,
      relatedLinks: data.related_links,
      page: _page
    }
  }

  private formatPromoteInfoResponse(r: any): PromoteInfoResult {
    const { data } = r
    return {
      availableTiers: data.availableTiers,
      promotion: data.promotion,
      availablePromotions: data.availablePromotions,
      status: r.status,
      message: r.message
    }
  }

  private formatPromotePostResponse(r: any): PromotePostResult {
    const { data } = r

    const formatedCredits: UserCredits = {
      free: data.availableCredits
        ? data.availableCredits.freeCredits
        : undefined,
      paid: data.availableCredits
        ? data.availableCredits.paidCredits
        : undefined
    }

    return {
      availableCredits: formatedCredits,
      promotion: data.promotion,
      status: r.status,
      message: r.message
    }
  }

  private formatMoveOldJobResponse(b: any): MoveOldJobResult {
    const { data } = b
    return {
      viewUrl: data.view_url,
      editUrl: data.edit_url
    }
  }

  private formatSimilarClassifiedsResponse(r: any): SimilarClassifiedsResult {
    const { data } = r
    const { similar, seen_by_others: seenByOthers } = data
    return {
      similar: similar && this.formatSimilarClassifiedsEntity(similar),
      seenByOthers:
        seenByOthers && this.formatSimilarClassifiedsEntity(seenByOthers)
    }
  }

  private formatSimilarClassifiedsEntity(e: any): SimilarClassifiedsEntity {
    return {
      seoUrl: e.seo_url,
      similarArgs: e.similar_args,
      total: e.total,
      user: e.user,
      classifieds: e.classifieds,
      label: e.label
    }
  }

  private formatViewLeasing(l: any): ClassifiedViewLeasing {
    return {
      onlyLeasing: l.only_leasing,
      options: l.options && l.options.map(formatLeasingOption),
      extras: l.extras
    }
  }

  private formatViewFinance(f: any): ClassifiedViewFinance {
    return {
      price: f.price,
      options: f.options && {
        maxInstallments: f.options.maxinstallments,
        minDownpayment: Math.round(f.price * f.options.mindownpayment) / 100,
        interestPercentage: f.options.interest
      }
    }
  }
}
