import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__landing_pages_rentals',
    path: '/landing/rentals/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/rentals/index.vue' /* webpackChunkName: "landing-pages-rentals.vue" */
        )
      )
  },
  {
    name: '__landing_pages_promotions',
    path: '/landing/promotions/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/promotions/index.vue' /* webpackChunkName: "landing-pages-promotions.vue" */
        )
      )
  },
  {
    name: '__landing_pages_tbi',
    path: '/landing/tbi/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/tbi/index.vue' /* webpackChunkName: "landing-pages-tbi.vue" */
        )
      )
  },
  {
    name: '__landing_pages_tbi_blackfriday',
    path: '/landing/tbi-blackfriday/',
    meta: { isLanding: true, blackFriday: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/tbi/index.vue' /* webpackChunkName: "landing-pages-tbi.vue" */
        )
      )
  },
  {
    name: '__landing_pages_audits',
    path: '/landing/audits/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/audits/index.vue' /* webpackChunkName: "landing-pages-audits.vue" */
        )
      )
  }
]
