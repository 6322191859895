import { Context } from '@nuxt/types'
import CacheService from '~/services/CacheService'
import CookiesService from '~/services/CookiesService'
import onRequest from './axios/on-request'
import onResponse from './axios/on-response'

export default function({ $axios, route, req, app, res }: Context) {
  $axios.onRequest(config =>
    onRequest(
      config,
      route,
      req,
      app.$requestContainer.resolve(CacheService),
      app.$requestContainer.resolve(CookiesService)
    )
  )
  $axios.onResponse(axiosResponse =>
    onResponse(axiosResponse, app.$requestContainer.resolve(CacheService), res)
  )
}
