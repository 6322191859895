export enum Permission {
  ADMIN_REPORTS = 'admin.reports',
  ADMIN_CATEGORIES_EDIT = 'admin.categories.edit',
  ADMIN_BLACKLIST = 'admin.blacklist',
  ADMIN_INVOICES_SEE = 'admin.invoices.see',
  ADMIN_ANNOUNCEMENTS = 'admin.announcements',
  CLASSIFIED_PROMOTIONS = 'classifieds_promotions',
  ADMIN_MANAGE_INHOUSE_ADS = 'admin_manage_in_house_ads',
  MASS_ACTIONS = 'classifieds.mass_actions'
}
