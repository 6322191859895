import { inject } from 'tsyringe'
import { IBuilder } from '~/builders/internal/IBuilder'
import { injectable } from '~/decorators/dependency-container'
import { UrlQueryParamSetter, ArrayParamHandling } from '~/models/url'
import UrlHistoryService from '~/services/url/history/UrlHistoryService'

@injectable()
export class UrlQueryParamSetterBuilder
  implements IBuilder<UrlQueryParamSetter> {
  private keyScope_: string[] | undefined
  private arrayParamHandling_: ArrayParamHandling =
    ArrayParamHandling.PARAM_PER_ELEMENT

  constructor(
    @inject(UrlHistoryService) private urlHistoryService: UrlHistoryService
  ) {}

  keyScope(value: string[]) {
    this.keyScope_ = value
    return this
  }

  arrayParamHandling(value: ArrayParamHandling) {
    this.arrayParamHandling_ = value
    return this
  }

  build(): UrlQueryParamSetter {
    const { keyScope_, arrayParamHandling_ } = this
    if (!keyScope_) {
      throw new TypeError('undefined key-scope')
    }

    return this.urlHistoryService.queryParamSetter({
      keyScope: keyScope_!,
      arrayParamHandling: arrayParamHandling_
    })
  }
}
