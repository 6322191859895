import { Search } from '~/router/routes/car/classifieds/search/components'
import { allVTypesRegex } from '~/constants/classified/vehicle'

export const vehiclesRoutes = [
  {
    name: '__classifieds_search_used_vtype',
    path: '/used-:vType/*',
    component: Search,
    pathToRegexpOptions: { strict: true },
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_leasing_vtype',
    path: '/leasing-:vType/*',
    component: Search,
    pathToRegexpOptions: { strict: true }
  },
  {
    name: '__classifieds_search_leasing_vtype_without_subpath',
    path: '/leasing-:vType/',
    component: Search,
    pathToRegexpOptions: { strict: true }
  },
  {
    name: '__classifieds_search_xrimatodotisi_vtype',
    path: '/xrimatodotisi-:vType/*',
    component: Search,
    pathToRegexpOptions: { strict: true }
  },
  {
    name: '__classifieds_search_xrimatodotisi_vtype_without_subpath',
    path: '/xrimatodotisi-:vType/',
    component: Search,
    pathToRegexpOptions: { strict: true }
  },
  {
    name: '__classifieds_search_classifieds_vtype',
    path: '/classifieds/:vType/',
    component: Search,
    pathToRegexpOptions: { strict: true },
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: `__classifieds_search_vtype`,
    path: `/:vType(${allVTypesRegex})/`,
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
