import { AuditShop } from '~/models/audit/shop'
import {
  DynamicFormFieldValues,
  DynamicFormSchema
} from '~/models/dynamic-form/schema'

export const ACCOUNT_AUDITS_NS = 'account/audits'
export interface AccountAuditsState {
  auditShop: AuditShop | null
  auditShopFieldValues: DynamicFormFieldValues
  auditShopSchema: DynamicFormSchema | null
}

function getDefaultState(): AccountAuditsState {
  return {
    auditShop: null,
    auditShopFieldValues: {},
    auditShopSchema: null
  }
}

export default getDefaultState
