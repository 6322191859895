










import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { Size } from '~/models/app/size'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.MD
    },
    roundnessClass: {
      type: String,
      default: 'tw-rounded-full'
    },
    withAction: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { size, roundnessClass, withAction } = toRefs(props)

    const sizeClasses = computed(() => {
      switch (size.value) {
        case Size.SM: {
          return ['tw-text-sm']
        }
        case Size.LG: {
          return ['tw-text-lg']
        }
        case Size.MD:
        default: {
          return ['tw-text-base']
        }
      }
    })
    let withActionClass = null

    if (withAction.value) {
      withActionClass = 'tw-cursor-pointer'
    }
    const classes = computed(() => [
      ...sizeClasses.value,
      roundnessClass.value,
      withActionClass
    ])

    return { classes }
  }
})
