import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { Store } from 'vuex'
import carHeadNuxtConfig from '~/config/apps/car/head.js'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { Layout, Owner } from '~/models/dealer/site/types'
import { namedCssColorToHexMap } from '~/models/shared/color'
import { StringMap } from '~/models/shared/types'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { convertHexToRgb } from '~/utils/color'
import { StoreWithRootState } from '~/store/types'
import { toCamelCase } from '~/utils/object'
import { capitalizeString } from '~/utils/string'
import { invalidBodyError } from '../../errors'
import { Head } from '~/models/head/types'

@containerScoped()
export default class DealerSiteLayoutService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService,
    @inject(Store) private store: StoreWithRootState
  ) {}

  async getLayout(websiteId: string): Promise<Layout> {
    const { data: body } = await this.http.get(
      `/api/dsite/${websiteId}/layout/`
    )

    if (
      !body ||
      !body.data ||
      !body.data.pages ||
      !body.data.template ||
      !body.data.owner
    ) {
      throw invalidBodyError(body)
    }

    let indexPageId: string | undefined
    const pageSnippets: StringMap<PageSnippet> = new Map(
      body.data.pages.map((page: any) => {
        const id = page.id && page.id.toString()
        if (page.is_index) {
          indexPageId = id
        }
        return [id, this.dealerSiteService.formatPageSnippet(page)]
      })
    )

    const { owner, template, analytics } = body.data
    return {
      title: body._page && body._page.title,
      pageSnippets,
      indexPageId,
      owner: this.formatOwner(owner),
      template,
      analytics: toCamelCase(analytics)
    }
  }

  convertColorToRgb(color: string): string {
    let c = color
    if (c.slice(0, 4) === 'rgba') {
      // start after parens, end before closing parens
      return c.slice(5, c.length - 1)
    } else if (c.slice(0, 3) === 'rgb') {
      // start after parens, end before closing parens
      return c.slice(4, c.length - 1)
    } else if (Object.keys(namedCssColorToHexMap).includes(c)) {
      c = namedCssColorToHexMap[c]
    }

    return convertHexToRgb(c).join(',')
  }

  private formatOwner(o: any): Owner {
    const userSince = o.user_since
    const displayName = o.display_name
    delete o.user_since
    delete o.display_name
    return {
      ...o,
      id: o.id && o.id.toString(),
      userSince,
      displayName
    }
  }

  imageUrlShouldBeLoaded(url?: string) {
    return url && url !== 'none'
  }

  setPageTitle(head: Head, title: string): Head {
    const { title: dealerSiteBaseTitle, owner } = this.store.state.dealers.site
    const baseTitle = carHeadNuxtConfig.titleTemplate.split(' - ')[0]
    const parts: string[] = []
    parts.push(title)
    const dealerPart = dealerSiteBaseTitle.trim() || owner?.displayName
    dealerPart && parts.push(capitalizeString(dealerPart))
    parts.push(baseTitle)
    head.title = parts.join(' - ')
    head.titleTemplate = '%s'

    return head
  }
}
