





import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props) {
    const { direction } = toRefs(props)

    const classes = computed(() => {
      switch (direction.value) {
        case 'vertical':
        default: {
          return ['tw-flex-col', 'tw-space-y-3', 'md:tw-space-y-1']
        }
        case 'horizontal': {
          return ['tw-flex-row', 'tw-space-x-3']
        }
      }
    })

    return { classes }
  }
})
