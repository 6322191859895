import { Search } from '~/router/routes/car/classifieds/search/components'

export const userProfileRoutes = [
  {
    name: '__classifieds_search_user_profile',
    path: '/user/:userId/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
