







































































import Vue, { PropType } from 'vue'
import { Classified } from '~/models/classified/types'
import CClassifiedThumbnail from '~/components/shared/configurable/classified/image/CClassifiedThumbnail.vue'
import CLiveDate from '~/components/shared/configurable/CLiveDate.vue'
import DiscountPercentageTag from '~/components/car/classifieds/DiscountPercentageTag.vue'
import CPrice from '~/components/shared/configurable/CPrice.vue'
interface Data {
  testModified: Date
}

export default Vue.extend({
  components: {
    CClassifiedThumbnail,
    CLiveDate,
    DiscountPercentageTag,
    CPrice
  },
  props: {
    classified: {
      type: Object as PropType<Classified>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    inRecents: {
      type: Boolean,
      required: false,
      default: false
    },
    rowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    linkDraggable: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: null
    },
    useRouter: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data(): Data {
    return {
      testModified: new Date().getTime()
    }
  },
  computed: {
    classifiedURL() {
      return this.classified.seo_url || `/${this.classified.id}`
    },
    lastModified() {
      const test = this.classified.id === 12073974

      if (test) {
        return this.testModified
      }

      return this.classified.modified
    },
    isYoungClassified() {
      const halfMinute = 30 * 1000 // ms
      return new Date() - this.lastModified < halfMinute
    },
    mainPrice() {
      if (this.classified.price) {
        return this.classified.price
      }
      if (this.classified.payment) {
        if (this.classified.payment.label) {
          return (
            this.classified.payment.value + ' ' + this.classified.payment.label
          )
        }
        return this.classified.payment.value
      }

      if (this.classified.salary) {
        if (this.classified.salary.label) {
          return (
            this.classified.salary.value + ' ' + this.classified.salary.label
          )
        }
        return this.classified.salary.value
      }

      return null
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    triggerThumbClick(e) {
      if (this.showLink) {
        this.$refs.thumb.imgClicked(e)
      }
    },
    classifiedClicked(evt, refData = {}) {
      // remove this hack when https://github.com/vuejs/vue-router/issues/703 is resolved

      this.$analytics.recordEvent({
        namespace: this.inRecents
          ? 'n_index_feed_recent_classified_click'
          : 'n_index_feed_classified_click',
        action: 'click',
        label: this.classified.title + ' (' + this.classified.id + ')'
      })

      if (evt) {
        evt.preventDefault()
        if (evt.ctrlKey) {
          window.open(this.classifiedURL)
          return
        }
      }

      if (!this.useRouter) {
        window.location.href = this.classifiedURL
        return
      }

      const refs = {
        categoryIds: refData.categoryIds || this.classified.category_ids,
        id: refData.id || this.classified.id,
        isParked: refData.isParked || null,
        thumb: refData.thumb || this.classified.thumb,
        title: refData.title || this.classified.title,
        price: refData.price || this.classified.price,
        salary: refData.salary || this.classified.salary,
        payment: refData.payment || this.classified.payment,
        thumbIndex: refData.thumbIndex || 0,
        offertype: refData.offertype || this.classified.offertype,
        hasPhotos: refData.hasPhotos || this.classified.has_photos,
        thumbsLength:
          refData.thumbsLength || (this.classified.has_photos ? 1 : 0),
        externalLink:
          refData.externalLink || this.classified.external_link || null
      }

      this.$store.commit('classifieds/view/SET_REFS', refs)

      return this.$router.push({
        path: this.classifiedURL
      })
    }
  }
})
