





import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { VariantType } from '~/models/app/variant'

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<VariantType>,
      default: null
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const rowClasses = computed(() => {
      function variantClasses() {
        if (!props.variant) {
          return []
        }
        return [props.variant]
      }
      return [...variantClasses()]
    })

    const handleClick = () => {
      if (!props.clickable) return
      emit('click')
    }

    return { rowClasses, handleClick }
  }
})
