import { Search } from '~/router/routes/car/classifieds/search/components'

export const jobsRoutes = [
  {
    name: '__classifieds_search_jobs',
    path: '/jobs/',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  },
  {
    name: '__classifieds_search_jobs_seo',
    path: '/jobs/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
