import { inject } from 'tsyringe'
import VueI18n from 'vue-i18n'
import { Action } from '~/components/shared/configurable/snackbar/types'
import { containerScoped } from '~/decorators/dependency-container'
import { Undoable } from '~/models/undoable'

@containerScoped()
export class SnackbarActionFactory {
  constructor(@inject(VueI18n) private i18n: VueI18n) {}

  undoAction(undoable: Undoable): Action {
    return {
      callback: async () => {
        await undoable.undo()
      },
      color: 'secondary',
      text: this.i18n.t('undo') as string
    }
  }
}

export const undoAction = (undoable: Undoable) => (
  actionFactory: SnackbarActionFactory
) => actionFactory.undoAction(undoable)
