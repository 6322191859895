










import Vue from 'vue'
import { ciPauseCircle } from '~/icons/source/regular/pause-circle'
import { ciPlayCircle } from '~/icons/source/regular/play-circle'

export default Vue.extend({
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    icons() {
      return {
        play: ciPlayCircle,
        pause: ciPauseCircle
      }
    }
  }
})
