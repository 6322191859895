import { CompactPage, Consent } from '~/models/common/types'
import {
  Classified,
  ClassifiedAuditsInfo,
  Seller
} from '~/models/classified/types'
import { AdsTargeting, BackendAdsConfig } from '~/models/ads'
import { LeasingOption } from '~/models/classified/leasing/types'
import { ProcessedPhoto } from '~/models/classified/photo'
import {
  CurrentUser,
  ContactFormData,
  MessageStats
} from '~/models/classified/view'
import { Promotion } from '~/models/classified/promoted'
import { DeletedInfoLink } from '~/models/classified/view/deleted'
import { ClassifiedNote } from '~/models/classified/note'
import { ClassifiedRentalInfo } from '~/models/rentals/types'
import { ClassifiedViewVariant } from '~/models/classified/view/variant'
import { ClassifiedOfferEntry } from '~/models/classified/offers'
import { AuditRequest } from '~/models/audit/request'

export const CLASSIFIED_VIEW_NS = 'classifieds/view'

export interface ClassifiedViewState {
  page?: CompactPage
  consent?: Consent
  classified?: Classified
  classifiedId?: number
  currentUser: CurrentUser | null
  seller: Seller | null
  bigPhotosVisible: boolean | string
  youtubeVisible: boolean
  showContact: boolean
  showContactPrompt: boolean
  relatedLinks: DeletedInfoLink[]
  notes: ClassifiedNote | null
  // TODO: type these later if possible
  refs: any
  contactSchema: any
  messageStats: MessageStats | null
  contactFormData: ContactFormData
  paidClassified: any
  paidFeatures: {
    isPaidOneClickAutoTouchEnabled: boolean
    isPromotionNnabled: boolean
  }
  extra: any
  adsTargetings: AdsTargeting[]
  hasUnreadMessages: boolean
  debugShimmer: boolean
  promotion?: Promotion
  hidePromotion: boolean
  installments: {
    numberOfInstallments: number | null
    installmentAmount: number | null
    downpayment: number | null
  }
  leasing: {
    option: LeasingOption | null
    duration: string | null
    withVat: boolean
    availableDurations: string[]
  }
  processedPhotos: ProcessedPhoto[]
  ads: BackendAdsConfig
  isFloatingButtonsMessagePhoneVisible: boolean
  rental: ClassifiedRentalInfo | null
  contactFormExtraText: string
  variant: ClassifiedViewVariant
  classifiedOffers: ClassifiedOfferEntry[]
  offerCount: number | null
  hasOfferForClassified: boolean
  audits?: ClassifiedAuditsInfo
  auditRequests: AuditRequest[] | null
  seoUrl: string
  insurance: {
    available: boolean
  }
}
function getDefaultState(): ClassifiedViewState {
  return {
    page: {
      breadcrumbs: [],
      metadata: [],
      seoUrl: '',
      title: '',
      externalUrl: '',
      targetSeoUrl: ''
    },
    consent: undefined,
    classified: undefined,
    classifiedId: undefined,
    refs: null,
    currentUser: null,
    seller: null,
    bigPhotosVisible: false,
    youtubeVisible: false,
    showContact: true,
    showContactPrompt: false,
    contactSchema: null,
    messageStats: null,
    contactFormData: {
      dealers: [],
      senderId: null,
      email: '',
      name: '',
      body: '',
      success: false
    },
    relatedLinks: [],
    notes: null,
    paidClassified: null,
    paidFeatures: {
      isPaidOneClickAutoTouchEnabled: false,
      isPromotionNnabled: false
    },
    extra: null,
    adsTargetings: [],
    ads: {
      disabled: false
    },
    hasUnreadMessages: false,
    debugShimmer: false,
    promotion: undefined,
    hidePromotion: false,
    installments: {
      numberOfInstallments: null,
      installmentAmount: null,
      downpayment: null
    },
    leasing: {
      option: null,
      duration: null,
      withVat: false,
      availableDurations: []
    },
    processedPhotos: [],
    isFloatingButtonsMessagePhoneVisible: false,
    rental: null,
    contactFormExtraText: '',
    variant: ClassifiedViewVariant.DEFAULT,
    offerCount: null,
    classifiedOffers: [],
    hasOfferForClassified: false,
    auditRequests: null,
    seoUrl: '',
    insurance: {
      available: false
    }
  }
}

export default getDefaultState
