

















































import Vue from 'vue'
import COpenStreetMap from '~/components/shared/configurable/map/COpenStreetMap.vue'
import CGoogleAutocompleteInput from '~/components/shared/configurable/form/autocomplete/CGoogleAutocompleteInput.vue'
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { faIconToSvg } from '~/utils/icon'
import { FullLocation } from '~/models/google'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'

interface Data {
  modalVisible: boolean
  city: string
  radius: number
  coordinates: object
  modalSaved: boolean
  postcode: number | string
}

const radiusToZoom = [
  { metersOnly: true, radius: 500, zoom: 15 },
  { radius: 1000, zoom: 14 },
  { radius: 2000, zoom: 13 },
  { radius: 5000, zoom: 11 },
  { radius: 10000, zoom: 10 },
  { radius: 25000, zoom: 9 },
  { radius: 50000, zoom: 8 },
  { radius: 100000, zoom: 7 },
  { radius: 250000, zoom: 6 },
  { radius: 400000, zoom: 5 },
  { radius: 500000, zoom: 5 },
  { radius: 600000, zoom: 1 },
  { radius: 800000, zoom: 2 }
]

export default Vue.extend({
  components: {
    COpenStreetMap,
    CGoogleAutocompleteInput
  },
  props: {
    defaultCity: { type: String, required: false, default: '' },
    defaultCoordinates: { type: Object, required: true },
    defaultRadius: { type: Number, required: false, default: 1000 },
    defaultPostcode: { type: [String, Number], required: false, default: null }
  },
  data(): Data {
    return {
      modalVisible: false,
      city: this.defaultCity,
      radius: this.defaultRadius,
      coordinates: this.defaultCoordinates,
      modalSaved: false,
      postcode: this.defaultPostcode
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    radiusOptions() {
      return radiusToZoom.map(r => {
        if (r.metersOnly) {
          return {
            text: r.radius + this.$t('m').toLowerCase(),
            value: r.radius
          }
        }
        return {
          text: r.radius / 1000 + this.$t('km').toLowerCase(),
          value: r.radius
        }
      })
    },
    zoom() {
      return this.getZoom(this.radius)
    },
    mapButtons() {
      return [
        {
          title: this.$t('my place'),
          position: 'topright',
          event: 'clicked:btnLocation',
          styles: {
            color: 'rgb(104, 104, 104)',
            backgroundColor: 'rgb(255, 255, 255)',
            lineHeight: '32px',
            fontSize: '16px',
            cursor: 'pointer',
            marginRight: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 1px'
          },
          html: faIconToSvg(faCrosshairs),
          tooltip: 'tooltip',
          callback: this.mapLocationClick
        }
      ]
    }
  },
  watch: {
    defaultCoordinates(c) {
      this.coordinates = c
    }
  },
  methods: {
    invalidateMapSize() {
      this.$refs.map.invalidateSize()
    },
    showModal() {
      this.modalVisible = true
      this.modalSaved = false
    },
    hideModal() {
      this.modalVisible = false
    },
    saveChoice() {
      if (this.city) {
        const loc = {
          city: this.city,
          radius: this.radius,
          coordinates: this.coordinates
        }

        if (this.postcode) {
          loc.postcode = this.postcode
        }

        this.$emit('location-set', loc)
        this.modalSaved = true
      }
    },
    modalCancel() {
      if (!this.modalSaved) {
        // re initialize the vars
        this.city = this.defaultCity
        this.radius = this.defaultRadius
        this.coordinates = this.defaultCoordinates
      }
    },
    handleAutocompleteInputChange({
      geolocation,
      postcode,
      city
    }: FullLocation) {
      this.postcode = postcode
      this.coordinates = geolocation
      this.city = city
    },
    getZoom(r) {
      for (const i in radiusToZoom) {
        if (radiusToZoom[i].radius === r) {
          return radiusToZoom[i].zoom
        }
      }

      return 14
    },
    mapLocationClick() {
      this.$refs.locator.getCurrentGpsLocation()
    },
    onModalClose() {
      this.hideModal()
    }
  }
})
