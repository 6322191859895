




































































import { Size } from '~/models/shared/types'
import { ButtonVariant, ButtonVariantType } from '~/models/app/variant'
import {
  defineComponent,
  computed,
  toRefs,
  PropType,
  ref,
  watch
} from '@nuxtjs/composition-api'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import { useButtonClasses } from '~/compositions/button/classes'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'

export default defineComponent({
  components: { CDynamicIcon },
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM,
      required: false
    },
    variant: {
      type: String as PropType<ButtonVariantType>,
      default: undefined
    },
    squared: { type: Boolean, default: false },
    circle: { type: Boolean, default: false },
    pill: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    pressed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    tag: { type: String, default: 'button' },
    href: { type: String, default: null },
    to: { type: [Object, String], default: null },
    target: { type: String, default: '_self' },
    title: { type: String, default: null },
    loading: { type: Boolean, default: false },
    icon: { type: Object, default: () => null },
    spinnerVariantProp: {
      type: String as PropType<ButtonVariantType>,
      default: undefined
    },
    floating: { type: Boolean, default: false },
    disabledHover: { type: Boolean, default: false },
    containerClasses: { type: String, required: false, default: '' },
    attributeValue: { type: String, default: undefined },
    type: { type: String, default: 'button' }
  },
  setup(props, { emit }) {
    const {
      href,
      to,
      variant,
      pressed,
      size: sizeProp,
      disabled,
      tag,
      loading,
      spinnerVariantProp,
      floating,
      pill,
      squared,
      circle,
      disabledHover
    } = toRefs(props)
    const isLink = computed(() => Boolean(href.value || to.value))
    const size = ref(sizeProp.value)
    const {
      sizeClasses,
      variantClasses,
      linkClasses,
      positionClasses,
      roundnessClasses,
      spinnerClasses
    } = useButtonClasses(
      size,
      disabled,
      loading,
      variant,
      pressed,
      isLink,
      floating,
      pill,
      squared,
      circle,
      disabledHover
    )

    watch(sizeProp, () => {
      setSize(sizeProp.value)
    })
    const componentType = computed(() => {
      if (isLink.value) {
        return CLink
      }
      return tag.value
    })

    const role = computed(() => {
      if (isLink.value) {
        return 'button'
      }
      return null
    })
    const spinnerVariant = computed(() => {
      if (spinnerVariantProp.value) {
        return spinnerVariantProp.value
      }
      if (
        [
          ButtonVariant.BLUE_DARK,
          ButtonVariant.BLUE,
          ButtonVariant.PRIMARY,
          ButtonVariant.SUCCESS,
          ButtonVariant.WARNING,
          ButtonVariant.DANGER,
          ButtonVariant.INFO,
          ButtonVariant.TEAL,
          ButtonVariant.BUY_IT,
          ButtonVariant.SECONDARY,
          ButtonVariant.TOP,
          ButtonVariant.SUPER,
          ButtonVariant.ORANGE,
          ButtonVariant.TURBO
        ].includes(variant.value) ||
        !variant.value
      ) {
        return ButtonVariant.WHITE
      } else if (variant.value === ButtonVariant.OUTLINE_SUCCESS) {
        return ButtonVariant.SUCCESS
      } else if (variant.value === ButtonVariant.OUTLINE_DANGER) {
        return ButtonVariant.DANGER
      } else if (variant.value === ButtonVariant.OUTLINE_WARNING) {
        return ButtonVariant.WARNING
      } else if (variant.value === ButtonVariant.OUTLINE_SECONDARY) {
        return ButtonVariant.SECONDARY
      } else if (variant.value === ButtonVariant.OUTLINE_INFO) {
        return ButtonVariant.TEAL
      } else if (variant.value === ButtonVariant.LIGHT) {
        return ButtonVariant.SECONDARY
      } else if (variant.value === ButtonVariant.OUTLINE_LIGHT) {
        return ButtonVariant.LIGHT
      } else if (variant.value === ButtonVariant.OUTLINE_BLUE) {
        return ButtonVariant.BLUE
      } else if (variant.value === ButtonVariant.OUTLINE_TEAL) {
        return ButtonVariant.TEAL
      } else if (variant.value === ButtonVariant.OUTLINE_ORANGE) {
        return ButtonVariant.ORANGE
      }
    })

    const spinnerSize = computed(() => {
      if (size.value === Size.SMALL) {
        return Size.EXTRA_SMALL
      } else if (size.value === Size.EXTRA_SMALL) {
        return Size.EXTRA_SMALL
      }
      return Size.SMALL
    })

    const setSize = (newSize: Size) => {
      size.value = newSize
    }
    const handleClick = (e: Event) => {
      if (!disabled.value) {
        emit('click', e)
      }
    }

    return {
      componentType,
      variantClasses,
      sizeClasses,
      linkClasses,
      role,
      isLink,
      spinnerSize,
      spinnerVariant,
      positionClasses,
      roundnessClasses,
      spinnerClasses,
      setSize,
      handleClick
    }
  }
})
