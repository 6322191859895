import VueRouter from 'vue-router'
import { AxiosInstance, AxiosResponse } from 'axios'
import { scoped, Lifecycle, inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { invalidBodyError } from '~/services/errors'
import qs from 'qs'
import {
  DocData,
  ExternalCertificates,
  ExternalCertificatesDoc,
  ExternalCertificatesAuditCategories,
  ValueLabel,
  ExternalCertificatesFieldPositions,
  ExternalCertificatesCategoryField,
  ExternalCertificatesGeneralInfoForm,
  ExternalCertificatesDocuments,
  ExternalCertificatesAuditCategory,
  ExternalCertificatesSchema,
  ExternalCertificatesGeneralInfo,
  CertificateState,
  CertificateAttachment,
  ExternalCertifcatesAvailablePositions,
  CommentState,
  CertificateReceiptResult,
  ExternalCertificatesCategorySpec
} from '~/models/external-certificates/types'
import {
  toCamelCase,
  toCamel,
  removeKeysWithNoValues,
  deepJsonCopy
} from '~/utils/object'
import { camelToUnderscore, toSnakeCase } from '~/utils/snake-case'
import { format, toDate } from '~/utils/date'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { getRandomNumberBetween } from '~/utils/number'
import { CategoryId } from '~/models/category/types'

export const GOOD_STATE = 'good'
export const EXTERNAL_CERTIFICATES_COOKIE_NAME = 'ext-cert-positions'
const DATE_FORMAT = 'yyyy-MM-dd'
@scoped(Lifecycle.ContainerScoped)
export default class ClassifiedExternalCertificatesService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(VueRouter) private router: VueRouter,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  async fetchExternalCertificates(
    classifiedId: number
  ): Promise<ExternalCertificates> {
    const { query } = this.router.currentRoute
    const test = ['true', '1'].includes(query._test as string)
      ? '?_test=true'
      : ''
    const response: AxiosResponse = await this.http.get(
      `/api/vehicles/certificates/external/${classifiedId}/${test}`
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return body.data
  }

  async fetchExternalCertificatesDocs(): Promise<ExternalCertificatesDoc> {
    const response: AxiosResponse = await this.http.get(
      '/api/vehicles/certificates/external/docs/'
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    const {
      documents,
      general_info: generalInfo,
      audit_categories: auditCategories,
      report_id: reportId,
      general_comments: generalComments
    } = body.data
    return {
      reportId,
      auditCategories,
      documents: this.convertObjectDataToArray(documents),
      generalInfo: this.convertObjectDataToArray(generalInfo),
      topLevelData: this.getTopLevelData(body.data),
      generalComments
    }
  }

  async fetchExternalCertificateForm(
    reportId?: string,
    category?: CategoryId | string | null
  ) {
    return await this.requestBuilder
      .request(
        'get',
        reportId
          ? `/api/vehicles/certificates/external/${reportId}/edit/`
          : '/api/vehicles/certificates/external/add/'
      )
      .params({ category })
      .validate(body => body?.data)
      .map(body => this.normalizedFormData(toCamelCase(body)))
      .send()
  }

  normalizedFormData(data: {
    data: {
      schema: ExternalCertificatesSchema
      certificate: {
        details: ExternalCertificates
        auditRequest: number
        finalized: boolean
        attachments: [{ attachments: [] }]
      }
      specs: {
        order: string[]
        category: ExternalCertificatesCategorySpec
      }
    }
  }) {
    const labels = {} as { [key: string]: string }
    const { certificate } = data.data
    const { order, category } = data.data.specs
    const { auditCategories } = data.data.schema

    Object.keys(auditCategories).forEach(categoryKey => {
      const key = toCamel(
        categoryKey
      ) as keyof ExternalCertificatesAuditCategories
      labels[key] = auditCategories[
        categoryKey as keyof ExternalCertificatesAuditCategories
      ]?.state?.categoryLabel as string
      Object.keys(
        auditCategories[key as keyof ExternalCertificatesAuditCategories]
      ).forEach(acKey => {
        if (auditCategories[key][acKey].availablePositions) {
          const firstKey = Object.keys(
            auditCategories[key][acKey].availablePositions
          )[0]
          auditCategories[key][acKey] = {
            ...auditCategories[key][acKey],
            position: firstKey as keyof ExternalCertifcatesAvailablePositions
          }
        } else {
          auditCategories[key][acKey] = {
            ...auditCategories[key][acKey]
          }
        }
      })
    })
    const camelCaseData = {
      ...data.data.schema,
      labels,
      auditRequest: {
        ...data.data.schema.auditRequest?.auditRequest,
        selected: certificate?.auditRequest
      },
      finalized: !!certificate?.finalized,
      auditCategories: this.normalizeAuditCategories(
        data.data.schema.auditCategories,
        certificate
      ),
      documents: this.normalizeDocuments(
        data.data.schema.documents,
        certificate
      ),
      generalInfo: this.normalizeGeneralInfo(
        data.data.schema.generalInfo,
        certificate
      ),
      generalComments: {
        ...data.data.schema.generalComments.generalComments,
        value: certificate?.details?.generalComments || null
      },
      synopsis: data.data.schema.synopsis
        ? {
            ...data.data.schema.synopsis.synopsis,
            value: certificate?.details?.synopsis?.value || null
          }
        : null,
      reportId: {
        ...data.data.schema.reportId.reportId,
        value: certificate?.details?.reportId || this.getRandomReportId(),
        disabled: !!certificate?.details?.reportId
      },
      attachments: {
        ...data.data.schema.attachments.attachments,
        files: certificate?.attachments[0]?.attachments
      },
      order: order.map(o => toCamel(o)),
      category
    }
    return camelCaseData
  }

  getRandomReportId(): number {
    return getRandomNumberBetween(1000000000, 1999999999)
  }

  normalizeAuditCategories(
    auditCategories: ExternalCertificatesAuditCategories,
    certificate: {
      details: { auditCategories: Array<ExternalCertificatesAuditCategory> }
    }
  ) {
    const certificateAuditCategories = {
      ...auditCategories
    } as ExternalCertificatesAuditCategories
    // const
    const auditCategoriesKeys = [] as Array<string>
    const auditSubCategoriesKeys = {} as { [key: string]: Array<string> }
    if (certificate?.details) {
      certificate.details.auditCategories.forEach(category => {
        const capitalizedCategory = toCamel(category.name as string)
        auditCategoriesKeys.push(capitalizedCategory)
        certificateAuditCategories[
          capitalizedCategory as keyof ExternalCertificatesAuditCategories
        ] = {
          ...auditCategories[
            capitalizedCategory as keyof ExternalCertificatesAuditCategories
          ],
          state: {
            ...auditCategories[
              capitalizedCategory as keyof ExternalCertificatesAuditCategories
            ].state,
            value: toCamel(category.state)
          },
          comment: {
            ...auditCategories[
              capitalizedCategory as keyof ExternalCertificatesAuditCategories
            ].comment,
            value: category.comment
          }
        }
        auditSubCategoriesKeys[capitalizedCategory] = []
        ;(category.value as []).forEach(
          (val: ExternalCertificatesAuditCategory) => {
            const valueName = toCamel(val.name as string)
            auditSubCategoriesKeys[capitalizedCategory].push(valueName)
            if (
              auditCategories[
                capitalizedCategory as keyof ExternalCertificatesAuditCategories
              ][valueName]?.availablePositions
            ) {
              certificateAuditCategories[
                capitalizedCategory as keyof ExternalCertificatesAuditCategories
              ][valueName] = {
                ...auditCategories[
                  capitalizedCategory as keyof ExternalCertificatesAuditCategories
                ][valueName]
              } as any
              ;(val.value as []).forEach(
                (position: ExternalCertificatesAuditCategory) => {
                  if (
                    !certificateAuditCategories[
                      capitalizedCategory as keyof ExternalCertificatesAuditCategories
                    ][valueName].positions
                  ) {
                    certificateAuditCategories[
                      capitalizedCategory as keyof ExternalCertificatesAuditCategories
                    ][valueName].positions = {}
                  }

                  // @ts-ignore
                  certificateAuditCategories[
                    capitalizedCategory as keyof ExternalCertificatesAuditCategories
                  ][valueName].positions[
                    position.position as keyof ExternalCertificatesFieldPositions
                  ] = {
                    comment: position.comment,
                    state: toCamel(position.state)
                  }
                }
              )
            } else {
              const stateValue = val.value as CommentState
              certificateAuditCategories[
                capitalizedCategory as keyof ExternalCertificatesAuditCategories
              ][valueName] = {
                ...auditCategories[
                  capitalizedCategory as keyof ExternalCertificatesAuditCategories
                ][valueName],
                ...{
                  ...val.value,
                  state: stateValue
                    ? toCamel(stateValue?.state as string)
                    : undefined
                }
              }
            }
          }
        )
      })
    }
    if (certificate?.details) {
      this.setNotCheckedState(
        auditCategories,
        auditCategoriesKeys,
        certificateAuditCategories,
        auditSubCategoriesKeys
      )
    }

    return certificateAuditCategories
  }

  private setNotCheckedState(
    auditCategories: ExternalCertificatesAuditCategories,
    auditCategoriesKeys: Array<string>,
    certificateAuditCategories: ExternalCertificatesAuditCategories,
    auditSubCategoriesKeys: { [key: string]: Array<string> }
  ) {
    Object.keys(auditCategories).forEach(c => {
      if (!auditCategoriesKeys.includes(c)) {
        certificateAuditCategories[
          c as keyof ExternalCertificatesAuditCategories
        ].state.value = CertificateState.NOT_CHECKED
      } else {
        Object.keys(
          auditCategories[c as keyof ExternalCertificatesAuditCategories]
        ).forEach(subCat => {
          if (!auditSubCategoriesKeys[c]?.includes(subCat)) {
            certificateAuditCategories[
              c as keyof ExternalCertificatesAuditCategories
            ][subCat].state = CertificateState.NOT_CHECKED
          }
        })
      }
    })
  }

  normalizeDocuments(
    schemaDocuments: ExternalCertificatesDocuments,
    certificate: {
      details: { documents: ExternalCertificatesDocuments }
    }
  ) {
    let certificateDocuments = {}
    if (certificate?.details?.documents) {
      const schemaKeys = Object.keys(schemaDocuments)
      certificateDocuments = Object.keys(certificate.details.documents)
        .filter(k => schemaKeys.includes(k))
        .reduce((obj, key) => {
          // @ts-ignore
          const keyTyped: keyof ExternalCertificatesDocuments = key
          const value: any = {
            ...certificate?.details?.documents[keyTyped],
            ...schemaDocuments[keyTyped]
          }
          if (value.type === 'date') {
            value.value = format(toDate(value.value), DATE_FORMAT)
          }
          return Object.assign(obj, {
            [keyTyped]: value
          })
        }, {})
    }
    return {
      ...schemaDocuments,
      ...certificateDocuments
    }
  }

  normalizeGeneralInfo(
    generalInfoData: ExternalCertificatesGeneralInfoForm,
    certificate: {
      details: { generalInfo: ExternalCertificatesGeneralInfo }
    }
  ) {
    let certificateGeneralInfo: ExternalCertificatesGeneralInfo | {} = {}
    let mileage = {
      audit: null,
      certifiedKm: null,
      lastKteoKm: null,
      lastServiceKm: null
    }
    if (certificate?.details?.generalInfo) {
      certificateGeneralInfo = deepJsonCopy(certificate.details.generalInfo)
    }
    if (certificate?.details?.generalInfo?.mileage) {
      mileage = {
        ...mileage,
        ...((certificateGeneralInfo as ExternalCertificatesGeneralInfo).mileage
          .value as {})
      }
    }
    const generalInfo = {
      ...generalInfoData,
      ...certificateGeneralInfo,
      date: {
        ...(generalInfoData as ExternalCertificatesGeneralInfoForm).date,
        value: format(
          toDate(
            (certificateGeneralInfo as ExternalCertificatesGeneralInfoForm).date
              ?.value || new Date()
          ),
          DATE_FORMAT
        )
      },
      firstRegistrationDate: {
        ...generalInfoData.firstRegistrationDate,
        greece:
          format(
            toDate(
              (certificateGeneralInfo as ExternalCertificatesGeneralInfoForm)
                ?.firstRegistrationDate?.value?.greece
            ),
            DATE_FORMAT
          ) || null,
        europe:
          format(
            toDate(
              (certificateGeneralInfo as ExternalCertificatesGeneralInfoForm)
                ?.firstRegistrationDate?.value?.europe
            ),
            DATE_FORMAT
          ) || null
      },
      mileage: {
        ...generalInfoData.mileage,
        ...mileage
      }
    }

    return generalInfo
  }

  async fetchAttachments(): Promise<any> {
    return await this.requestBuilder
      .request('get', '/api/vehicles/certificates/external/attachments/')
      .validate(body => body?.data)
      .map(body => toCamelCase(body))
      .send()
  }

  async uploadAttachment(file: Blob): Promise<CertificateAttachment> {
    const formData = new FormData()
    formData.append('file', file)
    return await this.requestBuilder
      .request('post', '/api/vehicles/certificates/external/attachments/')
      .data(formData)
      .validate(body => body?.data)
      .map(body => {
        return toCamelCase(body)
      })
      .send()
  }

  async uploadReceipt(
    file: Blob,
    requestId: number | string
  ): Promise<CertificateReceiptResult> {
    const formData = new FormData()
    formData.append('file', file)
    return await this.requestBuilder
      .request(
        'post',
        `/api/audits/audit-request/${requestId.toString()}/payment-receipt/`
      )
      .data(formData)
      .validate(body => body?.data)
      .send()
  }

  async submitExternalCertificateForm(
    auditCategories: ExternalCertificatesAuditCategories,
    reportId: ExternalCertificatesCategoryField,
    category: CategoryId | string = CategoryId.CARS,
    documents: {},
    generalInfo: ExternalCertificatesGeneralInfoForm,
    generalComments: ExternalCertificatesCategoryField,
    synopsis: ExternalCertificatesCategoryField,
    attachments: [],
    editMode: boolean = false,
    auditRequest: number,
    finalize: boolean,
    overwriteReport: boolean,
    isFirstDataSubmission: boolean,
    isGeneralCommentsStep: boolean,
    routePrefix: string
  ): Promise<{ reportId: number }> {
    let url = `/api/vehicles/certificates/external/add/?category=${category}`
    const isNew = !editMode && !isFirstDataSubmission && !overwriteReport
    if (!isNew) {
      url = `/api/vehicles/certificates/external/${this.getReportIdData(
        reportId
      )}/edit/?category=${category}`
    }
    const normalizedData = toSnakeCase({
      audit_categories: this.getNormalizedAuditCategoriesData(auditCategories),
      documents: this.getDocumentsData(documents),
      synopsis:
        (isGeneralCommentsStep && synopsis?.value) || synopsis?.value
          ? this.getSynopsisData(synopsis)
          : undefined,
      general_comments:
        (isGeneralCommentsStep && generalComments?.value) ||
        generalComments?.value
          ? this.getGeneralCommentsData(generalComments)
          : undefined,
      general_info: this.getGeneralInfoData(generalInfo),
      report_id: this.getReportIdData(reportId),
      attachments,
      audit_request: auditRequest,
      finalize
    })
    let response: AxiosResponse
    if (editMode || isFirstDataSubmission || overwriteReport) {
      response = await this.http.put(url, normalizedData)
    } else {
      response = await this.http.post(url, normalizedData)
    }
    const { data: body } = response
    if (!body.data) {
      throw invalidBodyError(body)
    }

    if (isNew) {
      // we are past new and into edit
      this.router.replace({
        name: `_${routePrefix}_account_audits_external_certificates_edit`,
        params: { reportId: body.data.certificate.report_id },
        query: { ...this.router.currentRoute.query, step: '1' }
      })
    }

    return { reportId: body.data.certificate.report_id }
  }

  getNormalizedAuditCategoriesData(
    auditCategories: ExternalCertificatesAuditCategories
  ) {
    const normalizedAuditCategoriesData: {
      [key: string]: { [key: string]: any }
    } = {}
    Object.keys(auditCategories).forEach(ac => {
      const category =
        auditCategories[ac as keyof ExternalCertificatesAuditCategories]
      const categoryKeys = Object.keys(
        auditCategories[ac as keyof ExternalCertificatesAuditCategories]
      )
      const stateAndComment: { [key: string]: {} } = {}
      categoryKeys.forEach((k: any) => {
        if (['state', 'comment'].includes(k)) {
          return
        }
        const field = category[k]
        if (field.position) {
          const statesAndComments = [] as Array<{
            comment: string | undefined | null
            label: string
            state: string
          }>
          field.availablePositions[field.position].forEach(pk => {
            if (
              field.positions &&
              (field.positions as ExternalCertificatesFieldPositions)[
                pk as keyof ExternalCertificatesFieldPositions
              ]
            ) {
              statesAndComments.push({
                comment:
                  field.positions?.[
                    pk as keyof ExternalCertificatesFieldPositions
                  ]?.comment,
                label: pk,
                state: camelToUnderscore(
                  field.positions?.[
                    pk as keyof ExternalCertificatesFieldPositions
                  ]?.state || GOOD_STATE
                )
              })
            } else {
              // the default values
              statesAndComments.push({
                comment: null,
                label: pk,
                state: camelToUnderscore(GOOD_STATE)
              })
            }
          })
          if (!normalizedAuditCategoriesData[ac]) {
            normalizedAuditCategoriesData[ac] = {}
          }
          normalizedAuditCategoriesData[ac][k] = statesAndComments
        } else if (!field.availablePositions) {
          stateAndComment[k] = {
            state: field.state
              ? camelToUnderscore(field.state as string)
              : GOOD_STATE,
            comment: field.comment || null
          }
          normalizedAuditCategoriesData[ac] = {
            ...normalizedAuditCategoriesData[ac],
            ...stateAndComment
          }
        }
      })
      normalizedAuditCategoriesData[ac] = {
        ...normalizedAuditCategoriesData[ac],
        state: category.state?.value
          ? camelToUnderscore(category.state.value as string)
          : GOOD_STATE,
        comment: category.comment?.value || null
      }
    })
    return normalizedAuditCategoriesData
  }

  getSynopsisData(synopsis: ExternalCertificatesCategoryField) {
    return synopsis?.value
  }

  getGeneralCommentsData(generalComments: ExternalCertificatesCategoryField) {
    return generalComments.value
  }

  getReportIdData(reportId: ExternalCertificatesCategoryField) {
    return reportId.value && parseInt(reportId.value as string, 10)
  }

  getDocumentsData(documents: {
    [key: string]: { value: string | boolean | Date }
  }) {
    const keys = Object.keys(documents)
    const documentsData: { [key: string]: string | boolean | Date } = {}
    keys.forEach(k => {
      if (k === 'kteo' && (documents[k].value as Date) instanceof Date) {
        documentsData[k] = format(documents[k].value, 'yyyy-MM-dd')
      } else {
        documentsData[k] = documents[k].value
      }
    })
    return documentsData
  }

  getGeneralInfoData = (generalInfo: ExternalCertificatesGeneralInfoForm) => {
    const generalInfoData: { [key: string]: string | Date | any } = {
      firstRegistrationDate: {},
      mileage: {}
    }
    const keys = Object.keys(generalInfo).filter(
      f => !['mileage', 'firstRegistrationDate'].includes(f)
    )

    keys.forEach(k => {
      generalInfoData[k] = generalInfo?.[
        k as keyof ExternalCertificatesGeneralInfoForm
      ]?.value as string
    })
    const firstRegistrationDate = {
      europe: generalInfo.firstRegistrationDate.europe,
      greece: generalInfo.firstRegistrationDate.greece
    }
    if (generalInfo.firstRegistrationDate.europe instanceof Date) {
      firstRegistrationDate.europe = format(
        generalInfo.firstRegistrationDate.europe,
        'yyyy-MM-dd'
      )
    }
    if (generalInfo.firstRegistrationDate.greece instanceof Date) {
      firstRegistrationDate.greece = format(
        generalInfo.firstRegistrationDate.greece,
        'yyyy-MM-dd'
      )
    }
    if (generalInfo.date?.value instanceof Date) {
      generalInfoData.date = format(generalInfo.date.value, 'yyyy-MM-dd')
    } else if (typeof generalInfo.date?.value !== 'string') {
      generalInfoData.date = null
    }

    generalInfoData.firstRegistrationDate = removeKeysWithNoValues(
      firstRegistrationDate,
      true
    )

    const mileageObject = {
      audit: generalInfo.mileage.audit,
      lastKteoKm: generalInfo.mileage.lastKteoKm,
      lastServiceKm: generalInfo.mileage.lastServiceKm,
      certifiedKm: null
    } as { certifiedKm: null | string }

    if (generalInfo.mileage.certifiedKm) {
      mileageObject.certifiedKm =
        'Τα χιλιομετρα έχουν ελεγχθεί και είναι πραγματικά'
    } else {
      mileageObject.certifiedKm = null
    }
    generalInfoData.mileage = removeKeysWithNoValues(mileageObject, true)
    return removeKeysWithNoValues(generalInfoData)
  }

  async checkReportId(reportId: number) {
    return await this.requestBuilder
      .request('get', `/api/audits/external-certificates/${reportId}/exists/`)
      .map(body => body)
      .send()
  }

  async validateExternalCertificate(data: {
    providerId: number
    reportId: string
    vinDigits: string
  }): Promise<boolean> {
    const queryString = qs.stringify(
      {
        provider_id: data.providerId,
        report_id: data.reportId,
        vin_digits: data.vinDigits
      },
      { addQueryPrefix: true }
    )
    const response: AxiosResponse = await this.http.get(
      '/api/vehicles/certificates/external/validate/' + queryString
    )
    const { data: body } = response
    if (!body?.message) {
      throw invalidBodyError(body)
    }
    return body.message === 'Report valid'
  }

  getTopLevelData(data: { [key: string]: object }): Array<ValueLabel> {
    const keys = Object.keys(data)
    const topLevelData: Array<ValueLabel> = []
    keys.forEach(k => {
      const dt = data[k] as { required: boolean; type: string }
      if (Array.isArray(dt)) {
        return topLevelData.push({ label: k, value: dt.map(d => d.name) })
      } else if (['general_comments', 'report_id'].includes(k)) {
        return topLevelData.push({
          label: k,
          value: [`required: ${dt.required}, type:${dt.type}`]
        })
      } else {
        return topLevelData.push({ label: k, value: Object.keys(dt) })
      }
    })
    return topLevelData
  }

  convertObjectDataToArray(obj: any): Array<DocData> {
    const keys: Array<string> = Object.keys(obj)
    const data: Array<DocData> = []
    keys.forEach(k => {
      data.push({
        ...obj[k],
        name: k
      })
    })
    return data
  }
}
