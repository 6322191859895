import { interopDefault } from '~/router/utils'
import { adminReportsRoutes } from '~/router/routes/shared/admin/reports'
import { adminInvoicesRoutes } from '~/router/routes/shared/admin/invoices'
import { adminUsersRoutes } from '~/router/routes/shared/admin/users'
import { adminIpsRoutes } from '~/router/routes/shared/admin/ips'
import { adminShortUlrRoutes } from '~/router/routes/shared/admin/short-url'
import { adminAnnouncementsRoutes } from '~/router/routes/shared/admin/announcements'
import { adminTickets } from '~/router/routes/shared/admin/tickets'
import { adminShortsRoutes } from '~/router/routes/shared/admin/shorts'
import { adminInteractionsRoutes } from '~/router/routes/shared/admin/interactions'
import { adminTranslationsRoutes } from '~/router/routes/shared/admin/translations'
import { adminSearchEmailsRoutes } from '~/router/routes/shared/admin/emails'

export default [
  {
    name: '__admin_index',
    path: '/admin',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/index.vue' /* webpackChunkName: "pages-shared-admin-index" */
        )
      )
  },
  ...adminReportsRoutes,
  ...adminInvoicesRoutes,
  ...adminUsersRoutes,
  ...adminIpsRoutes,
  ...adminShortUlrRoutes,
  ...adminAnnouncementsRoutes,
  ...adminTickets,
  ...adminShortsRoutes,
  ...adminInteractionsRoutes,
  ...adminTranslationsRoutes,
  ...adminSearchEmailsRoutes
]
