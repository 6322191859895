import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { FLASH_NS } from '~/store/modules/shared/flash/state'
import {
  SET_BACKEND_FLASH_MESSAGE,
  SET_CLIENT_FLASH_MESSAGE
} from '~/store/modules/shared/flash/mutation-types'
import CookiesService from '~/services/CookiesService'
import {
  BACKEND_FLASH_MESSAGE_COOKIE_NAME,
  CLIENT_FLASH_MESSAGE_COOKIE_NAME
} from '~/constants/shared'
import { redirectToken } from '~/constants/dependency-injection/tokens'
import { Redirect } from '~/models/global-dependencies'
import {
  ClientFlashMessageData,
  ClientFlashMessageType
} from '~/models/flash/types'
import LoggerService from '~/services/LoggerService'
import { APP_NS } from '~/store/modules/shared/app/state'

@containerScoped()
export default class FlashService {
  constructor(
    @inject(Store) private store: Store<RootState>,
    @inject(CookiesService) private cookies: CookiesService,
    @inject(redirectToken) private redirect: Redirect,
    @inject(LoggerService) private logger: LoggerService
  ) {}

  private setCarBackendFlash(cookieValue: string): void {
    const [message, variant] = cookieValue.split('||')
    this.store.commit(`${FLASH_NS}/${SET_BACKEND_FLASH_MESSAGE}`, {
      message: decodeURIComponent(message),
      variant
    })
  }

  private setPlotBackendFlash(cookieValue: string): void {
    const parsedCookie = JSON.parse(decodeURIComponent(cookieValue))
    const hasFlashes =
      parsedCookie && parsedCookie.flashes && parsedCookie.flashes.length > 0
    if (!hasFlashes) {
      return
    }
    // Currently, only the first plot flash cookie is parsed
    const [firstFlash] = parsedCookie.flashes
    const { message, type: variant } = firstFlash
    this.store.commit(`${FLASH_NS}/${SET_BACKEND_FLASH_MESSAGE}`, {
      message,
      variant
    })
  }

  setBackendFlashFromCookie(): void {
    const backendFlashCookie = this.cookies.get(
      BACKEND_FLASH_MESSAGE_COOKIE_NAME
    )
    if (!backendFlashCookie) {
      return
    }
    if (this.store.getters[`${APP_NS}/isCar`]) {
      this.setCarBackendFlash(backendFlashCookie)
    } else if (this.store.getters[`${APP_NS}/isPlot`]) {
      this.setPlotBackendFlash(backendFlashCookie)
    }
  }

  setClientFlashFromCookie(): void {
    const clientFlashCookieValue = this.cookies.safelyGetJsonParsedCookie(
      CLIENT_FLASH_MESSAGE_COOKIE_NAME
    )
    if (!clientFlashCookieValue || typeof clientFlashCookieValue !== 'object') {
      return
    }
    const { type, data } = clientFlashCookieValue
    this.store.commit(`${FLASH_NS}/${SET_CLIENT_FLASH_MESSAGE}`, {
      type,
      data
    })
  }

  redirectWithFlash(
    url: string,
    flashType: ClientFlashMessageType,
    flashData?: ClientFlashMessageData,
    forceReplace: boolean = true
  ) {
    this.setClientFlashCookie(flashType, flashData, forceReplace)
    this.redirect(url)
    setTimeout(() => this.setClientFlashFromCookie(), 300)
  }

  setClientFlashCookie(
    type: ClientFlashMessageType,
    data?: ClientFlashMessageData,
    forceReplace: boolean = true
  ) {
    this.cookies.set(
      CLIENT_FLASH_MESSAGE_COOKIE_NAME,
      JSON.stringify({ type, data }),
      undefined,
      forceReplace
    )
  }

  clearBackendCookie(): void {
    this.cookies.delete(BACKEND_FLASH_MESSAGE_COOKIE_NAME)
  }

  clearClientCookie(): void {
    this.cookies.delete(CLIENT_FLASH_MESSAGE_COOKIE_NAME)
  }
}
