































import Vue from 'vue'
import { Position } from '~/models/app/position'
import { Trigger } from '~/models/app/trigger'
import { Variant } from '~/models/app/variant'
import { ciInfoCircle } from '~/icons/source/solid/info-circle'

export default Vue.extend({
  props: {
    placement: {
      type: String,
      required: false,
      default: 'bottom'
    },
    variant: {
      type: String,
      required: false,
      default: Variant.INFO
    },
    forceBlue: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: Number,
      required: false,
      default: 18
    }
  },
  computed: {
    icons() {
      return {
        question: ciInfoCircle
      }
    },
    trigger() {
      return Trigger
    },
    position() {
      return Position
    }
  }
})
