import { xymaRoutes } from './xyma'
import { plotMapRoutes, plotRoutes } from './plot'
import { partsRoutes } from './parts'
import { jobsRoutes } from './jobs'
import { rentalsRoutes } from './rentals'
import { dealsRoutes } from './deals'
import { vehiclesRoutes } from './vehicles'
import { myClassifiedRoutes } from './my-classifieds'
import { deletedClassifiedRoutes } from './deleted'
import { userProfileRoutes } from '~/router/routes/shared/classifieds/user-profile'
import { carMapRoutes } from '~/router/routes/car/classifieds/search/car-map'
import { blackFridayRoutes } from '~/router/routes/car/classifieds/search/black-friday'
import quickSearchRoutes from '~/router/routes/car/classifieds/search/quick-search'

export default [
  {
    name: '__classifieds_search_index',
    path: '/classifieds/search',
    redirect: {
      name: '__classifieds_quick_search'
    }
  },
  ...quickSearchRoutes,
  ...plotMapRoutes,
  ...plotRoutes,
  ...xymaRoutes,
  ...myClassifiedRoutes,
  ...deletedClassifiedRoutes,
  ...partsRoutes,
  ...carMapRoutes,
  ...jobsRoutes,
  ...rentalsRoutes,
  ...dealsRoutes,
  ...blackFridayRoutes,
  ...vehiclesRoutes,
  ...userProfileRoutes
]
