import qs from 'qs'
import { inject } from 'tsyringe'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { containerScoped } from '~/decorators/dependency-container'
import {
  UrlQueryParamSetter,
  QueryParamSetterOptions,
  ArrayParamHandling
} from '~/models/url'
import { IUrlHistoryService } from '~/services/url/history/IUrlHistoryService'

@containerScoped()
export default class UrlHistoryService implements IUrlHistoryService {
  constructor(@inject(VueRouter) private router: VueRouter) {}

  pushHash(hash?: string) {
    const url = this.getCurrentUrl()
    url.hash = hash || ''
    this.router.replace(url.toString())
  }

  replaceQueryParams(
    queryParams: Record<string, string | string[] | number[] | null | undefined>
  ) {
    const params = {
      ...qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      }),
      ...queryParams
    }
    const url: string = qs.stringify(params, {
      arrayFormat: 'repeat',
      encode: false,
      skipNulls: true
    })
    history.replaceState(null, document.title, `?${url}`)
  }

  /**
   * Query param setter creator
   */
  queryParamSetter(options?: QueryParamSetterOptions): UrlQueryParamSetter {
    return queryParams => {
      const url = this.getCurrentUrlWithQueryParams(queryParams, options)
      history.pushState(null, document.title, url.toString())
    }
  }

  /**
   * Note: A popstate event is emitted both for going forward and backward in history.
   */
  onPopState(
    componentInstance: Vue,
    listener: (e: PopStateEvent) => void
  ): () => void {
    window.addEventListener('popstate', listener)
    const removeListener = () => {
      window.removeEventListener('popstate', listener)
    }
    componentInstance.$once('hook:beforeDestroy', () => {
      removeListener()
    })
    return removeListener
  }

  private getCurrentUrl(): URL {
    return new URL(window.location.href)
  }

  /**
   * Pseudocode examples with current query params "b=2&c=3":
   *    getCurrentUrlWithQueryParams({a: 1}, { keyScope: ['b'] })
   *      => c=3&a=1
   *    getCurrentUrlWithQueryParams({b: 4}, { keyScope: ['b'] })
   *      => b=4&c=3&a=1
   *
   * @param queryParams Input query params
   * @param keyScope Values of this set that are not keys of input query params will be deleted from output query params
   * @param arrayParamHandling
   * @private
   */
  private getCurrentUrlWithQueryParams(
    queryParams: Record<string, string | string[]>,
    { keyScope, arrayParamHandling }: QueryParamSetterOptions = {
      keyScope: [],
      arrayParamHandling: ArrayParamHandling.PARAM_PER_ELEMENT
    }
  ): URL {
    if (!keyScope.length) {
      throw new TypeError('undefined key-scope')
    }

    const isEmptyArray = (obj: any) => Array.isArray(obj) && !obj.length
    const isEmptyArrayOrUndefined = (obj: any) =>
      isEmptyArray(obj) || typeof obj === 'undefined'

    const urlSearchParams = new URLSearchParams(window.location.search)
    const removableKeys = new Set<string>(keyScope || [])

    Object.entries(queryParams).forEach(([key, paramValue]) => {
      !isEmptyArrayOrUndefined(paramValue) && removableKeys.delete(key)

      if (Array.isArray(paramValue)) {
        this.setArrayQueryParamValue(
          key,
          paramValue,
          urlSearchParams,
          arrayParamHandling
        )
        return
      }
      urlSearchParams.set(key, paramValue)
    })

    for (const key of removableKeys) {
      urlSearchParams.delete(key)
    }

    const url = this.getCurrentUrl()
    url.search = urlSearchParams.toString()
    return url
  }

  private setArrayQueryParamValue(
    key: string,
    value: string[],
    urlSearchParams: URLSearchParams,
    handling: ArrayParamHandling
  ) {
    if (!Array.isArray(value)) {
      throw new TypeError('Non-array value passed')
    }

    urlSearchParams.delete(key)

    switch (handling) {
      case ArrayParamHandling.PARAM_PER_ELEMENT:
        value.forEach(elementOfArrayParam => {
          urlSearchParams.append(key, elementOfArrayParam)
        })
        break
      case ArrayParamHandling.COMMA_SEPARATED_VALUE:
        urlSearchParams.set(key, value.join(','))
    }
  }
}
