import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__login',
    path: '/login/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/login.vue' /* webpackChunkName: "pages-shared-login_register-login.vue" */
        )
      )
  },
  {
    name: '__guest_register',
    path: '/guests/register/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/guest-register.vue' /* webpackChunkName: "pages-shared-login_register-guest-register.vue" */
        )
      )
  },
  {
    name: '__password_reset',
    path: '/password-reset/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/password-reset.vue' /* webpackChunkName: "pages-shared-login_register-guest-password-reset.vue" */
        )
      )
  },
  {
    name: '__password_set',
    path: '/password-set/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/password-set.vue' /* webpackChunkName: "pages-shared-login_register-guest-password-set.vue" */
        )
      )
  },
  {
    name: '__logout',
    path: '/logout/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/logout.vue' /* webpackChunkName: "pages-shared-login_register-logout.vue" */
        )
      )
  },
  {
    name: '__google_signin_redirect',
    path: '/google-login/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/google-login-redirect.vue' /* webpackChunkName: "pages-shared-login_register-google-login-redirect.vue" */
        )
      ),
    meta: { noFooter: true, noHeader: true }
  },
  {
    name: '__guest_register_activate',
    path: '/guests/register/activate/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/login_register/guest-register-activate.vue' /* webpackChunkName: "pages-shared-login_register-guest-register-activate.vue" */
        )
      ),
    meta: { noFooter: true, noHeader: true }
  }
]
