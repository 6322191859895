import { ActionTreeWithRootState } from '~/store/types'
import { AdvertiseFormState } from '~/store/modules/shared/advertising/form/state'
import {
  ADD_NEW_CAMPAIGN_AD,
  ADD_NEW_CAMPAIGN_AD_ITEM,
  DELETE_CAMPAIGN_AD,
  DELETE_CAMPAIGN_AD_ITEM,
  SET_CAMPAIGN_AD_ERRORS,
  SET_CAMPAIGN_AD_ITEM_ERRORS,
  SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA,
  SET_CAMPAIGN_ADS,
  SET_CAMPAIGN_ERRORS,
  SET_CAMPAIGN_SCHEMA,
  SET_CAMPAIGN_VALUES,
  SET_LOADING,
  SET_NEW_AD_ERRORS,
  SET_NEW_AD_SCHEMA,
  SET_NEW_AD_VALUES,
  SET_NEW_ITEM_ERRORS,
  SET_NEW_ITEM_VALUES,
  UPDATE_CAMPAIGN_AD,
  UPDATE_CAMPAIGN_AD_ITEM
} from '~/store/modules/shared/advertising/form/mutation-types'
import AdvertiseService from '~/services/ads/AdvertiseService'
import { AdItemStatus } from '~/models/advertising/types'
import { Position } from '~/models/app/position'
import FormService from '~/services/form/FormService'

export default {
  async loadCampaignSchema({ commit }) {
    try {
      commit(SET_LOADING, true)
      const { schema, values } = await this.$dep(
        AdvertiseService
      ).getCampaignSchema()

      commit(SET_CAMPAIGN_SCHEMA, schema)

      if (values) {
        commit(SET_CAMPAIGN_VALUES, values)
      }
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async loadNewAdSchema({ commit }) {
    try {
      commit(SET_LOADING, true)

      const { schema } = await this.$dep(AdvertiseService).getAdSchema()

      commit(SET_NEW_AD_SCHEMA, schema)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async loadNewAdItemSchema({ getters, commit }) {
    try {
      commit(SET_LOADING, true)

      const adId = this.$router.currentRoute.params.ad

      const placementContextCategory = getters.getCampaignAd(adId)
        ?.newItemValues?.placementContext?.categories

      const { schema } = await this.$dep(AdvertiseService).getAdItemSchema(
        placementContextCategory
      )

      commit(SET_CAMPAIGN_AD_NEW_ITEM_SCHEMA, { id: adId, schema })
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async getCampaignAdSchemaAndValues({ commit }) {
    try {
      commit(SET_LOADING, true)
      const adId = this.$router.currentRoute.params.ad
      const { schema, values, inHouseAd } = await this.$dep(
        AdvertiseService
      ).getCampaignAdSchemaAndValues(adId)

      const adInfo = {
        schema,
        values,
        items: inHouseAd.items.map(i => {
          return { item: i }
        }),
        ad: inHouseAd
      }

      commit(UPDATE_CAMPAIGN_AD, adInfo)
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async getCampaignAdItemSchemaAndValues({ commit, getters }) {
    try {
      commit(SET_LOADING, true)
      const adId = this.$router.currentRoute.params.ad
      const itemId = this.$router.currentRoute.params.item

      const placementContextCategory = getters.getCampaignAdItem(adId, itemId)
        ?.values?.placementContext?.categories

      const adItem = await this.$dep(
        AdvertiseService
      ).getCampaignAdItemSchemaAndValues(adId, itemId, placementContextCategory)

      commit(UPDATE_CAMPAIGN_AD_ITEM, { id: adId, campaignAdItem: adItem })
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async postNewCampaign({ state, commit }) {
    try {
      commit(SET_LOADING, true)
      const { campaign, values } = await this.$dep(
        AdvertiseService
      ).postNewCampaign(state.campaignValues)

      commit(SET_CAMPAIGN_VALUES, values)
      commit(SET_CAMPAIGN_ERRORS, {})

      this.$router
        .push({
          name: '__advertising_campaign_ad_new',
          params: { campaign: campaign.id.toString() }
        })
        .catch((_: any) => {})
    } catch (error) {
      if (error.response?.data?.data?.errors) {
        commit(SET_CAMPAIGN_ERRORS, error.response?.data?.data?.errors)
        const container = document.getElementById('advertise-edit-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
      } else {
        // @ts-ignore
        const defaultErrorMessage = this.app.i18n.t('something went wrong')
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async updateCampaign({ state, commit }) {
    try {
      commit(SET_LOADING, true)
      const { campaign, values } = await this.$dep(
        AdvertiseService
      ).updateCampaign(
        state.campaignValues,
        this.$router.currentRoute.params?.campaign
      )

      commit(SET_CAMPAIGN_VALUES, values)
      commit(SET_CAMPAIGN_ERRORS, {})

      // TODO check if ad exists in params and go to ad edit instead
      this.$router
        .push({
          name: '__advertising_campaign_ad_new',
          params: { campaign: campaign.id.toString() }
        })
        .catch((_: any) => {})
    } catch (error) {
      if (error.response?.data?.data?.errors) {
        commit(SET_CAMPAIGN_ERRORS, error.response?.data?.data?.errors)
        const container = document.getElementById('advertise-edit-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
      } else {
        // @ts-ignore
        const defaultErrorMessage = this.app.i18n.t('something went wrong')
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async getCampaignAds({ commit }, campaignId) {
    try {
      commit(SET_LOADING, true)
      const { ads } = await this.$dep(AdvertiseService).getCampaignAds(
        campaignId
      )

      const formattedAds = ads.rows.map(ad => {
        return {
          ad,
          items:
            ad.items?.map(i => {
              return { item: i }
            }) || []
        }
      })
      commit(SET_CAMPAIGN_ADS, formattedAds)
    } catch (error) {
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async postNewAd({ state, commit }, newFlow: boolean = false) {
    try {
      commit(SET_LOADING, true)
      const { ad, values } = await this.$dep(AdvertiseService).postNewAd(
        state.newAdValues
      )

      commit(ADD_NEW_CAMPAIGN_AD, { ad, values })
      commit(SET_NEW_AD_VALUES, {})
      commit(SET_NEW_AD_ERRORS, {})

      const campaignId = this.$router.currentRoute.params.campaign

      if (newFlow) {
        this.$router
          .replace({
            name: '__advertising_campaign_ad_edit',
            params: {
              campaign: campaignId.toString(),
              ad: ad.id.toString()
            },
            query: {
              newFlow: '1',
              triggerTopUp: '1'
            }
          })
          .catch((_: any) => {})
      } else {
        this.$router
          .push({
            name: '__advertising_campaign_ad_item_new',
            params: {
              campaign: campaignId.toString(),
              ad: ad.id.toString()
            }
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      if (error.response?.data?.data?.errors) {
        commit(SET_NEW_AD_ERRORS, error.response?.data?.data?.errors)
        const container = document.getElementById('ad-forms-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
        return
      } else if (!newFlow) {
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
        return
      }
      this.$logger.captureError(error)

      throw new Error(defaultErrorMessage)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async updateAd({ commit, getters }, silent: boolean = false) {
    try {
      commit(SET_LOADING, true)

      const campaignAd = getters.getCampaignAd(
        this.$router.currentRoute.params.ad
      )
      const updatedCampaignAd = await this.$dep(AdvertiseService).updateAd(
        campaignAd.values,
        this.$router.currentRoute.params.ad
      )

      commit(UPDATE_CAMPAIGN_AD, updatedCampaignAd)
      commit(SET_CAMPAIGN_AD_ERRORS, {
        id: this.$router.currentRoute.params.ad,
        errors: {}
      })

      if (!silent) {
        if (this.$router.currentRoute.query.newFlow) {
          this.$router
            .push({
              name: '__advertising_campaign_ad_item_new',
              params: {
                campaign: this.$router.currentRoute.params.campaign.toString(),
                ad: this.$router.currentRoute.params.ad.toString()
              }
            })
            .catch((_: any) => {})
        } else {
          // @ts-ignore
          const msg = this.app.i18n.t('success_saved_changes')
          this.$snackbar.success(msg, {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          })
        }
      }
    } catch (error) {
      if (error.response?.data?.data?.errors) {
        commit(SET_CAMPAIGN_AD_ERRORS, {
          id: this.$router.currentRoute.params.ad,
          errors: error.response?.data?.data?.errors
        })
        const container = document.getElementById('ad-forms-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
      } else if (!silent) {
        // @ts-ignore
        const defaultErrorMessage = this.app.i18n.t('something went wrong')
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteAd({ commit }, adId: number | string) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteAd(adId)

      commit(DELETE_CAMPAIGN_AD, adId)

      const campaignId = this.$router.currentRoute.params.campaign
      const routeAdId = this.$router.currentRoute.params.ad
      if (adId.toString() === routeAdId?.toString()) {
        // we just deleted this route so go back to campaign maybe?
        this.$router
          .push({
            name: '__advertising_campaign_edit',
            params: { campaign: campaignId.toString() }
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(
        error.response?.data?.error ||
          error.response?.data?.error ||
          defaultErrorMessage,
        {
          position: Position.BOTTOM_RIGHT,
          classes: ['above-ads-footer']
        }
      )
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async deleteAdItem(
    { commit },
    { adId, itemId }: { adId: number | string; itemId: number | string }
  ) {
    try {
      commit(SET_LOADING, true)
      await this.$dep(AdvertiseService).deleteAdItem(adId, itemId)

      commit(DELETE_CAMPAIGN_AD_ITEM, adId)

      const campaignId = this.$router.currentRoute.params.campaign
      const routeAdId = this.$router.currentRoute.params.ad
      const routeAdItemId = this.$router.currentRoute.params.item
      if (itemId.toString() === routeAdItemId.toString()) {
        // we just deleted this route so go back to ad edit maybe?
        this.$router
          .push({
            name: '__advertising_campaign_ad_edit',
            params: { campaign: campaignId, ad: routeAdId }
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async postNewAdItem({ getters, commit }, status?: AdItemStatus) {
    try {
      commit(SET_LOADING, true)

      const newAdItem = getters.getCampaignAd(
        this.$router.currentRoute.params.ad
      ).newItemValues

      const { item, values } = await this.$dep(AdvertiseService).postNewAdItem({
        ...newAdItem,
        status
      })

      commit(ADD_NEW_CAMPAIGN_AD_ITEM, {
        id: this.$router.currentRoute.params.ad,
        campaignAdItem: { item, values }
      })
      commit(SET_NEW_ITEM_VALUES, {
        id: this.$router.currentRoute.params.ad,
        adItemForm: {}
      })
      commit(SET_NEW_ITEM_ERRORS, {
        id: this.$router.currentRoute.params.ad,
        adItemForm: {}
      })

      if (status) {
        // @ts-ignore
        const msg1 = this.app.i18n.t('item_success_saved_to_drafts').toString()
        // @ts-ignore
        const msg2 = this.app.i18n.t('success_saved_changes').toString()
        this.$snackbar.success(status === AdItemStatus.DRAFT ? msg1 : msg2, {
          position: Position.BOTTOM_RIGHT,
          classes: ['above-ads-footer']
        })

        if (status === AdItemStatus.DRAFT) {
          // go to the currently saved
          if (this.$router.currentRoute.params?.item !== item.id.toString()) {
            this.$router
              .push({
                name: '__advertising_campaign_ad_item_edit',
                params: {
                  campaign: this.$router.currentRoute.params?.campaign,
                  ad: this.$router.currentRoute.params?.ad,
                  item: item.id.toString()
                }
              })
              .catch((_: any) => {})
          }
        }
      } else {
        // @ts-ignore
        const msg = this.app.i18n.t('item_success_publish').toString()
        this.$snackbar.success(msg, {
          position: Position.BOTTOM_RIGHT,
          classes: ['above-ads-footer']
        })

        this.$router
          .push({
            name: '__advertising_dashboard_ad_items',
            query: {
              campaign: this.$router.currentRoute.params?.campaign,
              ad: this.$router.currentRoute.params?.ad
            }
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      if (error.response?.data?.data?.errors) {
        commit(SET_NEW_ITEM_ERRORS, {
          id: this.$router.currentRoute.params.ad,
          adItemForm: error.response?.data?.data?.errors
        })
        const container = document.getElementById('advertise-edit-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
      } else {
        // @ts-ignore
        const defaultErrorMessage = this.app.i18n.t('something went wrong')
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async updateAdItem({ commit, getters }, status?: AdItemStatus) {
    try {
      commit(SET_LOADING, true)

      const campaignId = this.$router.currentRoute.params?.campaign
      const adId = this.$router.currentRoute.params?.ad
      const itemId = this.$router.currentRoute.params?.item
      const campaignAdItem = getters.getCampaignAdItem(adId, itemId)
      const updatedCampaignAdItem = await this.$dep(
        AdvertiseService
      ).updateAdItem({ ...campaignAdItem.values, status })

      commit(UPDATE_CAMPAIGN_AD_ITEM, {
        id: adId,
        campaignAdItem: updatedCampaignAdItem
      })
      commit(SET_CAMPAIGN_AD_ITEM_ERRORS, {
        adId,
        itemId,
        errors: {}
      })

      if (status) {
        // @ts-ignore
        const msg1 = this.app.i18n.t('item_success_saved_to_drafts').toString()
        // @ts-ignore
        const msg2 = this.app.i18n.t('success_saved_changes').toString()
        this.$snackbar.success(status === AdItemStatus.DRAFT ? msg1 : msg2, {
          position: Position.BOTTOM_RIGHT,
          classes: ['above-ads-footer']
        })
      } else {
        this.$router
          .push({
            name: '__advertising_dashboard_ad_items',
            query: { campaign: campaignId, ad: adId }
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      if (error.response?.data?.data?.errors) {
        commit(SET_CAMPAIGN_AD_ITEM_ERRORS, {
          adId: this.$router.currentRoute.params.ad,
          itemId: this.$router.currentRoute.params.item,
          errors: error.response?.data?.data?.errors
        })
        const container = document.getElementById('advertise-edit-container')
        if (container) {
          this.$dep(FormService).onErrorScroll({ container })
        }
      } else {
        // @ts-ignore
        const defaultErrorMessage = this.app.i18n.t('something went wrong')
        this.$snackbar.error(
          error.response?.data?.error || defaultErrorMessage,
          {
            position: Position.BOTTOM_RIGHT,
            classes: ['above-ads-footer']
          }
        )
      }
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async publishAdItem({ commit, getters }) {
    try {
      commit(SET_LOADING, true)
      const campaignAdItem = getters.getCampaignAdItem(
        this.$router.currentRoute.params?.ad,
        this.$router.currentRoute.params?.item
      )

      const afterPublish =
        campaignAdItem.item.status === AdItemStatus.REPLACED ? undefined : '1'

      await this.$dep(AdvertiseService).publishAdItem()

      // @ts-ignore
      const msg = this.app.i18n.t('item_success_publish').toString()
      this.$snackbar.success(msg, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })

      this.$router
        .push({
          name: '__advertising_dashboard_ad_items',
          query: {
            campaign: this.$router.currentRoute.params?.campaign,
            ad: this.$router.currentRoute.params?.ad,
            afterPublish
          }
        })
        .catch((_: any) => {})
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async duplicateItem(
    { commit, dispatch, getters },
    {
      adId,
      itemId,
      hash
    }: { adId: string | number; itemId: string | number; hash?: string }
  ) {
    try {
      commit(SET_LOADING, true)
      const campaignAdItem = getters.getCampaignAdItem(adId, itemId)

      let itemValues = campaignAdItem.values
      if (!itemValues) {
        const { values } = await this.$dep(
          AdvertiseService
        ).getCampaignAdItemSchemaAndValues(adId, itemId)

        itemValues = values
      }

      commit(SET_NEW_ITEM_VALUES, {
        id: adId,
        adItemForm: { ...itemValues }
      })

      await dispatch('loadNewAdItemSchema')

      if (
        this.$router.currentRoute.name !== '__advertising_campaign_ad_item_new'
      ) {
        this.$router
          .push({
            name: '__advertising_campaign_ad_item_new',
            params: {
              campaign: this.$router.currentRoute.params.campaign,
              ad: adId.toString()
            },
            hash
          })
          .catch((_: any) => {})
      }
    } catch (error) {
      // @ts-ignore
      const defaultErrorMessage = this.app.i18n.t('something went wrong')
      this.$snackbar.error(error.response?.data?.error || defaultErrorMessage, {
        position: Position.BOTTOM_RIGHT,
        classes: ['above-ads-footer']
      })
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING, false)
    }
  }
} as ActionTreeWithRootState<AdvertiseFormState>
