import { GetterTreeWithRootState } from '~/store/types'
import {
  Subscription,
  SubscriptionInfo,
  PushType
} from '~/models/search/subscriptions/types'
import { SearchSubscriptionsState } from './state'

export default {
  getSubscriptionBySearchId: state => (
    searchId: string
  ): Subscription | undefined => {
    const id = state.subscriptionsBySearch.get(searchId)
    return (id && state.subscriptions.get(id)) || undefined
  },

  searchPageSubscription: (_state, getters, rootState) => {
    const { getSubscriptionBySearchId } = getters
    return getSubscriptionBySearchId(rootState.classifieds.search.searchId)
  },

  getSubscriptionInfoBySearchId: state => (
    searchId: string
  ): SubscriptionInfo | undefined => {
    return state.subscriptionInfoSets.get(searchId)
  },

  searchPageSubscriptionInfo: (_state, getters, rootState) => {
    const { getSubscriptionInfoBySearchId } = getters
    return getSubscriptionInfoBySearchId(rootState.classifieds.search.searchId)
  },

  searchPagePushIsValid: (
    _state,
    getters,
    {
      classifieds: {
        search: { notifications: searchPageNotifications }
      }
    }
  ) => (type: PushType) => {
    const { searchPageSubscriptionInfo } = getters

    switch (type) {
      case PushType.INSTANT:
        return (
          searchPageSubscriptionInfo?.notifications.instantValid ||
          searchPageNotifications.instantValid
        )
      case PushType.BULK:
        return (
          searchPageSubscriptionInfo?.notifications.bulkValid ||
          searchPageNotifications.bulkValid
        )
    }
  },

  subscriptionExists: (_state, getters) => (searchId: string): boolean => {
    const { getSubscriptionBySearchId } = getters
    return getSubscriptionBySearchId(searchId)
  },

  subscriptionLimitReached: ({ countLoaded, maxCount, totalCount }) =>
    countLoaded &&
    Number.isInteger(maxCount) &&
    Number.isInteger(totalCount) &&
    maxCount! <= totalCount!,

  subscriptionIsOpen: state => (searchId: string): boolean => {
    return Boolean(searchId && state.openSubscriptions.has(searchId))
  },

  someSubscriptionsAreOpen: (state): boolean =>
    Boolean(state.openSubscriptions.size),

  subscriptionIsDisabled: (_state, getters) => (searchId: string): boolean => {
    return getters.unsubscribableDueToLimit(searchId)
  },

  unsubscribableDueToLimit: (_state, getters) => (
    searchId: string
  ): boolean => {
    const subscription = getters.getSubscriptionBySearchId(searchId)
    return !subscription && getters.subscriptionLimitReached
  },
  searchPageNotificationButtonVariant: (_state, getters, rootState) => {
    const { subscriptionIsDisabled, searchPageSubscription } = getters
    if (subscriptionIsDisabled(rootState.classifieds.search.searchId)) {
      return 'outline-secondary'
    } else if (searchPageSubscription) {
      // Subscription exists.
      return 'primary'
    }

    return 'outline-primary'
  }
} as GetterTreeWithRootState<SearchSubscriptionsState>
