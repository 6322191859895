import { VNode } from 'vue'

export function useVNode() {
  function addPropertiesToVNodes(
    vNodes: VNode[],
    { props = {}, staticClasses = '' } = { props: {}, staticClasses: '' }
  ) {
    return vNodes.map(node =>
      addPropertiesToVNode(node, { props, staticClasses })
    )
  }

  function addPropertiesToVNode(
    node: VNode,
    { props = {}, staticClasses = '' } = { props: {}, staticClasses: '' }
  ) {
    if (node.componentOptions) {
      node.componentOptions.propsData = {
        ...(node.componentOptions.propsData || {}),
        ...props
      }
    }
    if (node.data) {
      node.data.staticClass = `${node.data?.staticClass || ''} ${staticClasses}`
    }
    return node
  }

  return { addPropertiesToVNodes, addPropertiesToVNode }
}
