import { FormClassifiedInfo } from '~/models/form/types'

export const CLASSIFIED_EDIT_NS = 'classifieds/edit'

export interface EditState {
  info?: FormClassifiedInfo
  owner: any
  category?: any
  registerReferenceCode?: string
}
function getDefaultState(): EditState {
  return {
    category: undefined,
    info: undefined,
    owner: null,
    registerReferenceCode: undefined
  }
}

export default getDefaultState
