import { ButtonVariantType, ButtonVariant } from '~/models/app/variant'
import { computed } from '@nuxtjs/composition-api'
import { Size } from '~/models/shared/types'
import { Ref } from '@vue/composition-api'

export function useButtonClasses(
  size: Ref<Size>,
  disabled: Ref<boolean>,
  loading: Ref<boolean>,
  variant: Ref<ButtonVariantType>,
  pressed: Ref<boolean>,
  isLink: Ref<boolean>,
  floating: Ref<boolean>,
  pill: Ref<boolean>,
  squared: Ref<boolean>,
  circle: Ref<boolean>,
  disabledHover: Ref<boolean>
) {
  const spinnerClasses = computed(() => {
    if (floating.value) {
      return ['tw--left-0.5']
    }
    if (size.value !== 'xs') {
      return ['tw--left-2']
    }
    return []
  })

  const sizeClasses = computed(() => {
    if (floating.value) {
      return [
        'tw-w-14',
        'tw-h-14',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
        'tw-text-3xl',
        'tw-shadow'
      ]
    }
    switch (size.value) {
      case Size.EXTRA_SMALL: {
        return ['tw-px-1', 'tw-py-0', 'tw-text-[0.8125rem]']
      }
      case Size.SMALL: {
        return ['tw-px-2', 'tw-py-1', 'tw-text-base', 'tw-px-2', 'tw-py-1']
      }
      case Size.LARGE: {
        return ['tw-py-2', 'tw-px-4', 'tw-text-2xl']
      }
      case Size.EXTRA_LARGE: {
        return ['tw-py-2', 'tw-px-4', 'tw-text-3xl']
      }
      default: {
        if (circle.value) {
          return [
            'tw-w-10',
            'tw-h-10',
            'tw-text-lg',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center'
          ]
        }
        return ['tw-px-3', 'tw-py-[0.375rem]', 'tw-text-lg']
      }
    }
  })
  const shouldBeDisabled = computed(() => disabled.value || loading.value)
  const variantClasses = computed(() => {
    const disabledClasses = shouldBeDisabled.value
      ? ['disabled:tw-opacity-70', 'tw-cursor-default']
      : []
    switch (variant.value) {
      case ButtonVariant.LINK: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-text-blue-900', 'focus:tw-underline']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-text-blue-900',
                'hover:tw-underline',
                'hover:tw-text-blue-900'
              ]
            : []
        return ['tw-bg-transparent', 'tw-text-blue-700', ...hover, focus]
      }
      case ButtonVariant.OUTLINE_PRIMARY: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-primary-700',
                'hover:tw-text-white',
                'hover:tw-border-primary-700'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-primary-700', '!tw-text-white active']
            : []

        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-primary',
          'tw-border-primary',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }
      case ButtonVariant.SUCCESS: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-green-800', 'focus:tw-border-green-800']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-green-800',
                'hover:tw-border-green-800',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-green-800', '!tw-text-white', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-green-600',
          'tw-border',
          'tw-border-solid',
          'tw-border-green-600',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }
      case ButtonVariant.OUTLINE_SUCCESS: {
        const disabledClasses = shouldBeDisabled.value
          ? ['disabled:tw-opacity-70', 'tw-cursor-default']
          : []
        const focus = !shouldBeDisabled.value ? [] : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-green-800', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-green-800', '!tw-text-white active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-green-600',
          'tw-border-green-600',
          ...hover,
          ...focus,
          ...pressedClasses,
          ...disabledClasses
        ]
      }
      case ButtonVariant.WARNING: {
        const focus = !shouldBeDisabled.value ? ['focus:tw-bg-yellow-800'] : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-yellow-800',
                'tw-border-yellow-800',
                'hover:tw-text-grey-900'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-yellow-800', 'active']
            : []
        return [
          'tw-bg-yellow-700',
          'tw-text-body',
          'tw-border',
          'tw-border-solid',
          'tw-border-yellow-700',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }
      case ButtonVariant.OUTLINE_WARNING: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-yellow-800',
                'tw-border-yellow-800',
                'hover:tw-text-body'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-yellow-800', '!tw-text-body active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-yellow-700',
          'tw-border-yellow-700',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }
      case ButtonVariant.DANGER: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-red-800', 'focus:tw-border-red-800']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-red-800',
                'hover:tw-border-red-800',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-red-800', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-red',
          'tw-border',
          'tw-border-solid',
          'tw-border-red',
          ...pressedClasses,
          ...disabledClasses,
          ...hover,
          focus
        ]
      }
      case ButtonVariant.OUTLINE_DANGER: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-red-500',
                'hover:tw-border-red-500',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-red-600', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-red-500',
          'tw-border-red-300',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }
      case ButtonVariant.OUTLINE_SECONDARY: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-grey-600', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-grey-600', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-grey-600',
          'tw-border-grey-600',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }

      case ButtonVariant.INFO: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-cyan-800', 'focus:tw-border-cyan-600']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-cyan-800',
                'hover:tw-border-cyan-600',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-cyan-800', 'active']
            : []
        return [
          'tw-border',
          'tw-border-solid',
          'tw-border-cyan-600',
          'tw-text-white',
          'tw-bg-cyan-600',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }
      case ButtonVariant.OUTLINE_INFO: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-cyan-800', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-cyan-800', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-cyan-700',
          'tw-border-cyan-800',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }

      case ButtonVariant.WHITE: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-grey-200', 'focus:tw-border-grey-200']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-200',
                'hover:tw-border-grey-200',
                'hover:tw-text-grey-900'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-grey-200', 'active']
            : []
        return [
          'tw-bg-white',
          'tw-text-grey-800',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-200',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.LIGHT: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-grey-300', 'focus:tw-border-grey-300']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-300',
                'hover:tw-border-grey-300',
                'hover:tw-text-grey-900'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-grey-300', 'active']
            : []
        return [
          'light',
          'tw-bg-grey-200',
          'tw-text-body',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-300',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.EXTRA_LIGHT: {
        const focus = !shouldBeDisabled.value
          ? [
              'focus:tw-text-body',
              'focus:tw-bg-grey-200',
              'focus:tw-border-grey-400'
            ]
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-200',
                'hover:tw-text-body',
                'hover:tw-border-grey-400',
                'hover:tw-text-grey-900'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-grey-200', 'active']
            : []
        return [
          'tw-bg-grey-50',
          'tw-text-body',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-400',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_LIGHT: {
        const focus = !shouldBeDisabled.value ? [] : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-grey-300', 'hover:tw-text-grey-600']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-grey-300', '!tw-text-grey-700 active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-grey-500',
          'tw-border-grey-300',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }
      case ButtonVariant.TEAL: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-teal-700', 'focus:tw-border-teal-700']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-teal-700',
                'hover:tw-border-teal-700',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-teal-700', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-teal-500',
          'tw-border',
          'tw-border-solid',
          'tw-border-teal-500',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_TEAL: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-teal-700', 'focus:tw-text-white']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-teal-700', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-teal-700', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-teal-500',
          'tw-border-teal-500',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.BUY_IT: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-border-[#e67f00]']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:!tw-bg-[#e67f00]',
                'hover:tw-border-[#e67f00]',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-[#e67f00]', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-[#ff9e2d]',
          'tw-border',
          'tw-border-solid',
          'tw-border-[#ff9e2d]',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.BLUE_DARK: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-blue-800', 'focus:tw-border-blue-800']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-blue-800',
                'hover:tw-border-blue-800',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-blue-800', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-blue-700',
          'tw-border',
          'tw-border-solid',
          'tw-border-blue-700',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.BLUE: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-blue-700', 'focus:tw-border-blue-700']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-blue-700',
                'hover:tw-border-blue-700',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-blue-700', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-blue-500',
          'tw-border',
          'tw-border-solid',
          'tw-border-blue-500',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_BLUE_DARK: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-blue-800', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-blue-800', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-blue-800',
          'tw-border-blue-800',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_LIGHT_BLUE: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-blue-500', 'hover:tw-text-white']
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-blue-500', '!tw-text-white', 'active']
            : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-blue-500',
          'tw-border-blue-500',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_BLUE: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-blue-500', 'hover:tw-text-white']
            : []
        const pressedClasses = pressed.value
          ? ['tw-bg-blue-500', '!tw-text-white active']
          : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-blue-500',
          'tw-border-blue-500',
          ...pressedClasses,
          ...hover,
          ...disabledClasses
        ]
      }
      case ButtonVariant.SECONDARY: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-grey-700', 'focus:tw-border-grey-700']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-700',
                'hover:tw-border-grey-700',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['!tw-bg-grey-700', 'active']
            : []
        return [
          'tw-text-white',
          'tw-bg-grey-500',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-500',
          ...pressedClasses,
          ...hover,
          ...focus,
          ...disabledClasses
        ]
      }

      case ButtonVariant.OUTLINE_ORANGE: {
        return ['outline-orange', ...disabledClasses]
      }
      case ButtonVariant.ORANGE: {
        return 'orange'
      }
      case ButtonVariant.TURBO: {
        return 'turbo'
      }
      case ButtonVariant.SUPER: {
        return 'super'
      }
      case ButtonVariant.TOP: {
        return 'top'
      }

      case ButtonVariant.FACEBOOK: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-facebook-dark', 'focus:tw-border-facebook']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-facebook-dark',
                'hover:tw-border-facebook',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-facebook-dark', 'active']
            : []
        return [
          'tw-bg-facebook',
          'tw-text-white',
          ...disabledClasses,
          ...pressedClasses,
          ...hover,
          focus
        ]
      }
      case ButtonVariant.X: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-grey-900', 'focus:tw-border-grey-900']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-900',
                'hover:tw-border-grey-900',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-grey-900', 'active']
            : []
        return [
          'tw-bg-black',
          'tw-text-white',
          ...disabledClasses,
          ...pressedClasses,
          ...hover,
          focus
        ]
      }
      case ButtonVariant.WHATSAPP: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-[#1fb256]', 'focus:tw-border-[#1da851]']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-[#1fb256]',
                'hover:tw-border-[#1da851]',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-[#1fb256]', 'active']
            : []
        return [
          'tw-bg-whatsapp',
          'tw-text-white',
          ...disabledClasses,
          ...pressedClasses,
          ...hover,
          focus
        ]
      }
      case ButtonVariant.VIBER: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-viber-dark', 'focus:tw-border-viber']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-viber-dark',
                'hover:tw-border-viber',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !shouldBeDisabled.value
            ? ['tw-bg-viber-dark', 'active']
            : []
        return [
          'tw-bg-viber',
          'tw-text-white',
          ...disabledClasses,
          ...pressedClasses,
          ...hover,
          focus
        ]
      }
      // @TODO use const Variant
      case ButtonVariant.OUTLINE_PLOT: {
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? ['hover:tw-bg-plot', 'hover:tw-text-white']
            : []
        const pressedClasses = pressed.value
          ? ['tw-bg-plot', '!tw-text-white active']
          : []
        return [
          'tw-transparent',
          'tw-border',
          'tw-border-solid',
          'tw-text-plot',
          'tw-border-plot',
          ...pressedClasses,
          ...hover
        ]
      }

      case ButtonVariant.PRIMARY:
      default: {
        const focus = !shouldBeDisabled.value
          ? ['focus:tw-bg-primary-700', 'focus:tw-border-primary-700']
          : []
        const hover =
          !shouldBeDisabled.value && !disabledHover.value
            ? [
                'hover:tw-bg-primary-700',

                'hover:tw-border-primary-700',

                'hover:tw-text-white'
              ]
            : []
        const pressedClasses = pressed.value
          ? ['tw-bg-primary-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-primary',
          'tw-border',
          'tw-border-solid',
          'tw-border-primary',
          ...pressedClasses,
          ...disabledClasses,
          ...hover,
          focus
        ]
      }
    }
  })

  const linkClasses = computed(() => {
    if (isLink.value) {
      return 'tw-inline-block tw-cursor-pointer'
    }
    return null
  })

  const positionClasses = computed(() => {
    if (floating.value) {
      return ['tw-fixed', 'tw-right-6', 'tw-bottom-6']
    }
    return ['tw-relative']
  })

  const roundnessClasses = computed(() => {
    if (floating.value || circle.value) {
      return ['tw-rounded-full']
    } else if (pill.value) {
      return ['tw-rounded-[50rem]']
    } else if (squared.value) {
      return ['tw-rounded-0']
    }
    return ['tw-rounded-[0.2rem]']
  })

  return {
    sizeClasses,
    variantClasses,
    linkClasses,
    positionClasses,
    roundnessClasses,
    spinnerClasses
  }
}
