










import { Variant, VariantType } from '~/models/app/variant'
import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { Size } from '~/models/app/size'

export default defineComponent({
  props: {
    bgClass: {
      type: String,
      default: null
    },
    textClass: {
      type: String,
      default: null
    },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.INFO
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MD
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { variant, rounded, size, bgClass, textClass } = toRefs(props)

    const roundnessClasses = computed(() => {
      return rounded.value ? ['tw-rounded'] : ['tw-rounded-full']
    })
    const colorClasses = computed(() => {
      if (bgClass.value && textClass.value) {
        return [bgClass.value, textClass.value]
      }
      switch (variant.value) {
        case Variant.PRIMARY: {
          return ['tw-bg-primary-100', 'tw-text-primary-900']
        }
        case Variant.LIGHT: {
          return ['tw-bg-grey-300', 'tw-text-grey-800']
        }
        case Variant.SUCCESS: {
          return ['tw-bg-green-100', 'tw-text-green-900']
        }
        case Variant.DANGER: {
          return ['tw-bg-red-100', 'tw-text-red-900']
        }
        case Variant.WARNING: {
          return ['tw-bg-yellow-100', 'tw-text-yellow-900']
        }
        case Variant.INFO:
        default: {
          return ['tw-bg-blue-100', 'tw-text-blue-800']
        }
      }
    })
    const sizeClasses = computed(() => {
      switch (size.value) {
        case Size.LG: {
          return ['tw-px-3', 'tw-py-0.5', 'tw-text-base']
        }
        case Size.SM: {
          return ['tw-py-0', 'tw-px-2', 'tw-text-sm']
        }
        case Size.MD:
        default: {
          return ['tw-py-0.5', 'tw-px-2.5', 'tw-text-sm']
        }
      }
    })
    return { roundnessClasses, colorClasses, sizeClasses }
  }
})
