import { CIcon } from '~/icons/types'
export const ciInfoCircle: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M256 8C119 8 8 119.1 8 256c0 137 111 248 248 248s248-111 248-248C504 119.1 393 8 256 8m-30 95.3c8.2-8.3 18.3-12.5 29.9-12.5 11.8 0 21.9 4.2 30.1 12.5s12.3 18.3 12.3 29.9c0 11.7-4.2 21.7-12.5 29.9-8.3 8.3-18.4 12.4-30 12.4s-21.7-4.1-29.9-12.3-12.3-18.3-12.3-29.9 4.2-21.7 12.4-30m94.7 317.9H198.4c-3.9 0-7.1-3.2-7.1-7.1v-8.3c0-3.9 3-7 6.9-7.1 6.6-.3 11.5-1.7 14.8-4.7.9-.8 1.5-1.9 1.9-3 .8-2.6 1.9-8.6 1.9-21.1V245.5c0-19-3-23.5-3.5-24.2-1.2-1.5-4.8-4-15.2-4.6-3.7-.2-6.7-3.4-6.7-7.1v-8.5c0-3.9 3.2-7.1 7.1-7.1h89.4c3.9 0 7.1 3.2 7.1 7.1v168.8c0 19 3 23.5 3.5 24.2 1.2 1.5 4.8 4 15.4 4.6 3.7.2 6.7 3.4 6.7 7.1z'
    }
  ],
  name: 'info-circle',
  type: 'solid'
}
