import { RequestOptions } from 'http'
import { inject } from 'tsyringe'
import { requestToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import woothee from 'woothee'
import { WootheeParseResult } from '~/models/user-agent/woothee'
import { DEFAULT_USER_AGENT } from '~/constants/user-agent'

@containerScoped()
export default class UserAgentService {
  parsedUa: WootheeParseResult

  constructor(@inject(requestToken) private ssrRequest?: RequestOptions) {
    const getUserAgent = () => {
      if (
        this.ssrRequest &&
        this.ssrRequest.headers &&
        'user-agent' in this.ssrRequest.headers
      ) {
        return this.ssrRequest.headers['user-agent'] as string
      } else if (typeof navigator !== 'undefined') {
        return navigator.userAgent
      }
      return DEFAULT_USER_AGENT
    }

    this.parsedUa = woothee.parse(getUserAgent())
  }

  isMacOs(): boolean {
    return this.parsedUa.os === 'Mac OSX'
  }

  isAndroidOs() {
    return this.parsedUa.os === 'Android'
  }

  isIpad() {
    return this.parsedUa.os === 'iPad'
  }

  isIpod() {
    return this.parsedUa.os === 'iPod'
  }

  isIphone() {
    return this.parsedUa.os === 'iPhone'
  }

  isFirefox() {
    return this.parsedUa.name === 'Firefox'
  }

  isEdge() {
    return this.parsedUa.name === 'Edge'
  }

  isChrome() {
    return this.parsedUa.name === 'Chrome'
  }

  isIos() {
    return (
      this.isIphone() ||
      this.isIpad() ||
      this.isIpod() ||
      this.parsedUa.os === 'iOS'
    )
  }
}
