import { interopDefault } from '~/router/utils'

export const adminReportsRoutes = [
  {
    name: '__admin_reports_search',
    path: '/admin/reports/search/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/reports/classified/search.vue' /* webpackChunkName: "pages-shared-admin-reports-classified-search" */
        )
      )
  },
  {
    name: '__admin_message_reports_search',
    path: '/admin/reports/messages/search/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/reports/message/search.vue' /* webpackChunkName: "pages-shared-admin-reports-message-search" */
        )
      )
  },
  {
    name: '__admin_classified_list_comment_reports_search',
    path: '/admin/reports/classified-list-comments/search/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/reports/classified-list/comments-search.vue' /* webpackChunkName: "pages-shared-admin-classified-list-comments-search-search" */
        )
      )
  },
  {
    name: '__admin_reports_shorts',
    path: '/admin/reports/shorts/',
    pathToRegexpOptions: { strict: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/reports/shorts/search.vue' /* webpackChunkName: "pages-shared-admin-reports-shorts-search" */
        )
      )
  }
]
