import DependencyContainer from 'tsyringe/dist/typings/types/dependency-container'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import {
  requestToken,
  sentryToken,
  httpToken,
  responseToken,
  requestContainerToken,
  redirectToken
} from '~/constants/dependency-injection/tokens'
import { createValueRegistrationFunction } from './factories'
import { Dependencies } from '~/models/global-dependencies'

export function registerRequestDependenciesToContainer(
  { axios, sentry, i18n, req, res, router, store, redirect }: Dependencies,
  c: DependencyContainer
) {
  const value = createValueRegistrationFunction(c)
  value(requestToken, req || false)
  value(responseToken, res || false)
  value(redirectToken, redirect)
  value(sentryToken, sentry)
  value(requestContainerToken, c)
  value(httpToken, axios)
  value(VueRouter, router)
  value(VueI18n, i18n)
  value(Store, store)
}
