import { SchemaField } from '~/models/shared/types'

export enum OnlineLoanType {
  AUTO = 'auto-loan',
  CONSUMER = 'consumer-loan',
  SANTANDER = 'santander-loan',
  MORTAGE = 'mortgage-loan'
}

export enum SantanderLoanPeriodType {
  OBJECT = 'object',
  ARRAY = 'array'
}

export interface MinMaxValues {
  min: string
  max: string
}
export interface SantanderLoanPeriod {
  type: SantanderLoanPeriodType
  values: MinMaxValues | string[]
}
export interface SantanderLoanFees {
  feeTypeId: string
  fixedFee: string
  maxAmount: string
  minAmount: string
  paymentId: string
  pctMaxFee: string
}
export interface SantanderLoanInfo {
  description: string
  startDate: number
  endDate: number
  fees: SantanderLoanFees
  interestRate: number
  maxDownpaymentPercentage: number
  minDownpaymentPercentage: number
  period: SantanderLoanPeriod
  type: string
}

export interface LoansInfo {
  extraLoanInfo?: SantanderLoanInfo
  loanType: OnlineLoanType
}

export interface SantanderTargetSchema {
  schema: { [key: string]: SchemaField }
}
