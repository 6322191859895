import { DependencyContainer } from 'tsyringe'
import { constructor } from 'tsyringe/dist/typings/types'

/**
 * Register distinct class resolution by client or server.
 */
export function registerRuntimeSingleton<T extends any>(
  c: DependencyContainer,
  clientConstructor: constructor<T>,
  serverConstructor: constructor<T>,
  token: constructor<T> = clientConstructor
) {
  c.registerSingleton(
    token,
    process.client ? clientConstructor : serverConstructor
  )
}
