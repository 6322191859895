import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__information',
    path: '/information',
    redirect: { name: '__information_support' },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/information/index.vue' /* webpackChunkName: "pages-information-index.vue" */
        )
      ),
    children: [
      {
        path: 'dealers_faq',
        name: '__information_dealers_faq',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/dealers-faq.vue' /* webpackChunkName: "pages-information-dealers-faq.vue" */
            )
          )
      },
      {
        path: 'users_faq',
        name: '__information_users_faq',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/users-faq.vue' /* webpackChunkName: "pages-information-users-faq.vue" */
            )
          )
      },
      {
        path: 'terms',
        name: '__information_terms',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/terms.vue' /* webpackChunkName: "pages-information-terms.vue" */
            )
          )
      },
      {
        path: 'contact',
        name: '__information_contact',
        redirect: { name: '__information_support' }
      },
      {
        path: 'support',
        name: '__information_support',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/support.vue' /* webpackChunkName: "pages-information-support.vue" */
            )
          )
      },
      {
        name: '__information_simboulos',
        path: '/information/simboulos',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/simboulos.vue' /* webpackChunkName: "pages-information-simboulos.vue" */
            )
          )
      },
      {
        name: '__information_careers',
        path: '/information/careers',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/careers.vue' /* webpackChunkName: "pages-information-careers.vue" */
            )
          )
      },
      {
        name: '__information_privacy',
        path: '/information/privacy',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/information/privacy.vue' /* webpackChunkName: "pages-information-privacy.vue" */
            )
          )
      }
    ]
  }
]
