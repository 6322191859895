import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { StatsResult } from '~/models/telemetry/statistics'

@containerScoped()
export default class StatsService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async record(
    statNamespace: string,
    classifiedIds: number[]
  ): Promise<StatsResult> {
    const response: AxiosResponse = await this.http.get('/api/stats/record/', {
      params: {
        stat: statNamespace,
        classified: classifiedIds
      }
    })
    const { data: body } = response
    return body
  }
}
