import { render, staticRenderFns } from "./CJsonld.vue?vue&type=template&id=a7cbc30c&scoped=true&"
import script from "./CJsonld.vue?vue&type=script&lang=ts&"
export * from "./CJsonld.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7cbc30c",
  null
  
)

export default component.exports