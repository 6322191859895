import Vue from 'vue'
import { FloatingCornerElementName, Corner } from '~/models/layout'
import { PageState } from '~/store/modules/shared/page/state'
import { ActionTreeWithRootState } from '~/store/types'
import {
  ADD_BODY_CLASS,
  ADD_CORNER_ELEMENT,
  REMOVE_BODY_CLASS,
  REMOVE_CORNER_ELEMENT
} from './mutation-types'

export default {
  addCornerElement(
    { commit },
    {
      name,
      componentInstance,
      corner = Corner.BOTTOM_RIGHT
    }: {
      name: FloatingCornerElementName
      componentInstance: Vue
      corner: Corner
    }
  ): () => void {
    commit(ADD_CORNER_ELEMENT, { name, corner })

    const removeElement = () => commit(REMOVE_CORNER_ELEMENT, { name, corner })
    componentInstance.$once('hook:beforeDestroy', removeElement)
    return removeElement
  },
  addBodyClass({ commit, state }, className: string) {
    commit(ADD_BODY_CLASS, className)
    if (process.client && document) {
      document.body.className = state.bodyClasses.join(' ')
    }
  },
  removeBodyClass({ commit, state }, className: string) {
    commit(REMOVE_BODY_CLASS, className)
    if (process.client && document) {
      document.body.className = state.bodyClasses.join(' ')
    }
  }
} as ActionTreeWithRootState<PageState>
