import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toCamelCase } from '~/utils/object'
import {
  AuditRequest,
  AuditRequestsRowsResult,
  AuditRequestsResult,
  AuditRequestStatusStatus,
  AuditRequestResult,
  CreateAuditRequestResult
} from '~/models/audit/request'
import {
  AUDIT_REQUEST_FAILED_STATUSES,
  AUDIT_REQUEST_SUCCESSFUL_STATUSES
} from '~/constants/audits/request'
import { SIMPLE_DATETIME_FORMAT } from '~/constants/date'
import { format } from '~/utils/date'
import { AuditProviderShop, AuditShop } from '~/models/audit/shop'
import { Variant } from '~/models/app/variant'

@containerScoped()
export default class AuditRequestService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  changeStatus(
    auditRequestId: AuditRequest['id'],
    status: AuditRequestStatusStatus,
    payload: Record<string, any> = {}
  ): Promise<any> {
    return this.requestBuilder
      .request('post', `/api/audits/audit-request/${auditRequestId}/status/`)
      .data({ status, ...payload })
      .send()
  }

  cancelRequest(
    auditRequestId: AuditRequest['id'],
    cancellationReason?: string
  ): Promise<any> {
    return this.requestBuilder
      .request('post', `/api/audits/audit-request/${auditRequestId}/cancel/`)
      .data({ cancellation_reason: cancellationReason })
      .send()
  }

  setAppointmentDate(
    auditRequestId: AuditRequest['id'],
    appointmentDate: Date
  ): Promise<any> {
    return this.requestBuilder
      .request(
        'post',
        `/api/audits/audit-request/${auditRequestId}/appointment/`
      )
      .data({
        appointment_date: format(appointmentDate, SIMPLE_DATETIME_FORMAT)
      })
      .send()
  }

  changeShop(
    auditRequestId: AuditRequest['id'],
    auditShopId: AuditShop['id']
  ): Promise<any> {
    return this.requestBuilder
      .request('put', `/api/audits/audit-request/${auditRequestId}/audit-shop/`)
      .data({
        audit_shop: auditShopId
      })
      .send()
  }

  getAuditRequests(
    params: {
      pg: number
      auditShopId?: AuditProviderShop['id'] | null
    } = { pg: 1 }
  ): Promise<AuditRequestsRowsResult> {
    return this.requestBuilder
      .request('get', '/api/audits/audit-requests/')
      .map(body => toCamelCase({ auditRequests: body.data.audit_requests }))
      .params({ pg: params.pg, audit_shop: params.auditShopId })
      .send()
  }

  getAuditRequest(auditRequestId: AuditRequest['id']): Promise<AuditRequest> {
    return this.requestBuilder
      .request('get', `/api/audits/audit-request/${auditRequestId}/`)
      .map(body => toCamelCase({ ...body.data.audit_request }))
      .send()
  }

  public requestAuditForClassified(
    classifiedId: number | string,
    auditShopId: number,
    auditPackageId: number
  ): Promise<CreateAuditRequestResult> {
    return this.requestBuilder
      .request('post', `/api/audits/classified/${classifiedId}/request/`)
      .data({
        audit_shop: auditShopId,
        audit_package: auditPackageId
      })
      .send()
  }

  public getAuditRequestsForClassified(
    classifiedId: number | string
  ): Promise<AuditRequestsResult> {
    return this.requestBuilder
      .request('get', `/api/audits/classified/${classifiedId}/request/`)
      .validate(body => body?.data?.audit_requests)
      .send()
  }

  getAuditRequestStatus(
    auditRequestId: AuditRequest['id']
  ): Promise<AuditRequestResult> {
    return this.requestBuilder
      .request('get', `/api/audits/audit-request/${auditRequestId}/status/`)
      .send()
  }

  acceptAuditRequest(
    auditRequestId: AuditRequest['id'],
    code?: string
  ): Promise<AuditRequestResult> {
    return this.requestBuilder
      .request(
        'post',
        `/api/audits/audit-request/${auditRequestId}/accept/?code=${code}`
      )
      .send()
  }

  showOnClassified(auditRequestId: AuditRequest['id']) {
    return this.requestBuilder
      .request(
        'post',
        `/api/audits/audit-request/${auditRequestId}/show-on-classified/`
      )
      .send()
  }

  hideFromClassified(auditRequestId: AuditRequest['id']) {
    return this.requestBuilder
      .request(
        'delete',
        `/api/audits/audit-request/${auditRequestId}/show-on-classified/`
      )
      .send()
  }

  getStatusVariant(status: AuditRequestStatusStatus, index: number) {
    if (this.statusIsSuccess(status)) {
      return Variant.SUCCESS
    } else if (this.statusIsFail(status)) {
      return Variant.DANGER
    }

    // If the first item is neither success nor fail, use info
    if (index === 0) {
      return Variant.INFO
    }
    return Variant.SUCCESS
  }

  statusIsSuccess(status: AuditRequestStatusStatus): boolean {
    return AUDIT_REQUEST_SUCCESSFUL_STATUSES.includes(status)
  }

  statusIsFail(status: AuditRequestStatusStatus): boolean {
    return AUDIT_REQUEST_FAILED_STATUSES.includes(status)
  }
}
