
import {
  computed,
  defineComponent,
  h,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { Size } from '~/models/shared/types'
import { useVNode } from '~/compositions/vnode'
import { VNode } from 'vue'
import CInputGroupText from '~/components/shared/configurable/form/input/CInputGroupText.vue'

export default defineComponent({
  props: {
    extraBorderRadius: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    }
  },
  setup(props, { slots }) {
    const { extraBorderRadius, size, prepend, append } = toRefs(props)

    const { addPropertiesToVNodes } = useVNode()

    const hasPrependedContent = computed(() => slots.prepend || prepend.value)
    const hasAppendedContent = computed(() => slots.append || append.value)

    const inputRoundnessClass = computed(() => {
      if (hasPrependedContent.value && hasAppendedContent.value) {
        return 'tw-rounded-none'
      }
      if (hasPrependedContent.value) {
        return extraBorderRadius.value
          ? 'tw-rounded-r-lg !tw-border-grey-400'
          : 'tw-rounded-r-md'
      }
      if (hasAppendedContent.value) {
        return extraBorderRadius.value
          ? 'tw-rounded-l-lg !tw-border-grey-400'
          : 'tw-rounded-l-md'
      }

      return 'tw-rounded-md'
    })

    function vNodeIsButton(vNode: VNode): boolean {
      return vNode.componentOptions?.tag === 'CButton'
    }
    function vNodeIsSelect(vNode: VNode): boolean {
      return vNode.componentOptions?.tag === 'CFormSelect'
    }

    function renderContentBeforeAndAfter(
      vNodes: VNode[],
      placement: 'append' | 'prepend'
    ): VNode {
      const [node] = vNodes
      const isPrepended = placement === 'prepend'
      const roundnessClass = isPrepended ? 'tw-rounded-l-md' : 'tw-rounded-r-md'

      if (vNodeIsButton(node)) {
        let staticClasses

        if (isPrepended) {
          extraBorderRadius.value
            ? (staticClasses =
                '-tw-mr-px !tw-rounded-l-lg tw-rounded-r-none !tw-border-grey-400')
            : (staticClasses = '-tw-mr-px tw-rounded-r-none')
        } else {
          extraBorderRadius.value
            ? (staticClasses =
                '-tw-ml-px !tw-rounded-r-lg tw-rounded-l-none !tw-border-grey-400')
            : (staticClasses = '-tw-ml-px tw-rounded-l-none')
        }

        return h(
          'div',
          addPropertiesToVNodes(vNodes, {
            props: { size: size.value },
            staticClasses
          })
        )
      } else if (vNodeIsSelect(node)) {
        return h(
          'div',
          { staticClass: 'tw-inline-flex' },
          addPropertiesToVNodes(vNodes, {
            props: {
              size: size.value,
              roundnessClass
            },
            staticClasses: isPrepended ? '-tw-mr-px' : '-tw-ml-px'
          })
        )
      }
      return h(
        'div',
        { staticClass: 'tw-inline-flex' },
        addPropertiesToVNodes(vNodes, {
          props: {
            size: size.value,
            roundnessClass
          },
          staticClasses: isPrepended ? '-tw-mr-px' : '-tw-ml-px'
        })
      )
    }

    function renderContent(): VNode[] {
      const r = []
      if (slots.prepend) {
        r.push(renderContentBeforeAndAfter(slots.prepend(), 'prepend'))
      } else if (prepend.value) {
        r.push(
          renderContentBeforeAndAfter(
            [h(CInputGroupText, prepend.value)],
            'prepend'
          )
        )
      }
      if (slots.default) {
        r.push(
          h(
            'div',
            { staticClass: 'tw-flex-1' },
            addPropertiesToVNodes(slots.default(), {
              props: {
                size: size.value,
                roundnessClass: inputRoundnessClass.value
              }
            })
          )
        )
      }
      if (slots.append) {
        r.push(renderContentBeforeAndAfter(slots.append(), 'append'))
      } else if (append.value) {
        r.push(
          renderContentBeforeAndAfter(
            [h(CInputGroupText, append.value)],
            'append'
          )
        )
      }
      return r
    }

    return () => {
      return h(
        'div',
        {
          attrs: {
            role: 'group'
          },
          staticClass:
            'tw-flex tw-relative tw-flex-wrap tw-items-stretch tw-w-full'
        },
        renderContent()
      )
    }
  }
})
