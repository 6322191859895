






















































import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'

export default defineComponent({
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    value: {
      type: [Array, Boolean, String, Number],
      default: null
    },
    checked: {
      type: [Boolean, String, Array, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    switch: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    spaceVertically: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    externalLabelClasses: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const {
      id,
      checked,
      disabled,
      switch: switchProp,
      large,
      spaceVertically,
      reverse,
      externalLabelClasses
    } = toRefs(props)
    const checkboxTemplateRef = ref<HTMLElement | null>(null)
    const { createRandomId } = useId()
    const internalId = id.value ?? createRandomId()

    const internalValue = computed({
      get() {
        return checked.value
      },
      set(value) {
        emit('input', value)
        emit('change', value)
      }
    })

    const checkboxClasses = computed(() => [
      ...(disabled.value ? ['tw-bg-grey-300'] : []),
      ...(disabled.value
        ? ['tw-border-grey-300']
        : ['tw-border-grey-400', 'group-hover/checkbox:tw-border-primary']),
      ...(switchProp.value ? ['tw-sr-only'] : []),
      ...(disabled.value ? ['checked:tw-opacity-50'] : []),
      ...(disabled.value ? ['tw-cursor-not-allowed'] : ['tw-cursor-pointer']),
      ...(large.value
        ? ['tw-h-6', 'tw-w-6']
        : ['tw-h-5', 'tw-w-5', 'lg:tw-h-4', 'lg:tw-w-4'])
    ])

    const labelClasses = computed(() => [
      ...(disabled.value
        ? ['tw-text-grey-500']
        : ['group-hover/checkbox:lg:tw-text-primary']),
      ...(disabled.value ? ['tw-cursor-not-allowed'] : ['tw-cursor-pointer']),
      ...(large.value ? ['tw-text-lg'] : ['tw-text-base']),
      { 'tw-ml-0': reverse.value },
      externalLabelClasses.value
    ])

    const switchButtonClasses = computed(() => [
      ...(disabled.value
        ? []
        : [
            'focus:tw-outline-none',
            'focus:tw-ring-2',
            'focus:tw-ring-primary',
            'focus:tw-ring-offset-2'
          ]),
      ...(disabled.value
        ? ['tw-border-grey-300']
        : ['tw-border-grey-400', 'group-hover/checkbox:tw-border-primary']),
      ...(disabled.value ? ['tw-cursor-not-allowed'] : ['tw-cursor-pointer']),
      ...(disabled.value ? ['tw-bg-grey-400'] : ['tw-bg-white']),
      ...(large.value ? ['tw-h-5', 'tw-w-10'] : ['tw-h-4', 'tw-w-8']),
      ...(disabled.value ? ['tw-opacity-50'] : [])
    ])

    const checkboxContainerClasses = computed(() => [
      ...(large.value ? ['tw-h-6'] : ['tw-h-5'])
    ])
    const switchButtonToggleClasses = computed(() => [
      ...(large.value ? ['tw-h-4', 'tw-w-4'] : ['tw-h-3', 'tw-w-3']),
      ...(disabled.value ? ['tw-bg-grey-600'] : ['tw-bg-grey-400'])
    ])
    const wrapperClasses = computed(() => [
      ...(spaceVertically.value ? ['tw-my-3', 'md:tw-my-1'] : []),
      { 'tw-flex-row-reverse': reverse.value }
    ])

    const showSwitchButton = computed(() => switchProp.value)

    function handleSwitchButtonClick() {
      if (!checkboxTemplateRef.value) {
        return
      }
      checkboxTemplateRef.value.click()
    }

    return {
      internalId,
      internalValue,
      checkboxClasses,
      labelClasses,
      showSwitchButton,
      switchButtonClasses,
      handleSwitchButtonClick,
      checkboxTemplateRef,
      checkboxContainerClasses,
      switchButtonToggleClasses,
      wrapperClasses
    }
  }
})
