

























import { Action } from './types'
import { Position } from '~/models/app/position'
import { VariantType } from '~/models/app/variant'
import { getRouteMetaItem } from '~/utils/router'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Snackbar',
  props: {
    variant: {
      type: String as PropType<VariantType>,
      required: false,
      default: null
    },
    position: {
      type: String as PropType<Position>,
      required: false,
      default: Position.BOTTOM_RIGHT
    },
    action: {
      type: Object as PropType<Action>,
      required: false,
      default: null
    },
    classes: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      active: false,
      text: '',
      iframeStyle: null
    }
  },
  computed: {
    actionColor() {
      return this.action?.color || '#ffffff'
    },
    isCrossOrigin(): boolean {
      return getRouteMetaItem(this.$route, 'isCrossOrigin')
    }
  },
  methods: {
    onIframeScroll() {
      const offset = 50

      if (this.position === Position.BOTTOM_RIGHT) {
        // TODO: handle all the other positions
        let bottom =
          window.parent.document.body.scrollHeight -
          window.parent.innerHeight -
          window.parent.document.documentElement.scrollTop

        bottom = bottom < offset ? 20 : bottom - offset

        this.iframeStyle = {
          bottom: bottom + 'px'
        }
      }
    },
    close() {
      this.$emit('close')
    },
    show() {
      this.active = true
      if (!this.isCrossOrigin && window.parent && window.parent !== window) {
        window.parent.addEventListener('scroll', this.onIframeScroll, false)
      }
    },
    hide() {
      this.active = false
      if (!this.isCrossOrigin && window.parent && window.parent !== window) {
        window.parent.removeEventListener('scroll', this.onIframeScroll, false)
      }
    },
    actionClicked() {
      if (this.action.callback) {
        this.action.callback()
      } else {
        this.close()
      }
    }
  }
})
