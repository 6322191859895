import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__classifieds_quick_search',
    path: '/quick-search/:root?/:category?',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/quick-search/index.vue' /* webpackChunkName: "pages-classifieds-quick-search-index" */
        )
      ),
    meta: {
      scrollTopSmooth: true
    }
  }
]
