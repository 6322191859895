import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  ClassifiedOfferCountResult,
  ClassifiedOffersListResult,
  ClassifiedOffersSetStateResult,
  NewClassifiedOfferBidSchemaResult,
  NewClassifiedOfferForm,
  NewClassifiedOfferPostResult,
  NewClassifiedOfferSchemaResult,
  OfferState
} from '~/models/classified/offers'
import { toSnakeCase } from '~/utils/snake-case'

@containerScoped()
export default class ClassifiedOffersService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  public submitNewOffer(
    classifiedId: number | string,
    form: NewClassifiedOfferForm
  ): Promise<NewClassifiedOfferPostResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/offers/${classifiedId}/new/`)
      .data(toSnakeCase(form))
      .send()
  }

  public submitNewCounteroffer(
    offerId: number | string,
    form: NewClassifiedOfferForm
  ): Promise<NewClassifiedOfferPostResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/offers/${offerId}/bids/new/`)
      .data(toSnakeCase(form))
      .send()
  }

  public getNewOfferSchema(
    classifiedId: number | string
  ): Promise<NewClassifiedOfferSchemaResult> {
    return this.requestBuilder
      .request('get', `/api/classifieds/offers/${classifiedId}/new/`)
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public getNewBidSchema(
    offerId: number | string
  ): Promise<NewClassifiedOfferBidSchemaResult> {
    return this.requestBuilder
      .request('get', `/api/classifieds/offers/${offerId}/bids/new/`)
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public getOfferCountForClassified(
    classifiedId: number | string
  ): Promise<ClassifiedOfferCountResult> {
    return this.requestBuilder
      .request('get', `/api/classifieds/offers/${classifiedId}/count/`)
      .validate(
        body =>
          body &&
          body.data &&
          (body.data.offer_count || body.data.offer_count === 0)
      )
      .send()
  }

  public getOffersForClassified(
    classifiedId: number | string,
    page: number = 1
  ): Promise<ClassifiedOffersListResult> {
    return this.requestBuilder
      .request('get', `/api/classifieds/offers/${classifiedId}/`)
      .params({ pg: page })
      .validate(body => body && body.data && body.data.classified_offers_list)
      .send()
  }

  public getUserOffers(
    state: OfferState | null = null,
    received: boolean = false,
    page: number = 1
  ): Promise<ClassifiedOffersListResult> {
    return this.requestBuilder
      .request('get', '/api/user/classified-offers/')
      .params({ pg: page, state, received: received || null })
      .validate(body => body && body.data && body.data.classified_offers_list)
      .send()
  }

  public setOfferState(
    offerId: number,
    state: OfferState
  ): Promise<ClassifiedOffersSetStateResult> {
    return this.requestBuilder
      .request('post', `/api/classifieds/offers/set-state/${offerId}/`)
      .data({
        state
      })
      .validate(body => body && body.data && body.data.offer)
      .send()
  }
}
