













import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { Variant, VariantType } from '~/models/app/variant'
import { Size } from '~/models/app/size'

export enum CSpinnerType {
  GROW = 'grow',
  BORDER = 'border'
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<CSpinnerType>,
      default: CSpinnerType.BORDER
    },
    size: {
      type: String as PropType<Size>,
      default: 'md'
    },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.PRIMARY
    }
  },
  setup(props) {
    const { variant, type, size } = toRefs(props)

    const variantColor = computed(() => {
      switch (variant.value) {
        case Variant.INFO: {
          return 'tw-text-sky'
        }
        case Variant.SUCCESS: {
          return 'tw-text-green'
        }
        case Variant.WARNING: {
          return 'tw-text-yellow'
        }
        case Variant.DANGER: {
          return 'tw-text-red'
        }
        case Variant.SECONDARY: {
          return 'tw-text-grey-600'
        }
        case Variant.WHITE: {
          return 'tw-text-white'
        }
        case Variant.LIGHT: {
          return 'tw-text-grey-300'
        }
        case Variant.TEAL: {
          return 'tw-text-teal-300'
        }
        case Variant.ORANGE: {
          return 'tw-deep-orange-400'
        }
        case Variant.PRIMARY:
        default: {
          return 'tw-text-primary'
        }
      }
    })
    const sizeClass = computed(() => {
      switch (size.value) {
        case 'xs': {
          return 'tw-w-4 tw-h-4'
        }
        case 'sm': {
          return 'tw-w-6 tw-h-6'
        }
        case 'md': {
          return 'tw-w-8 tw-h-8'
        }
        case 'lg': {
          return 'tw-w-12 tw-h-12'
        }
        case 'xl': {
          return 'tw-w-14 tw-h-14'
        }
        case 'xxl': {
          return 'tw-w-20 tw-h-20'
        }
        default: {
          return 'tw-w-8 tw-h-8'
        }
      }
    })
    const bgColor = computed(() => {
      if (type.value === CSpinnerType.BORDER) {
        return 'tw-bg-transparent'
      }
      switch (variant.value) {
        case Variant.SECONDARY: {
          return 'tw-bg-grey-600'
        }
        case Variant.INFO: {
          return 'tw-bg-sky'
        }
        case Variant.SUCCESS: {
          return 'tw-bg-green'
        }
        case Variant.WARNING: {
          return 'tw-bg-yellow'
        }
        case Variant.DANGER: {
          return 'tw-bg-red'
        }
        case Variant.WHITE: {
          return 'tw-bg-white'
        }
        case Variant.LIGHT: {
          return 'tw-bg-grey-300'
        }
        case Variant.TEAL: {
          return 'tw-bg-teal-300'
        }
        case Variant.PRIMARY:
        default: {
          return 'tw-bg-primary'
        }
      }
    })

    const typeClass = computed(() => {
      if (type.value === CSpinnerType.GROW) {
        return 'grow-spinner'
      }
      return 'border-spinner'
    })

    const borderClass = computed(() => {
      if (type.value === CSpinnerType.BORDER) {
        return ['tw-border-4', 'tw-border-solid']
      }
      return []
    })

    return {
      variantColor,
      typeClass,
      bgColor,
      sizeClass,
      borderClass
    }
  }
})
