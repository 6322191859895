export const Variant = {
  PRIMARY: 'primary',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  SUCCESS: 'success',
  SECONDARY: 'secondary',
  WHITE: 'white',
  LIGHT: 'light',
  LINK: 'link',
  TEAL: 'teal',
  BLUE: 'blue',
  BLUE_DARK: 'blue-dark',
  ORANGE: 'orange',
  DARK: 'dark'
} as const

export const ButtonVariant = {
  ...Variant,
  BUY_IT: 'buy-it',
  EXTRA_LIGHT: 'extra-light',
  OUTLINE_PRIMARY: 'outline-primary',
  OUTLINE_WARNING: 'outline-warning',
  OUTLINE_DANGER: 'outline-danger',
  OUTLINE_SUCCESS: 'outline-success',
  OUTLINE_SECONDARY: 'outline-secondary',
  OUTLINE_INFO: 'outline-info',
  OUTLINE_LIGHT: 'outline-light',
  OUTLINE_TEAL: 'outline-teal',
  OUTLINE_BLUE: 'outline-blue',
  OUTLINE_LIGHT_BLUE: 'outline-light-blue',
  OUTLINE_BLUE_DARK: 'outline-blue-dark',
  OUTLINE_ORANGE: 'outline-orange',
  OUTLINE_PLOT: 'outline-plot',
  ORANGE: 'orange',
  TURBO: 'turbo',
  SUPER: 'super',
  TOP: 'top',
  FACEBOOK: 'facebook',
  WHATSAPP: 'whatsapp',
  X: 'x',
  VIBER: 'viber'
} as const

type VariantKeys = keyof typeof Variant
export type VariantType = typeof Variant[VariantKeys]

type ButtonVariantKeys = keyof typeof ButtonVariant
export type ButtonVariantType = typeof ButtonVariant[ButtonVariantKeys]
