import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__messages',
    path: '/cpanel/messages',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/cpanel/messages.vue' /* webpackChunkName: "pages-shared-cpanel-_messages.vue" */
        )
      )
  },
  {
    name: '__guest_messages',
    path: '/iframe/guests/messages',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/guest_panel/guest-messages.vue' /* webpackChunkName: "pages-shared-guest_panel-guest-messages.vue" */
        )
      )
  },
  {
    name: '__message_settings',
    path: '/cpanel/message-settings',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/cpanel/message-settings.vue' /* webpackChunkName: "pages-shared-cpanel-message-settings.vue" */
        )
      )
  },
  {
    name: '__classifieds_view_messages',
    path: '/classifieds/:id/messages',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/view/messages-in-edit.vue' /* webpackChunkName: "pages-shared-classifieds-view-messages-in-edit" */
        )
      )
  }
]
