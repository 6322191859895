















import { formatNumber } from '~/utils/number'
import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    price: {
      type: [String, Number],
      default: '',
      required: false
    },
    commaSeparator: {
      type: String,
      default: ',',
      required: false
    },
    replaceSeparator: {
      type: String,
      default: null
    },
    integral: {
      type: Boolean,
      default: false
    },
    strikethrough: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { price, commaSeparator, strikethrough } = toRefs(props)
    const hasMultipleSpaces = computed(() => {
      if (!price.value) {
        return false
      }

      const str = price.value.toString()
      return str.split(' ').length > 2
    })

    const priceNoDecimals = computed(() => {
      if (!price.value) {
        return ''
      }

      const str = price.value.toString()
      if (hasMultipleSpaces.value) {
        return str
      }
      const numarray = str.split(commaSeparator.value)
      const priceStripped = numarray[0].split(' ')[0]
      if (/^\d+$/.test(priceStripped) && parseInt(priceStripped)) {
        return formatNumber(parseInt(priceStripped))
      }
      return priceStripped
    })

    const priceDecimals = computed(() => {
      if (!price.value) {
        return ''
      }

      const str = price.value.toString()
      if (hasMultipleSpaces.value) {
        return ''
      }
      const numarray = str.split(commaSeparator.value)
      if (numarray[1]) {
        return numarray[1].split(' ')[0]
      }
      return null
    })

    const currencySymbol = computed(() => {
      if (!price.value) {
        return ''
      }

      const str = price.value.toString()
      if (hasMultipleSpaces.value) {
        return ''
      }
      const numarray = str.split(' ')
      if (numarray.length === 2) {
        return numarray[1]
      }
      return null
    })

    const basePrice = computed(() => {
      return priceNoDecimals.value || price.value
    })

    const strikethroughClass = computed(() =>
      strikethrough.value ? ['tw-line-through'] : []
    )
    const basePriceClasses = computed(() => [...strikethroughClass.value])
    const currencySymbolClasses = computed(() => [...strikethroughClass.value])
    const priceDecimalsClasses = computed(() => [...strikethroughClass.value])

    return {
      hasMultipleSpaces,
      priceNoDecimals,
      priceDecimals,
      currencySymbol,
      basePrice,
      basePriceClasses,
      currencySymbolClasses,
      priceDecimalsClasses
    }
  }
})
