import { Merge } from 'type-fest'
import VueI18n from 'vue-i18n'
import { CSnackbarOptions } from '~/models/snackbar'
import { ISnackbarService } from '~/services/snackbar/ISnackBarService'

export class NoopSnackbarService implements ISnackbarService {
  error(
    _text: string | VueI18n.LocaleMessage | Error,
    _options?: Merge<CSnackbarOptions, { log?: boolean }>
  ): Promise<void> | undefined {
    return undefined
  }

  info(
    _text: string | VueI18n.LocaleMessage,
    _options?: CSnackbarOptions
  ): Promise<void> | undefined {
    return undefined
  }

  saved(): void {}

  savedChanges(): void {}

  show(
    _text: string | VueI18n.LocaleMessage,
    _options?: CSnackbarOptions
  ): Promise<void> {
    return Promise.resolve(undefined)
  }

  success(
    _text: string | VueI18n.LocaleMessage,
    _options?: CSnackbarOptions
  ): Promise<void> | undefined {
    return undefined
  }
}
