import {
  FIELD_FULL_WIDTH_COLUMNS,
  FIELD_HALF_WIDTH_COLUMNS
} from '~/constants/form/columns'
import { FormConfig } from '~/models/form/types'

export const JOBS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      title: FIELD_FULL_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_FULL_WIDTH_COLUMNS,
      salary: FIELD_HALF_WIDTH_COLUMNS,
      payment: FIELD_HALF_WIDTH_COLUMNS,
      job_type: FIELD_FULL_WIDTH_COLUMNS,
      tags: FIELD_FULL_WIDTH_COLUMNS,
      photos2: FIELD_FULL_WIDTH_COLUMNS
    }
  }
})

export const VEHICLES_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      mileage: FIELD_HALF_WIDTH_COLUMNS,
      finance: FIELD_HALF_WIDTH_COLUMNS,
      price_debatable: FIELD_HALF_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS,
      fuel_type: FIELD_HALF_WIDTH_COLUMNS,
      fueltype: FIELD_HALF_WIDTH_COLUMNS,
      console: FIELD_HALF_WIDTH_COLUMNS,
      type: FIELD_HALF_WIDTH_COLUMNS,
      engine_size: FIELD_HALF_WIDTH_COLUMNS,
      engine_power: FIELD_HALF_WIDTH_COLUMNS,
      gearbox_type: FIELD_HALF_WIDTH_COLUMNS,
      drive_type: FIELD_HALF_WIDTH_COLUMNS,
      kteo: FIELD_HALF_WIDTH_COLUMNS,
      emissions_co2: FIELD_HALF_WIDTH_COLUMNS,
      euroclass: FIELD_HALF_WIDTH_COLUMNS,
      circulation_tax: FIELD_HALF_WIDTH_COLUMNS,
      fuel_efficiency_city: FIELD_HALF_WIDTH_COLUMNS,
      fuel_efficiency_motorway: FIELD_HALF_WIDTH_COLUMNS,
      fuel_efficiency_mixed: FIELD_HALF_WIDTH_COLUMNS,
      battery_capacity: FIELD_HALF_WIDTH_COLUMNS,
      battery_range: FIELD_HALF_WIDTH_COLUMNS,
      battery_quick_charge_time: FIELD_HALF_WIDTH_COLUMNS,
      battery_charge_time: FIELD_HALF_WIDTH_COLUMNS,
      maximum_capacity: FIELD_HALF_WIDTH_COLUMNS,
      maximum_lift_height: FIELD_HALF_WIDTH_COLUMNS,
      size: FIELD_HALF_WIDTH_COLUMNS,
      offertype: FIELD_HALF_WIDTH_COLUMNS,
      condition: FIELD_HALF_WIDTH_COLUMNS,
      crashed: FIELD_HALF_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      price_parked: FIELD_HALF_WIDTH_COLUMNS,
      home_delivery: FIELD_HALF_WIDTH_COLUMNS,
      visibility: FIELD_HALF_WIDTH_COLUMNS,
      multichild_price: FIELD_HALF_WIDTH_COLUMNS,
      doors: FIELD_HALF_WIDTH_COLUMNS,
      weight: FIELD_HALF_WIDTH_COLUMNS,
      payload: FIELD_HALF_WIDTH_COLUMNS,
      operating_weight: FIELD_HALF_WIDTH_COLUMNS,
      exterior_color: FIELD_HALF_WIDTH_COLUMNS,
      interior_color: FIELD_HALF_WIDTH_COLUMNS,
      interior_type: FIELD_HALF_WIDTH_COLUMNS,
      rim_size: FIELD_HALF_WIDTH_COLUMNS,
      axles: FIELD_HALF_WIDTH_COLUMNS,
      airbags: FIELD_HALF_WIDTH_COLUMNS,
      body_material: FIELD_HALF_WIDTH_COLUMNS,
      seats: FIELD_HALF_WIDTH_COLUMNS,
      length: FIELD_HALF_WIDTH_COLUMNS,
      cabins: FIELD_HALF_WIDTH_COLUMNS,
      number_plate_ending: FIELD_HALF_WIDTH_COLUMNS,
      toilets: FIELD_HALF_WIDTH_COLUMNS,
      beds: FIELD_HALF_WIDTH_COLUMNS,
      frame_size: FIELD_HALF_WIDTH_COLUMNS,
      brakes_type: FIELD_HALF_WIDTH_COLUMNS,
      gears: FIELD_HALF_WIDTH_COLUMNS,
      metallic: FIELD_HALF_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_HALF_WIDTH_COLUMNS,
      variant: FIELD_HALF_WIDTH_COLUMNS,
      model_variant: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const PARTS_XYMA_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      condition: FIELD_HALF_WIDTH_COLUMNS,
      condition_type: FIELD_HALF_WIDTH_COLUMNS,
      offertype: FIELD_HALF_WIDTH_COLUMNS,
      damaged: FIELD_HALF_WIDTH_COLUMNS,
      external_id: FIELD_HALF_WIDTH_COLUMNS,
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      visibility: FIELD_HALF_WIDTH_COLUMNS,
      price_parked: FIELD_HALF_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      price_debatable: FIELD_HALF_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_HALF_WIDTH_COLUMNS,
      part_number: FIELD_HALF_WIDTH_COLUMNS,
      sex: FIELD_HALF_WIDTH_COLUMNS,
      microchip: FIELD_HALF_WIDTH_COLUMNS,
      dealer_licence_number: FIELD_HALF_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const RENTALS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      engine_size: FIELD_HALF_WIDTH_COLUMNS,
      fuel_type: FIELD_HALF_WIDTH_COLUMNS,
      deposit_guarantee: FIELD_HALF_WIDTH_COLUMNS,
      gearbox_type: FIELD_HALF_WIDTH_COLUMNS,
      doors: FIELD_HALF_WIDTH_COLUMNS,
      boot_capacity: FIELD_HALF_WIDTH_COLUMNS,
      passengers: FIELD_HALF_WIDTH_COLUMNS,
      max_mileage: FIELD_HALF_WIDTH_COLUMNS,
      minimum_drivers_licence_years: FIELD_HALF_WIDTH_COLUMNS,
      minimum_age_of_driver: FIELD_HALF_WIDTH_COLUMNS,
      battery_range: FIELD_HALF_WIDTH_COLUMNS,
      battery_charge_time: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_FULL_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      length: FIELD_HALF_WIDTH_COLUMNS,
      engine_power: FIELD_HALF_WIDTH_COLUMNS,
      beds: FIELD_HALF_WIDTH_COLUMNS,
      makemodel: FIELD_FULL_WIDTH_COLUMNS,
      variant: FIELD_FULL_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS,
      model_variant: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const PLOT_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      address: FIELD_HALF_WIDTH_COLUMNS,
      postcode: FIELD_HALF_WIDTH_COLUMNS,
      location: FIELD_FULL_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      area: FIELD_HALF_WIDTH_COLUMNS,
      residential_zone: FIELD_HALF_WIDTH_COLUMNS,
      bedrooms: FIELD_HALF_WIDTH_COLUMNS,
      year_of_construction: FIELD_HALF_WIDTH_COLUMNS,
      available_from: FIELD_HALF_WIDTH_COLUMNS,
      heat_medium: FIELD_HALF_WIDTH_COLUMNS,
      type_of_heat: FIELD_HALF_WIDTH_COLUMNS,
      energy_class: FIELD_HALF_WIDTH_COLUMNS,
      bathrooms: FIELD_HALF_WIDTH_COLUMNS,
      livingrooms: FIELD_HALF_WIDTH_COLUMNS,
      kitchens: FIELD_HALF_WIDTH_COLUMNS,
      agent_classified_code: FIELD_HALF_WIDTH_COLUMNS,
      code: FIELD_HALF_WIDTH_COLUMNS,
      max_distance_from_location: FIELD_HALF_WIDTH_COLUMNS,
      facade_length: FIELD_HALF_WIDTH_COLUMNS,
      spaces: FIELD_HALF_WIDTH_COLUMNS,
      floor: FIELD_HALF_WIDTH_COLUMNS,
      guests: FIELD_HALF_WIDTH_COLUMNS,
      extra_telephone: FIELD_HALF_WIDTH_COLUMNS,
      extra_name: FIELD_HALF_WIDTH_COLUMNS,
      real_owners_name: FIELD_HALF_WIDTH_COLUMNS,
      owners_telephone: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      minimum_rental_days: FIELD_HALF_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
